import React, { Component} from 'react';
import {
    Table, Button, DatePicker, Card, PageHeader, message, Input, Space
} from 'antd';
import Xhr from '../libs/Xhr';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Utility from "../libs/Utility";
import './scss/RoomCharDetail.scss';

export default class RoomCharDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            currentPage: 1,
            pageSize: 10,
            selectedRowKeys: [],
            loading: false,
            startTime: "",
            endTime: "",
            total: 0,
            roomId: 0,
            uid: 0,
            name: '',
            startValue: '',
            endValue: '',
            endOpen: false,
            lang: 'zh_cn',
            tableScrollX: 1000,
        }

        this._columns = [
            {
                title: Utility.language('开始日期', this.state.lang),
                dataIndex: 'start_time',
                key: 'start_time',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.start_time - b.start_time,
                render: (text, record) => {
                    return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy-MM-dd') : '';
                },
            },{
                title: Utility.language('结束日期', this.state.lang),
                dataIndex: 'end_time',
                key: 'end_time',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.end_time - b.end_time,
                render: (text, record) => {
                    return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy-MM-dd') : '';
                },
            },
            {
                title: Utility.language('UID', this.state.lang),
                dataIndex: 'uid',
                key: 'uid',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.uid - b.uid,
                ...this.getColumnSearchProps('uid'),
            },
            {
                title: Utility.language('昵称', this.state.lang),
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: Utility.language('魅力值', this.state.lang),
                dataIndex: 'money',
                key: 'money',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.money - b.money,
            },
            {
                title: Utility.language('私聊条数', this.state.lang),
                dataIndex: 'private_chat',
                key: 'private_chat',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.private_chat - b.private_chat,
            }
        ]

        this._load();
    }

    componentDidMount() {
        this.userinfo();
	}

    userinfo = () => {
        var uinfo = localStorage.getItem('uinfo');
        var lang = localStorage.getItem('lang');
        this.setState({
            lang: lang ? lang : 'zh_cn',
        })
        uinfo = JSON.parse(uinfo);
        if (!uinfo) {
            this.props.history.push('/static/login');
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({
            searchText: ''
        });
    };

    onSearch = () => {

        this.setState({
            currentPage: 1,
        }, () =>{
            this._load();
        })

    }

    onOk(value) {
        //('onOk: ', value);
    }

    onChange = (field, value) => {
        this.setState({
            [field]: value
        });
    };

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    };

    disabledStartDate = (startValue) => {
        const { endValue } = this.state;
        if (endValue) {
            return (
                startValue.weekday() !== 3 || startValue.valueOf() >= endValue.valueOf()
            );
        } else {
            return startValue.weekday() !== 3;
        }
    };

    disabledEndDate = (endValue) => {
        const { startValue } = this.state;
        if (startValue) {
            return (
                endValue.weekday() !== 3 || (startValue.valueOf() + 24*6*3600*1000) >= endValue.valueOf()
            );
        } else {
            return endValue.weekday() !== 3;
        }
    };

    handleTableChange = (pagination) => {
        // this.setState({
        //     currentPage: pagination,
        // }, () =>{
        //     this._load();
        // })
    }

    tableChange = (pagination, filters, sorter, extra) => {
        if(extra.action !== 'paginate')
            return false;
        this.setState({
                currentPage: pagination.current,
                pageSize: pagination.pageSize,
            },()=>this._load()

        );
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    start = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 500);
    };


    SetTitle = (value) => {
        return <h1 style={{textAlign:"center"}}>{value}</h1>;
    }

    onStartChange = (value, dateString) => {
        this.onChange("startValue", value);
    };

    onEndChange = (value, dateString) => {
        this.onChange("endValue", value);
    };


    async _load() {

        let start_time = this.state.startValue;
        let end_time = this.state.endValue;
        let roomId = 0;
        try {
            roomId = this.props.location.state.rid;
        }catch (e) {
            if(!roomId)
            {
                message.error(Utility.language('缺少房间参数，请从上个页面重新进来', this.state.lang));
                return false;
            }
        }
        if(start_time)
            start_time = start_time.format('YYYY-MM-DD');
        if(end_time)
            end_time = end_time.format('YYYY-MM-DD');
        var bid = localStorage.getItem('bid');
        let page = this.state.currentPage;
        let limit = this.state.pageSize;
        let res = await Xhr.get('roomwater/getCharmDetail?bid=' + bid + '&room_id=' + roomId + '&start_time=' + start_time + '&end_time=' + end_time + '&page=' + page + '&limit=' + limit)
        if (res.success) {
            this.setState({
                data: res.data.data,
                total: res.data.total,
                roomId: res.data.room_info.room_id,
                uid: res.data.room_info.uid,
                name: res.data.room_info.name,
            });
        }else{
            message.error(res.msg)
        }
    }

    render() {
        const { startValue, endValue, endOpen, tableScrollX } = this.state;
        const paginationProps = {
            onChange : (page) => this.handleTableChange(page),
            total: this.state.total,
            showSizeChanger: true,
            defaultCurrent: this.state.currentPage,
            current: this.state.currentPage,
            pageSizeOptions: [10,20,50,100,500,1000],
        }


        return (
            <article className="page-room-char-detail">
                <PageHeader
                    className="site-page-header"
                    onBack={() => window.history.back()}
                    title={Utility.language('返回房间流水', this.state.lang)}
                    subTitle=""
                />
                <header className="prcd-header">
                    <Space>
                        <span>{Utility.language('房间魅力榜单', this.state.lang)}</span>
                        <Card bordered={false}>
                            <span>{Utility.language('房间id', this.state.lang)}：{this.state.roomId} {Utility.language('房主ID', this.state.lang)}：{this.state.uid} {Utility.language('房主昵称', this.state.lang)}：{this.state.name}</span>
                        </Card>
                    </Space>
                    <Space>
                        {Utility.language('日期', this.state.lang)} ： <DatePicker
                        disabledDate={this.disabledStartDate}
                        value={startValue}
                        placeholder="Start"
                        onChange={this.onStartChange}
                        onOpenChange={this.handleStartOpenChange}
                        />
                        <DatePicker
                            disabledDate={this.disabledEndDate}
                            value={endValue}
                            placeholder="End"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                        />
                        <Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
                    </Space>
                </header>
                        <Table
                            className="prcd-table"
                            scroll={{ x: tableScrollX }}
                            rowKey={record => record.uid}
                            columns={this._columns}
                            dataSource={this.state.data}
                            bordered
                            pagination={paginationProps}
                            onChange={this.tableChange}
                        />
            </article>
        );
    }
}