import React, { Component } from 'react';
import { Result } from 'antd';

export default class ForbiddenView extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Result
                status="403"
                title="你无权对此操作"
                subTitle="当前内容您无权进行操作，如有疑问联系管理员"
            />
        );
    }
}