import eventCenter from './EventCenter';
import 'babel-polyfill';
import 'fetch-detector';
import 'fetch-ie8';

require('es6-promise').polyfill();

var checkUserPurview = function (res) {
	if (res.status >= 200 && res.status < 500) {
		var copy = res.clone();
		copy.json().then((r)=>{
			// eslint-disable-next-line
			if(r.code && r.code == -1)
			{
				logout();
			}
		})
		var userResp = res.headers.get('User-Status');
		if (userResp) {
			try {
				var json = JSON.parse(userResp);
				eventCenter.emit("Status.Code", json.status);
				// eslint-disable-next-line
				if(json && json.status == 403)
				{
					logout();
				}
				return false;
			} catch (e) {
				//console.log(e);
			}
		}
	}
	// eslint-disable-next-line
	if (res.status == 403 || res.status == 302 || res.status == 405) {
		eventCenter.emit("Status.Code", res.status);
		return false;
	}
	return true;
}

var logout = function () {
	localStorage.removeItem('uid');
	localStorage.removeItem('token');
	localStorage.removeItem('fu');
	localStorage.removeItem('uinfo');
}

export default class Xhr {
	static get Domain() {
		// eslint-disable-next-line
		if (window.location.hostname == 'localhost' || window.location.hostname =='oversea.imee.com') {
			return 'http://oversea.imee.com/';
		}
		return 'https://'+window.location.hostname+'/';
	}

	static cross() {
		return 'cors' ;
	}

	static credit() {
		// eslint-disable-next-line
		if (window.location.hostname == 'localhost' || window.location.hostname =='oversea.imee.com') {
			return 'same-origin';
		} else {
			return 'include';
		}
	}


	static get(url) {
		// eslint-disable-next-line
		let surl = url.indexOf('http') == 0 ? url : Xhr.Domain + url;
		let token = localStorage.getItem('token');
		let lang = localStorage.getItem('lang');
		if(!lang)
			lang = 'zh_cn';
		surl += '&lang='+lang;
		let header = {};
		if (token) {
			header['User-Token'] = encodeURIComponent(token);
		}
		header["User-Language"] = lang;
		return new Promise((resolve, reject) => {
			fetch(surl, {
				mode: Xhr.cross(),
				credentials: Xhr.credit(),
				method: 'GET',
				redirect: 'manual',
				headers:header
			}).then((res) => {
				// console.log(res);
				if (checkUserPurview(res)) {
					return res.json();
				} else {
					return false;
				}
			}).then((res) => {
				if (res !== false) resolve(res);
			}).catch((e => {
				reject(e);
			}));
		});
	}

	static post(url, post) {
		// eslint-disable-next-line
		let surl = url.indexOf('http') == 0 ? url : Xhr.Domain + url;
		let token = localStorage.getItem('token');
		let lang = localStorage.getItem('lang');
		let _websession = localStorage.getItem('wesession');
		let websession = "";
		if(_websession)
		{
			_websession = JSON.parse(_websession);
			websession = _websession.websession;
		}
		if(!lang)
			lang = 'zh_cn';
		let header = {};
		post['lang'] = lang;
		header["Content-Type"] = "application/x-www-form-urlencoded"
		if (token) {
			header['User-Token'] = encodeURIComponent(token);
		}
		if(websession)
		{
			header['WEBSESSION'] = websession;
		}
		header["User-Language"] = lang;
		return new Promise((resolve, reject) => {
			var formData = [];
			for (var key in post) {
				if (post.hasOwnProperty(key)) {
					formData.push(`${key}=` + encodeURIComponent(post[key]));
				}
			}
			fetch(surl, {
				mode: Xhr.cross(),
				credentials: Xhr.credit(),
				method: 'POST',
				redirect: 'manual',
				headers: header,
				body: formData.join("&"),
			}).then((res) => {
				if (checkUserPurview(res)) {
					return res.json();
				} else {
					return false;
				}
			}).then((res) => {
				if (res !== false) resolve(res);
			}).catch((e => {
				reject(e);
			}));
		});
	}
}
