import React, {Component} from 'react';
import {
    Table, Input, Button, Select,
    message, Modal, Space, Tooltip,
    Form, InputNumber, Alert
} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import Xhr from '../libs/Xhr';
import Utility from '../libs/Utility';
import {ExclamationCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import './scss/BrokerUsers.scss';
import {Link} from "react-router-dom";
import BaseModal from "../libs/BaseModal";
import CancelModal from "../components/CancelModal";


export default class UserLevel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            lang: 'zh_cn',
            tableScrollX: 1200,
            rowId:0,
            columns: [],
        }

        this._columns = [
            {
                title: Utility.language('成员类型', this.state.lang),
                dataIndex: 'level_str',
                key: 'level_str',
                defaultSortOrder: 'descend',
            },
            {
                title: Utility.language('定义', this.state.lang),
                dataIndex: 'desc',
                key: 'desc',
            },
            {
                title: Utility.language('实时数量', this.state.lang),
                dataIndex: 'num',
                key: 'num',
            },

        ]
        this._load();
    }

    componentDidMount() {
        var lang = localStorage.getItem('lang');
        this.setState({
            lang: lang ? lang : 'zh_cn',
        })
    }

    tableChange = (pagination, filters, sorter, extra) => {

    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRows,
            selectedRowKeys,
            currentPage: 1,
        });
    };

    start = () => {
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 500);
    };


    async _load() {
        var bid = localStorage.getItem('bid');
        var res = await Xhr.get('broker/brokeruserslevel?bid=' + bid )
        if (res.success) {
            this.setState({
                data: res.data,
                columns: this._columns,
            });
        } else {
            message.error(res.msg)
        }


    }

    handleOnCancelModalCancel=()=>{
        this.setState({cancelModal:false,rowId:0})
    }
    render() {
        const { tableScrollX} = this.state;
        const rowSelection = {
            onChange: this.onSelectChange,
        };
       return (
            <article className="page-broker-users">
                <Table
                    className="pbu-table"
                    scroll={{ x: tableScrollX }}
                    // rowSelection={rowSelection}
                    // rowKey={record => record.id}
                    columns={this.state.columns}
                    dataSource={this.state.data}
                    bordered
                    onChange={this.tableChange}
                />
            </article>
        );
    }
}
