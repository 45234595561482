import React, {Component} from 'react';
import {
    Table, Input, Button, Select,
    message, Modal, Space, Tooltip,
    Form, InputNumber, Alert
} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import Xhr from '../libs/Xhr';
import Utility from '../libs/Utility';
import {ExclamationCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import './scss/BrokerUsers.scss';
import {Link} from "react-router-dom";
import BaseModal from "../libs/BaseModal";
import CancelModal from "../components/CancelModal";


export default class BrokerUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            total: 0,
            currentPage: 1,
            pageSize: 10,
            ssuid: '',
            dt: 0,
            selectedRowKeys: [],
            loading: false,
            pack_cal: 0,
            searchText: '',
            searchedColumn: '',
            lang: 'zh_cn',
            isRate: false,
            tableScrollX: 1200,
            cancelModal: false,
            rowId:0,
            columns: [],
        }


        this._columns = [
            {
                title: Utility.language('成员ID', this.state.lang),
                dataIndex: 'uid',
                key: 'uid',
                ...this.getColumnSearchProps('uid'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.uid - b.uid,
            },
            {
                title: Utility.language('成员昵称', this.state.lang),
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: Utility.language('成员vip等级', this.state.lang),
                dataIndex: 'vip',
                key: 'vip',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.vip - b.vip,
            },
            {
                title: (
                    <Tooltip
                        title={
                            <React.Fragment>
                                <div>
                                    {Utility.language('当商业厅内某用户给A主播赠送了100钻礼物', this.state.lang)}
                                </div>
                                <div>
                                    {Utility.language('1、公会长设定A主播的人气比为0%：', this.state.lang)}
                                    {Utility.language('则A主播立即收到0公会魅力值，Ta的公会长立即收到70公会魅力值；', this.state.lang)}
                                </div>
                                <div>
                                    {Utility.language('2、公会长设定A主播的人气比为90%：', this.state.lang)}
                                    {Utility.language('则A主播立即收到63公会魅力值，Ta的公会长立即收到7公会魅力值', this.state.lang)}
                                </div>
                            </React.Fragment>
                        }
                    >
                        <span>{Utility.language('人气分配比', this.state.lang)}<QuestionCircleOutlined /></span>
                    </Tooltip>
                ),
                dataIndex: 'rate',
                key: 'rate',
                tooltip: "This is a required field",
            },
            {
                title: Utility.language('状态', this.state.lang),
                dataIndex: 'state',
                key: 'state',
                render: (text, record) => {
                    return this._columnsStateRender(text, record)
                },
            },
            {
                title: Utility.language('结算类型', this.state.lang),
                dataIndex: 'pack_cal',
                key: 'pack_cal',
                render: (text, record) => {
                    // eslint-disable-next-line
                    return text == 1 ? Utility.language('打包结算', this.state.lang) : Utility.language('普通结算', this.state.lang)
                },
            },
            {
                title: Utility.language('加入时间', this.state.lang),
                dataIndex: 'date',
                key: 'date',
                render: (text, record) => {
                    return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy.MM.dd') : '';
                },
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.date - b.date,
            },
            {
                title: Utility.language('成员类型', this.state.lang),
                dataIndex: 'level_name',
                key: 'level_name',
                ...this.getColumnSearchProps('level_name'),
            },
            {
                title: Utility.language('是否签约', this.state.lang),
                dataIndex: 'is_only_str',
                key: 'is_only_str',
                  ...this.getColumnSearchProps('is_only_str'),
            },
            {
                title: Utility.language('是否有效主播', this.state.lang),
                dataIndex: 'is_effective_gs',
                key: 'is_effective_gs',
                ...this.getColumnSearchProps('is_effective_gs'),
            },
            {
                title: Utility.language('最后登录时间', this.state.lang),
                dataIndex: 'online_dateline',
                key: 'online_dateline',
                render: (text, record) => {
                    return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy.MM.dd HH:mm:ss') : '';
                },
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.date - b.date,
            }, {
                title: Utility.language('操作', this.state.lang),
                key: 'action',
                render: (text, record) => (
                    <span>

						{
                            // eslint-disable-next-line
						    text.state == 0 ?
                            <React.Fragment>
                                <Button type="link" onClick={() => {
                                    this._confirmModal(text.id, "agree", Utility.language('确定通过该成员的入会申请？', this.state.lang))
                                }}>
                                    {Utility.language('入会审核通过', this.state.lang)}
                                </Button>
                                <Button type="link" onClick={() => {
                                    this._confirmModal(text.id, "reject", Utility.language('确定拒绝该成员的入会申请？', this.state.lang))
                                }}>
                                    {Utility.language('入会审核拒绝', this.state.lang)}
                                </Button>
                            </React.Fragment>
                            : ''}

                        {
                            // eslint-disable-next-line
                            text.state == 3 ?
                            <React.Fragment>
                                <Button type="link" onClick={() => {
                                    this._confirmModal(text.id, "exit", Utility.language('确定同意该成员的退会申请？', this.state.lang))
                                }}>
                                    {Utility.language('同意退会', this.state.lang)}
                                </Button>
                                <Button type="link" onClick={() => {
                                    this._confirmModal(text.id, "refuse", Utility.language('确定拒绝该成员的退会申请？', this.state.lang))
                                }}>
                                    {Utility.language('拒绝退会', this.state.lang)}
                                </Button>
                            </React.Fragment>
                            : ''}
                        {
                            // eslint-disable-next-line
                            text.state == 1 ?
                            <Button type="link" onClick={() => {
                                this._confirmModal(text.id, "remove", Utility.language('确定从公会移除该成员？', this.state.lang))
                            }}>
                                {Utility.language('移出公会', this.state.lang)}
                            </Button>
                            : ''}

                        {
                            //  签约/解约
                            (text.is_only == 0 || text.only_status == 4) && ( text.state == 1 ) &&
                                <Button type="link"  onClick={() => {
                                    this.doSign(text.id)
                                }}>
                                        {Utility.language('签约', this.state.lang)}

                                    </Button>
                        }
                        {
                            text.is_only == 1 &&
                            <Button type="link" onClick={() => {
                                this.cancelSign(text.id)
                            }}>
                                {Utility.language('解约', this.state.lang)}
                            </Button>
                        }
					</span>
                ),
            },
        ]

        this._load();
    }

    componentDidMount() {
        var lang = localStorage.getItem('lang');
        this.setState({
            lang: lang ? lang : 'zh_cn',
        })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={     text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({
            searchText: ''
        });
    };
    // 解约
    cancelSign = id => {
      this.setState({cancelModal: true,rowId:id})
    }
    //  签约
    doSign = id => {
        this._confirmSignModal(id,  Utility.language('通过系统通知给对应的用户发送签约邀请？', this.state.lang))
    }



    _columnsStateMap = {
        0: '加入待审核',
        1: '已通过',
        2: '已拒绝',
        3: '退会待审核',
    }

    _columnsStateRender(text, record) {
        if (this._columnsStateMap[text]) {
            return Utility.language(this._columnsStateMap[text], this.state.lang);
        }
        return text;
    }

    handleTableChange = (pagination, pageSize) => {

    }

    selectChange = (value) => {
        this.setState({
            dt: value,
            currentPage: 1,
        }, () => {
            this._load();
        })
    }

    inputChange = (value) => {
        this.setState({
            ssuid: value,
            currentPage: 1,
        }, () => {
            this._load();
        })
    }

    onOk(value) {
        //console.log('onOk: ', value);
    }

    onChange(value, dateString) {
        //console.log('Selected Time: ', value);
        //console.log('Formatted Selected Time: ', dateString);
    }

    tableChange = (pagination, filters, sorter, extra) => {
        console.log(extra);
        if (extra.action !== 'paginate')
            return false;
        this.setState({
                currentPage: pagination.current,
                pageSize: pagination.pageSize,
            }, () => this._load()
        );
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRows,
            selectedRowKeys,
            currentPage: 1,
        });
    };

    start = () => {
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 500);
    };

    _confirmModal = (uid, action, message) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined/>,
            content: message,
            okText: Utility.language('确认', this.state.lang),
            cancelText: Utility.language('取消', this.state.lang),
            onOk: () => {
                this._brokerUserAction(uid, action)
            }
        });
    }
    _confirmPackModal = (uid, message) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined/>,
            content: message,
            okText: Utility.language('确认', this.state.lang),
            cancelText: Utility.language('取消', this.state.lang),
            onOk: () => {
                this._brokerUserPackAction(uid)
            }
        });
    }
    _confirmSignModal = (id,message) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined/>,
            content: message,
            okText: Utility.language('确认', this.state.lang),
            cancelText: Utility.language('取消', this.state.lang),
            onOk: () => {
                this._sign(id)
            }
        });

    }

    _setPatchPopular = () => {
        const validateMessages = {
            // eslint-disable-next-line
            required: '${label} required!',
            types: {
                // eslint-disable-next-line
                email: '${label} is not a valid email!',
                // eslint-disable-next-line
                number: '${label} is not a valid number!',
            },
            number: {
                // eslint-disable-next-line
                range: '${label} must be between ${min} and ${max}',
            },
        };
        const limitDecimals = (value: string | number): string => {
            // eslint-disable-next-line
            const reg = /^(\-)*(\d+).*$/;
            if(typeof value === 'string') {
                return !isNaN(Number(value)) ? value.replace(reg, '$1$2') : ''
            } else if (typeof value === 'number') {
                return !isNaN(value) ? String(value).replace(reg, '$1$2') : ''
            } else {
                return ''
            }
        };
        const { selectedRows } = this.state;
        const message =  (
            <div>
                <div>
                    {Utility.language('会长成功配置A主播的比例后，A主播将能收到【该比例部分】的人气魅力值', this.state.lang)}
                </div>
                <div>
					<span style={{color:'red'}}>
                        {Utility.language('当主播和平台审核通过后，分成比会实时生效', this.state.lang)}
					</span>
                </div>
            </div>
        );
        let defaultUid = [];
        selectedRows.forEach((item) => {
            defaultUid.push(item.uid);
        })
        this.setState({
            modalTitle: Utility.language('设置公会成员与公会人气分配比例', this.state.lang),
            modalData: <div>
                <Form name="nest-messages" onFinish={this.onRateFinish} validateMessages={validateMessages}>
                    <Form.Item initialValue={defaultUid} name="uid" label={Utility.language('成员uids集合', this.state.lang)} rules={[{ required: true }]} help={Utility.language('支持手动在后面用英文逗号拼接uid', this.state.lang)}>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="rate" label={Utility.language('人气分配比', this.state.lang)} rules={[{ type: 'number', min: 0, max: 100 },{ required: true }]} help={Utility.language('数字必须在0~100之间的整数，没有小数', this.state.lang)}>
                        <InputNumber
                            min="0"
                            max="100"
                            step="1"
                            formatter={limitDecimals}
                            parser={limitDecimals}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            {Utility.language('提交', this.state.lang)}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Alert
                            message={Utility.language('人气分配比例说明', this.state.lang)}
                            description={message}
                            type="success" />
                    </Form.Item>
                </Form>
            </div>
        })
        this.showModal()
    }

    showModal = () => {
        this.setState({
            visible: true,
            modalKey: Math.random(),
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    onRateFinish = (values: any) => {
        this.setRate(values);
    }

    async setRate(values) {
        var bid = localStorage.getItem('bid');
        var res = await Xhr.post('broker/setRate', {uids: values.uid, rate: values.rate, bid: bid});
        if (res.success) {
            message.success('success!');
            this.handleCancel();
        } else {
            message.error(res.msg)
        }
    }
    async _sign (id)  {
        var res = await Xhr.post('broker/doSign', {id:id});
        if (res.success == true) {
            message.success('success!');
        } else {
            message.error(res.msg)
        }
        this._load()
    }

    async _brokerUserAction(uid, action) {
        var bid = localStorage.getItem('bid');
        var res = await Xhr.post('broker/brokeradmin', {ids: uid, ac: action, bid: bid});
        if (res.success) {
            message.success('success');
            this.start();
            this._load();
        } else {
            this.start();
            message.error(res.msg);
        }
    };

    async _brokerUserPackAction(uid) {
        var bid = localStorage.getItem('bid');
        var res = await Xhr.post('broker/sendPackProtocol', {ids: uid, bid: bid});
        if (res.success) {
            message.success('success');
            this.start();
            this._load();
        } else {
            this.start();
            message.error(res.msg);
        }
    };


    async _agreeAll() {
        this.setState({loading: true});
        var ids = this.state.selectedRowKeys;
        if (ids.length < 1) {
            message.error(Utility.language('所选个数存在问题', this.state.lang));
            this.start();
            return false;
        }

        var bid = localStorage.getItem('bid');
        var res = await Xhr.post('broker/brokeradmin', {ids: ids.join(','), ac: 'multagree', bid: bid});
        if (res.success) {
            message.success('success');
            this.start();
            this._load();
        } else {
            this.start();
            message.error(res.msg);
        }

    };

    async _exitAll() {
        this.setState({loading: true});
        var ids = this.state.selectedRowKeys;
        if (ids.length < 1) {
            message.error(Utility.language('所选个数存在问题', this.state.lang));
            this.start();
            return false;
        }
        var bid = localStorage.getItem('bid');
        var res = await Xhr.post('broker/brokeradmin', {ids: ids.join(','), ac: 'multrefuse', bid: bid});
        if (res.success) {
            message.success('success');
            this.start();
            this._load();
        } else {
            this.start();
            message.error(res.msg);
        }

    };


    async _load() {
        var page = this.state.currentPage;
        var bid = localStorage.getItem('bid');
        var dt = this.state.dt;
        var st = 1;
        let limit = this.state.pageSize
        // console.log('state.searchText', this.state.searchText);
        // console.log('state.searchedColumn', this.state.searchedColumn);
        // eslint-disable-next-line
        if (this.state.searchedColumn == 'uid' && this.state.searchText) {
            var ssuid = this.state.searchText;
        } else {
            // eslint-disable-next-line
            var ssuid = this.state.ssuid;
        }
        var res = await Xhr.get('broker/brokerusers?bid=' + bid + '&ssuid=' + ssuid + '&dt=' + dt + '&st=' + st + '&page=' + page + '&limit=' + limit)
        if (res.success) {
            if(!res.isRate)
            {
                this._columns.map((item, key) => {
                    if(item.key == 'rate')
                        delete this._columns[key];
                })
            }
            this.setState({
                data: res.data.data,
                total: res.total,
                isRate: res.isRate,
                columns: this._columns,
            });
        } else {
            message.error(res.msg)
        }


    }

    handleOnCancelModalCancel=()=>{
        this.setState({cancelModal:false,rowId:0})
    }

     handleOnCancelModalok=(data)=>{
        this.setState({cancelModal:false})
        let id = this.state.rowId
         var res =  Xhr.post('broker/breakOffSign', {id: id,...data});

        if(res.success === false) {
            message.error(res.msg)
        }else{
            message.success('success');
        }
        this._load()
    }

    render() {
        const paginationProps = {
            onChange: (page, pageSize) => this.handleTableChange(page, pageSize),
            total: this.state.total,
            showSizeChanger: true,
            defaultCurrent: this.state.currentPage,
            current: this.state.currentPage
        }
        const {Option} = Select;
        const {Search} = Input;

        const {loading, selectedRowKeys, tableScrollX,cancelModal} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;


        return (
            <article className="page-broker-users">
                <header className="pbu-header">
                    <Space>
                        <Search
                            placeholder={Utility.language('输入uid', this.state.lang)}
                            enterButton="Search"
                            onSearch={value => this.inputChange(value)}
                        />
                        <Select
                            placeholder={Utility.language('状态筛选', this.state.lang)}
                            optionFilterProp="children"
                            onChange={this.selectChange}
                        >
                            <Option value="0">{Utility.language('全部状态', this.state.lang)}</Option>
                            <Option value="1">{Utility.language('已通过', this.state.lang)}</Option>
                            <Option value="2">{Utility.language('加入待审核', this.state.lang)}</Option>
                            <Option value="3">{Utility.language('退会待审核', this.state.lang)}</Option>
                        </Select>
                        <Button type="primary" onClick={() => this._agreeAll()} disabled={!hasSelected}
                                loading={loading}>
                            {Utility.language('加入批量通过', this.state.lang)}
                        </Button>
                        {/*{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}*/}
                        <Button type="primary" onClick={() => this._exitAll()} disabled={!hasSelected}
                                loading={loading}>
                            {Utility.language('退会批量拒绝', this.state.lang)}
                        </Button>
                        {/*<span>{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>*/}
                        <Button hidden={!this.state.isRate} type="primary" onClick={() => {this._setPatchPopular()}} disabled={!hasSelected} loading={loading}>
                            {Utility.language('批量设置人气分配', this.state.lang)}
                        </Button>
                        <Link to="/static/ratedata" hidden={!this.state.isRate}>
                            <Button type="primary">
                                {Utility.language('人气比例调整记录', this.state.lang)}
                            </Button>
                        </Link>
                    </Space>
                </header>

                <BaseModal
                    title={this.state.modalTitle}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    data={this.state.modalData}
                    key={this.state.modalKey}
                >
                </BaseModal>

                <Table
                    className="pbu-table"
                    scroll={{ x: tableScrollX }}
                    rowSelection={rowSelection}
                    rowKey={record => record.id}
                    columns={this.state.columns}
                    dataSource={this.state.data}
                    bordered
                    pagination={paginationProps}
                    onChange={this.tableChange}
                />
                <CancelModal visible={cancelModal} onCancel={this.handleOnCancelModalCancel} onOk={this.handleOnCancelModalok} title={Utility.language('解约', this.state.lang)}/>
            </article>
        );
    }
}
