var ko = {};
ko.l = {
    "今日总流水": "금일 총 수익",
    "本周期总流水": "이번 주 총 수익",
    "环比昨日": "어제 대비",
    "环比上周": "지난 주 대비",
    "日期": "날짜",
    "订单详情": "주문 정보",
    "账期": "결산기",
    "成员ID": "멤버ID",
    "昵称": "닉네임",
    "人气值奖励": "인기도 보상",
    "人气值审核": "인기도 심사",
    "成员vip等级": "멤버 VIP 레벨",
    "结算类型": "정산 유형",
    "打包结算": "정산",
    "普通结算": "일반 정산",
    "加入时间": "가입 시간",
    "最后登录时间": "마지막 접속 시간",
    "操作": "작업",
    "确定通过该成员的入会申请？": "해당 멤버의 가입 신청을 동의하겠습니까?",
    "确定拒绝该成员的入会申请？": "해당 멤버의 가입 신청을 거부하겠습니까?",
    "入会审核通过": "에이전시 가입 심사 통과",
    "入会审核拒绝": "에이전시 가입 심사 거부",
    "确定同意该成员的退会申请？": "해당 멤버의 탈퇴 신청을 동의하겠습니까?",
    "同意退会": "동의하기",
    "确定拒绝该成员的退会申请？": "해당 멤버의 탈퇴 신청을 거부하겠습니까?",
    "拒绝退会": "거부하기",
    "确定从公会移除该成员？": "해당 멤버를 에이전시에서 제거하겠습니까?",
    "移出公会": "에이전시에서 제거",
    "加入待审核": "가입 신청 심사 중",
    "已通过": "승인",
    "已拒绝": "거절됨",
    "退会待审核": "탈퇴 신청 심사 중",
    "确认": "확인",
    "取消": "최소",
    "所选个数存在问题": "선택한 수량이 오류가 있습니다.",
    "输入uid": "UID입력하기",
    "状态筛选": "상태 필터",
    "全部状态": "모든 상태",
    "加入批量通过": "대량 승인",
    "退会批量拒绝": "대량 거절",
    "主播ID": "비제이ID",
    "主播昵称": "비제이 닉네임",
    "返回公会奖励": "에이전시 복귀 보상",
    "同意": "동의",
    "时间": "시간",
    "人气变更对象": "인기도 변경 대상",
    "房间": "방",
    "人气变更详细": "인기도 변경 내역",
    "生效时间": "발효 시간",
    "人气值变化": "인기도 변경",
    "时长": "기간",
    "操作人id": "조작원ID",
    "返回房间管理": "방 관리로 뒤로가기",
    "房间id": "방ID",
    "欢迎光临": "어서오세요",
    "请输入手机号码": "전화번호를 입력해주세요.",
    "请输入正确的图形验证码": "그래픽 인증번호를 정확히 입력하세요.",
    "请填写正确的手机验证码": "정확한 휴대폰 인증 코드를 입력하세요.",
    "正在登录中": "로그인 중",
    "你未被邀请开通公会": "에이전시 설립 초대를 받지 않았습니다.",
    "手机号": "전화번호",
    "请输入右边的图形验证码!": "우측의 그래픽 인증번호를 입력하세요.",
    "图形码": "그래픽 인증번호",
    "验证码": "인중코드",
    "请输入你收到的验证码!": "인증코드를 입력하세요.",
    "获取验证码": "인증코드 받기",
    "秒后重发": "초 후 다시 발송",
    "登录": "로그인",
    "订单流水": "주문 매출",
    "订单成功数": "완성한 주문량",
    "订单失败数": "실패된 주문량",
    "有单人数": "주문 접수한 인원수",
    "返回公会流水": "에이전시 수익으로 가기",
    "公会流水监控": "에이전시 수익 모니터링",
    "今日订单总流水": "금일 주문 총 수익",
    "本周期订单总流水": "이번 주 주문 총 수익",
    "本周订单成功数": "이번 주 완성된 주문량",
    "本周期订单失败数": "이번 주 실패된 주문량",
    "本周期有单人数": "이번주 주문 접수한 인원수",
    "房间昵称": "방제목",
    "接待id": "진행자ID",
    "接待昵称": "진행자 닉네임",
    "在档房间流水": "착석 시 방 수익",
    "在坑流水": "착석 시 수익",
    "开始日期": "시작일",
    "结束日期": "종료일",
    "魅力值": "매력치",
    "缺少房间参数，请从上个页面重新进来": "방 데이터가 없어 뒤로가기 해서 다시 로딩해 주세요.",
    "返回房间流水": "방 수익으로 가기",
    "房间魅力榜单": "방 매력 랭킹",
    "房主昵称": "방방 닉네임",
    "房主ID": "방장ID",
    "房间状态": "방 상태",
    "房间业务": "방 업무",
    "麦时（本周期）": "착석 시간(이번 주)",
    "设置房间人气值": "방 인기도 설정",
    "房间信息": "방 정보",
    "公会人气值": "에이전시 인기도",
    "人气设置": "인기도 설정",
    "使用时长": "사용시간",
    "提交设置": "설정하기",
    "数据格式错误": "데이터 양식 오류",
    "人气设置不能为空": "인기도 설정해야 합니다.",
    "时长不能为空": "시간을 설정해야 합니다.",
    "人气只能是大于100的正整数": "인기도는 >100이어야 합니다.",
    "时长只能是大于1的正整数": "시간은 >1이어야 합니다.",
    "待创建": "대기",
    "正常": "정상",
    "关闭": "폐쇄",
    "删除": "삭제",
    "房间频道": "방 카테고리",
    "房间流水": "방 수익",
    "麦时": "착석 시간",
    "房间流水详细": "방 수익 내역",
    "时段": "시간대",
    "流水": "수익",
    "房间流水监控": "방 수익 모니터링",
    "公会类型": "에이전시 유형",
    "结算金额": "정산 금액",
    "查看详细": "전체 보기",
    "说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准": "설명: 해당 정산에 보너스가 포함되지 않습니다. 보너스 기준은 운영팀의 공지를 참고하세요.",
    "缺少日期参数，请从上个页面重新进来": "날짜 데이터가 없어 뒤로가기 해서 다시 로딩해 주세요.",
    "缺少跳转参数，请从上个页面重新进来": "데이터가 없어 뒤로가기 해서 다시 로딩해 주세요.",
    "返回结算管理": "정산 관리로 가기",
    "未发放": "미지급",
    "已发放": "지급됨",
    "Partying业务": "Partying업무",
    "签约成员": "계약 멤버",
    "房间管理": "방 관리",
    "流水信息": "수익 정보",
    "公会流水": "에이전시 수익",
    "主播流水": "비제이 수익",
    "接待流水": "진행자 수익",
    "结算信息": "정산 정보",
    "结算管理": "정산 관리",
    "登录方式": "로그 인 방식",
    "手机号登录": "핸드폰 번호 등록",
    "账号登录": "계 정 로그 인",
    "请输入你的账号": "당신 의 계 정 을 입력 하 세 요",
    "请输入你的密码": "비밀 번 호 를 입력 하 세 요.",
    "密码": "비밀 번호",
    "手機號碼格式不合理": "전화번호 양식이 정확하지 않습니다.",
    "驗證碼錯誤": "인증번호가 정확하지 않습니다.",
    "驗證碼好像不對哦…": "인증번호가 정확하지 않습니다.",
    "区号": "국가번호",
    "韩语": "한국어",
    "印尼语": "인도네시아어",
    "泰语": "태국어",
    "阿拉伯语": "아랍어",
    "账号": "계정",
    "越南语": "베트남어",
    "日接待时长": "낮 접대 시간",
    "当前任务等级": "현재 퀘스트 등급",
    "1级任务完成度": "1급 퀘스트 완성도",
    "2级任务完成度": "2급 퀘스트 완성도",
    "3级任务完成度": "3급 퀘스트 완성도",
    "4级任务完成度": "4급 퀘스트 완성도",
    "5级任务完成度": "5급 퀘스트 완성도",
    "接待任务": "진행자 퀘스트",
    "主播任务": "비제이 퀘스트",
    "萌新任务": "새싹 퀘스트",
    "任务等级": "퀘스트 등급",
    "完成状态": "완성 상태",
    "完成": "완성",
    "未完成": "미완성",
    "周期": "주기",
    "任务1": "퀘스트1",
    "任务2": "퀘스트2",
    "任务3": "퀘스트3",
    "任务4": "퀘스트4",
    "任务5": "퀘스트5",
    "任务6": "퀘스트6",
    "任务7": "퀘스트7",
    "任务8": "퀘스트8",
    "任务9": "퀘스트9",
    "任务10": "퀘스트10",
    "任务11": "퀘스트11",
    "任务12": "퀘스트12",
    "任务13": "퀘스트13",
    "返回任务数据": "퀘스트 데이터로 리턴하기",
    "运营数据":"운영 데이터",
    "任务数据":"퀘스트 데이터",
    "有效天":"유효 일수",
    "数据是否聚合":"데이터 통일 여부",
    "是":"예",
    "否":"아니오",
    "直播房间流水":"라이브 방 수익",
    "房间流水汇总":"방의 물이 한데 모이다",
    "当商业厅内某用户给A主播赠送了100钻礼物": "협력방에서 비제이 A에게 다이아몬드 100개를 선물로 주셨을 때",
    "1、公会长设定A主播的人气比为0%：": "1. 길드장이 비제이A의 인기도를 0%로 설정하여：",
    "则A主播立即收到0公会魅力值，Ta的公会长立即收到70公会魅力值；": "그러면 비제이A는 즉시 0 길드 매력치를 받고 비제이A의 길드장은 즉시 70 길드 매력치를 받습니다.",
    "2、公会长设定A主播的人气比为90%：": "2. 길드장이 비제이A의 인기도를 90%로 설정하여：",
    "则A主播立即收到63公会魅力值，Ta的公会长立即收到7公会魅力值": "그러면 비제이A는 즉시 63길드 매력치를 받고 비제이A의 길드장은 즉시 7 길드 매력치를 받습니다.",
    "会长成功配置A主播的比例后，A主播将能收到【该比例部分】的人气魅力值": "길드장이 비제이A 의 비율을 성공적으로 설정한 후 비제이A 는 이 비율에 따라 인기 매력치을 받을 수 있습니다.",
    "当主播和平台审核通过后，分成比会实时生效": "비제이와 플랫폼이 검토를 통과한 후  설정한 비율이 실시간으로 적용됩니다.",
    "设置公会成员与公会人气分配比例": "길드 유저 및 길드 인기 분배 비율을 설정",
    "成员uids集合": "유저 uids 컬렉션",
    "支持手动在后面用英文逗号拼接uid": "수동으로 뒤에 영어 쉼표로 uid 추가하기 지원",
    "人气分配比": "인기 분배 비율",
    "数字必须在0~100之间的整数，没有小数": "숫자는 반드시 0~100 사이의 정수여야 합니다",
    "提交": "제출",
    "人气分配比例说明": "인기 분배 비율 설명",
    "批量设置人气分配": "인기 배분을 대량으로 설정하다",
    "人气比例调整记录": "인기 배분 조정 기록",
    "编号": "번호",
    "成员id": "회원 ID",
    "成员昵称": "유저 닉네임",
    "申请时间": "신청 시간",
    "调整前比例": "조정 전 비율",
    "调整后比例": "조정 후 비율",
    "状态": "상태",
    "返回成员列表": "유저 목록으로 돌아가기",
    "成员uid": "유저 ID",
    "选择状态": "상태 선택",
    "全部": "전부",
    "查询": "조회",
    "人气分配比例变更": "인기 분배 비율 변경",
    "用户ID": "유저ID",
    "用户昵称": "유저 닉네임",
    "公会ID": "길드ID",
    "公会昵称": "길드명",
    "个人与公会人气分配比例变化": "개인 및 길드 인기 분배 비율 변화",
    "当前": "현재",
    "调整后": "조정 후",
    "人气分配比说明": "인기 분배 비율 설명",
    "例：": " 예:",
    " 1、公会长设定A主播的人气比为0%：": " 1. 길드장이 비제이A의 인기비율을 0%로 설정하여：",
    "则A主播立即收到63公会魅力值，Ta的公会长立即收到7公会魅力值；": "그러면 비제이A는 즉시 63길드 매력치를 받고 비제이A의 길드장은 즉시 7 길드 매력치를 받습니다.",
    "拒绝调整": "조정 거부",
    "同意调整": "조정에 동의",
    "缺少表更信息！": "표에 대한 추가 정보가 없습니다!",
    "正在加载中": "로딩 중",
    "确定拒绝这次人气分配比例变更": "이번 인기 분배 비율의 변경을 거부하기로 확정",
    "确定同意这次人气分配比例变更": "이번 인기 분배 비율의 변경 동의하기로 확정",
    "是否签约":"멤버 계약 여부",
    "成员类型":"멤버 유형",
};

export default ko;