import React, {Component} from 'react';
import {Route, Switch} from 'react-router';
import {HashRouter, BrowserRouter} from "react-router-dom";
import {Layout, Menu, Spin} from 'antd';

import {
    TeamOutlined,
    MoneyCollectOutlined,
    FundOutlined
} from '@ant-design/icons';

import Forbidden from './pages/Forbidden';
import Error from './pages/Error';
import Xhr from './libs/Xhr';
import EventCenter from './libs/EventCenter';
import Utility from "./libs/Utility";

import Index from './pages/Index';
import BrokerUsers from './pages/BrokerUsers';
import RoomManage from "./pages/RoomManage";
import BrokerMoney from "./pages/BrokerMoney";
import HotManage from "./pages/HotManage";
import OrderWater from "./pages/OrderWater";
import RoomWater from "./pages/RoomWater";
import RoomWaterDetail from "./pages/RoomWaterDetail";
import HostWater from "./pages/HostWater";
import Login from "./pages/Login";
import BrokerReward from "./pages/BrokerReward";
import HotCheck from "./pages/HotCheck";
import Head from "./components/Head";
import RoomCharDetail from "./pages/RoomCharDetail";
import ReceptionWater from "./pages/ReceptionWater";
import LiveWater from "./pages/LiveWater";
import SettleManage from "./pages/SettleManage";
import SettleManageDetail from "./pages/SettleManageDetail";
import TaskData from './pages/TaskData';
import TaskDataDetail from './pages/TaskDataDetail';
import LineLogin from "./pages/thirdLogin/LineLogin";
import GoogleLogin from "./pages/thirdLogin/GoogleLogin";
import FacebookLogin from "./pages/thirdLogin/FacebookLogin";
import SnapLogin from "./pages/thirdLogin/SnapLogin";
import RateData from "./pages/rate/RateData";
import RoomProfferDetail from "./pages/RoomProfferDetail";
import UserLevel from './pages/UserLevel';

//--------- 样式及图片 --------------
import './App.scss';


const {SubMenu} = Menu;
const { Sider} = Layout;

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            purview: {},
            lang: 'zh_cn',
        }
        EventCenter.on('Status.Code', this._onForbidden);
        this._load();
    }

    componentDidMount() {
        var lang = localStorage.getItem('lang');
        this.setState({
            lang: lang ? lang : 'zh_cn',
        })
    }

    async _load() {
        var res = await Xhr.get("purview/index");
        if (res && res.success) {
            this.setState({
                loading: false,
                purview: res.data
            });
        }
    }

    componentWillUnmount() {
        EventCenter.removeListener('Status.Code', this._onForbidden);
    }

    _onForbidden = (code) => {
        // eslint-disable-next-line
        if (code == 405) {
            var url = Xhr.Domain + '/login/index';
            var sUserAgent = navigator.userAgent;
            if (sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPad') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1) {
                url = url + (url.indexOf('?') > -1 ? '&' : '?') + 'platform=mobile';
            }
            window.location.href = url;
        } else {
            this.refs.location.history.push("/static/forbidden")
        }
    }

    onLocation = (path) => {
        this.refs.location.history.push(path)
    }

    render() {
        if (this.state.loading) {
            return (
                <Spin size="large"/>
            );
        }
        return (
            <MainLayout onLocation={this.onLocation} purview={this.state.purview}>
                <HashRouter ref={'location'}>
                    <Switch>
                        <Route exact path="/" component={Index}/>
                        <Route exact path="/static/login" component={Login}/>
                        <Route exact path="/static/forbidden" component={Forbidden}/>
                        <Route exact path="/static/brokerusers" component={BrokerUsers}/>
                        <Route exact path="/static/userlevel" component={UserLevel}/>
                        <Route exact path="/static/roommanage" component={RoomManage}/>
                        <Route exact path="/static/brokermoney" component={BrokerMoney}/>
                        <Route exact path="/static/hotmanage" component={HotManage}/>
                        <Route exact path="/static/orderwater" component={OrderWater}/>
                        <Route exact path="/static/roomwater" component={RoomWater}/>
                        <Route exact path="/static/roomwaterdetail" component={RoomWaterDetail}/>
                        <Route exact path="/static/hostwater" component={HostWater}/>
                        <Route exact path="/static/brokerreward" component={BrokerReward}/>
                        <Route exact path="/static/hotcheck" component={HotCheck}/>
                        <Route exact path="/static/roomchardetail" component={RoomCharDetail}/>
                        <Route exact path="/static/receptionwater" component={ReceptionWater}/>
                        <Route exact path="/static/livewater" component={LiveWater}/>
                        <Route exact path="/static/settlemanage" component={SettleManage}/>
                        <Route exact path="/static/settlemanagedetail" component={SettleManageDetail}/>
                        <Route exact path="/static/taskdata" component={TaskData} />
                        <Route exact path="/static/taskdatadetail" component={TaskDataDetail} />
                        <Route exact path="/static/roomprofferdetail" component={RoomProfferDetail} />

                        <Route exact path="/h5/loginline" component={LineLogin} />
                        <Route exact path="/h5/logingoogle" component={GoogleLogin} />
                        <Route exact path="/h5/loginfacebook" component={FacebookLogin} />
                        <Route exact path="/h5/loginsnap" component={SnapLogin} />
                        <Route exact path="/static/ratedata" component={RateData} />
                        <Route path="*" component={Error}/>
                    </Switch>
                </HashRouter>
            </MainLayout>
        )
    }
}

class MainLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false
        }
    }

    componentDidMount() {
        if (checkMobile()) this.setState({collapsed: true})
        window.addEventListener('resize', () => this.setState({collapsed: checkMobile()}))
    }

    onCollapse = () => {
        this.setState({collapsed: !this.state.collapsed,});
    }

    _handleMenuClick = ({item, key}) => {
        // eslint-disable-next-line
        switch (key) {
            case 'login':
                return this.props.onLocation('/static/login');
            case 'brokerusers':
                return this.props.onLocation('/static/brokerusers');
            case 'roommanage':
                return this.props.onLocation('/static/roommanage');
            case 'brokermoney':
                return this.props.onLocation('/static/brokermoney');
            case 'hotmanage':
                return this.props.onLocation('/static/hotmanage');
            case 'orderwater':
                return this.props.onLocation('/static/orderwater');
            case 'roomwater':
                return this.props.onLocation('/static/roomwater');
            case 'roomwaterdetail':
                return this.props.onLocation('/static/roomwaterdetail');
            case 'hostwater':
                return this.props.onLocation('/static/hostwater');
            case 'brokerreward':
                return this.props.onLocation('/static/brokerreward');
            case 'hotcheck':
                return this.props.onLocation('/static/hotcheck');
            case 'roomchardetail':
                return this.props.onLocation('/static/roomchardetail');
            case 'receptionwater':
                return this.props.onLocation('/static/receptionwater');
            case 'livewater':
                return this.props.onLocation('/static/livewater');
            case 'settlemanage':
                return this.props.onLocation('/static/settlemanage');
            case 'settlemanagedetail':
                return this.props.onLocation('/static/settlemanagedetail');
            case 'taskdata':
                return this.props.onLocation('/static/taskdata');
            case 'taskdatadetail':
                return this.props.onLocation('/static/taskdataDetail');
            case 'roomprofferdetail':
                return this.props.onLocation('/static/roomprofferdetail');
            case 'userlevel':
                return this.props.onLocation('/static/userlevel');
            case 'logout':
                window.location.href = Xhr.Domain + 'login/logout';
        }
    }

    _renderContent = () => {
        return (
            <>
                {/*<Content style={{background: '#fff', minHeight: 850,}}>*/}
                {this.props.children}
                {/*</Content>*/}
                {/*{this._renderFooter()}*/}
            </>

        );
    }

    _renderSider = () => {
        return (
            <Sider collapsible={true} collapsedWidth="70"
                   trigger={null} className="page-sider" collapsed={this.state.collapsed}>
                <div className="ps-logo">
                    <img alt="img" src="https://xs-image.pati.sg/backstage/partying/family/logo.png" className="ps-logo-icon"/>
                    <h2 className={this.state.collapsed ? 'ps-logo-hide' : ''}>Partying</h2>
                </div>
                <Menu theme="dark" mode="inline" className="ps-menu"
                      onClick={this._handleMenuClick}
                      defaultOpenKeys={['sub2', 'sub3', 'sub4','sub7']}
                >
                    <SubMenu
                        key="sub2"
                        title={
                            <span>
								<TeamOutlined/>
								<span>{Utility.language('Partying业务', this.state.lang)}</span>
							</span>
                        }
                    >
                        <Menu.Item key="brokerusers">{Utility.language('签约成员', this.state.lang)}</Menu.Item>
                        <Menu.Item key="roommanage">{Utility.language('房间管理', this.state.lang)}</Menu.Item>
                        <Menu.Item key="userlevel">{Utility.language('主播管理', this.state.lang)}</Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub3"
                        title={
                            <span>
								<MoneyCollectOutlined/>
								<span>{Utility.language('流水信息', this.state.lang)}</span>
							</span>
                        }
                    >
                        <Menu.Item key="brokermoney">{Utility.language('公会流水', this.state.lang)}</Menu.Item>
                        <Menu.Item key="roomwater">{Utility.language('房间流水', this.state.lang)}</Menu.Item>
                        <Menu.Item key="hostwater">{Utility.language('主播流水', this.state.lang)}</Menu.Item>
                        <Menu.Item key="receptionwater">{Utility.language('接待流水', this.state.lang)}</Menu.Item>
                        <Menu.Item key="livewater">{Utility.language('直播房间流水', this.state.lang)}</Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub4"
                        title={
                            <span>
								<MoneyCollectOutlined/>
								<span>{Utility.language('结算信息', this.state.lang)}</span>
							</span>
                        }
                    >
                        <Menu.Item key="settlemanage">{Utility.language('结算管理', this.state.lang)}</Menu.Item>
                        {/*<Menu.Item key="brokerreward">公会奖励</Menu.Item>*/}
                    </SubMenu>
                    <SubMenu
                        key="sub7"
                        title={
                            <span>
                            <FundOutlined />
                            <span>{Utility.language('运营数据', this.state.lang)}</span>
                            </span>
                            }>
                    <Menu.Item key="taskdata">{Utility.language('任务数据', this.state.lang)}</Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
        );
    }
    _renderArSider = () => {
        return (
            <Sider collapsible={true} collapsedWidth="70" trigger={null} className="page-sider"
                   collapsed={this.state.collapsed}>
                <div className="ps-logo">
                    <img alt="img" src="https://xs-image.pati.sg/backstage/partying/family/logo.png" className="ps-logo-icon"/>
                    <h2 className={this.state.collapsed ? 'ps-logo-hide' : ''}>Partying</h2>
                </div>
                <Menu theme="dark"
                      mode="inline"
                      onClick={this._handleMenuClick}
                      defaultOpenKeys={['sub2', 'sub3', 'sub4','sub7']}
                >
                    <SubMenu
                        key="sub2"
                        title={
                            <span>
								<TeamOutlined/>
								<span>{Utility.language('Partying业务', this.state.lang)}</span>
							</span>
                        }
                    >
                        <Menu.Item key="brokerusers">{Utility.language('成员列表', this.state.lang)}</Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub3"
                        title={
                            <span>
								<MoneyCollectOutlined/>
								<span>{Utility.language('流水信息', this.state.lang)}</span>
							</span>
                        }
                    >
                        <Menu.Item key="brokermoney">{Utility.language('公会流水', this.state.lang)}</Menu.Item>
                        <Menu.Item key="hostwater">{Utility.language('主播流水', this.state.lang)}</Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="sub7"
                        title={
                            <span>
                            <FundOutlined />
                            <span>{Utility.language('运营数据', this.state.lang)}</span>
                            </span>
                    }>
                    <Menu.Item key="taskdata">{Utility.language('任务数据', this.state.lang)}</Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
        );
    }

    _renderFooter = () => {
        return (<footer className="page-footer">©2024 Created by Pati</footer>);
    }

    render() {
        var uinfo = localStorage.getItem('uinfo');
        var broker_language = localStorage.getItem('broker_language');
        uinfo = JSON.parse(uinfo);
        const {collapsed} = this.state;

        return uinfo ?
            (<Layout className="page-frame">

                {
                    // eslint-disable-next-line
                    uinfo ? (broker_language && broker_language == 'ar' ? this._renderArSider() : this._renderSider()) : ''
                }
                <Layout>
                    <Head onCollapse={this.onCollapse} collapsed={this.state.collapsed}/>
                    <section className="page-frame-content">
                        {this._renderContent()}
                    </section>
                    <footer
                        className="page-footer"
                        style={{
                            left: collapsed ? 'calc(50% - 55px)' : 'calc(50% + 10px)',
                            bottom: collapsed ? '1px' : '10px'
                        }}
                    >©2024 Created by Pati
                    </footer>
                </Layout>
            </Layout>)
            :
            // <Layout className="page-frame">
            this._renderContent()
        // </Layout>
    }
}

//---------------- helps --------------
//移动端判断
function checkMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window && window.navigator && window.navigator.userAgent
    )
}
