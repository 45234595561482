import React, {Component} from 'react';
import {
    Table, Button, DatePicker, Card, message, Input, Space,
    Statistic
} from 'antd';
import Xhr from '../libs/Xhr';
import {Link} from "react-router-dom";
import Utility from "../libs/Utility";
import Highlighter from "react-highlight-words";
import {SearchOutlined} from '@ant-design/icons';
import "./scss/BrokerMoney.scss"



export default class BrokerMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            total: 0,
            currentPage: 1,
            pageSize: 10,
            selectedRowKeys: [],
            loading: false,
            startTime: "",
            endTime: "",
            todayTotalWater: 0,
            yesterdayTotalWater: 0,
            todayCashWater: 0,
            yesterdayCashWater: 0,
            todayOrderWater: 0,
            yesterdayOrderWater: 0,
            currentWeekWater: 0,
            lastWeekWater: 0,
            types: false,
            columns: [],
            searchText: '',
            searchedColumn: '',
            lang: 'zh_cn',
            isDisplayToday: false,
            tableScrollX: 1200,
        }

        this._load();

        this._columns = this.state.columns
    }

    componentDidMount() {
        this.userinfo();
    }

    userinfo = () => {
        var uinfo = localStorage.getItem('uinfo');
        var lang = localStorage.getItem('lang');
        this.setState({
            lang: lang ? lang : 'zh_cn',
        })
        uinfo = JSON.parse(uinfo);
        if (!uinfo) {
            this.props.history.push('/static/login');
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({
            searchText: ''
        });
    };

    handleTableChange = (pagination, size) => {
        this.setState({
            currentPage: pagination,
            pageSize: size
        }, () => {
            this._load();
        })
    }

    onSearch = () => {

        this.setState({
            currentPage: 1,
        }, () => {
            this._load();
        })

    }

    onOk(value) {
        // console.log('onOk: ', value);
    }

    onChange = (value, dateString) => {
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        })
    }

    tableChange = (pagination, filters, sorter, extra) => {
        //console.log('tableChange', pagination, filters, sorter, extra);
    }

    onSelectChange = selectedRowKeys => {
        //console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    start = () => {
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 500);
    };

    caclNum = (num1, num2) => {
        let dealer = ((num1 - num2) / num2).toFixed(1);
        // eslint-disable-next-line
        if (num2 == 0) dealer = num1;
        dealer = (dealer * 100).toFixed(1);
        let res = <span><span style={{color: 'gray'}}>0</span>%</span>;
        if (dealer >= 0) res = <span><span style={{color: 'red'}}>{'+' + dealer}</span>%</span>;
        if (dealer < 0) res = <span><span style={{color: 'green'}}>{dealer}</span>%</span>;
        return res;
    }

    caclRate = (num1, num2) => {
        let dealer = ((num1 - num2) / num2).toFixed(1);
        // eslint-disable-next-line
        if (num2 == 0) dealer = num1;
        dealer = (dealer * 100).toFixed(1);
        return dealer;
    }
    caclColor = (num1, num2) => {
        let dealer = ((num1 - num2) / num2).toFixed(1);
        // eslint-disable-next-line
        if (num2 == 0) dealer = num1;
        dealer = (dealer * 100).toFixed(1);
        return dealer >= 0 ? 'red' : 'green';
    }


    async _load() {

        const {currentPage, pageSize, startTime, endTime} = this.state;

        var bid = localStorage.getItem('bid');
        let res = await Xhr.get('brokerwater/getBrokerListBak?bid=' + bid + '&page=' + currentPage + '&limit=' + pageSize + '&start_time=' + startTime + '&end_time=' + endTime)
        if (res.success) {
            let columns = res.data.columns
            for (var i in columns) {
                columns[i]['defaultSortOrder'] = 'descend';
                // eslint-disable-next-line
                switch (columns[i]['dataIndex']) {
                    // case 'day':
                    //     columns[i]['sorter'] = (a, b) => a.day - b.day;
                    //     columns[i]['render'] = (text, record) => {
                    //         return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy/MM/dd') : '';
                    //     };
                    //     break;
                    case 'current_total':
                        columns[i]['sorter'] = (a, b) => a.current_total - b.current_total;
                        break;
                    case 'order_total':
                        columns[i]['sorter'] = (a, b) => a.order_total - b.order_total;
                        break;
                    case 'roommoney':
                        columns[i]['sorter'] = (a, b) => a.roommoney - b.roommoney;
                        break;
                    case 'reward_total':
                        columns[i]['sorter'] = (a, b) => a.reward_total - b.reward_total;
                        break;
                    case 'rid':
                        columns[i]['sorter'] = (a, b) => a.rid - b.rid;
                        break;
                    case 'anchor_num':
                        columns[i]['sorter'] = (a, b) => a.anchor_num - b.anchor_num;
                        break;
                    case 'anchor_has_num':
                        columns[i]['sorter'] = (a, b) => a.anchor_has_num - b.anchor_has_num;
                        break;
                    case 'single_water':
                        columns[i]['sorter'] = (a, b) => a.single_water - b.single_water;
                        break;
                    case 'wheat_time':
                        columns[i]['sorter'] = (a, b) => a.wheat_time - b.wheat_time;
                        columns[i]['render'] = function (wheat_time) {
                            return wheat_time ? Utility.secondsToDate(wheat_time) : '';
                        }
                        break;
                }

            }
            this.setState({
                data: res.data.data,
                //total: res.data.total,
                todayTotalWater: res.data.water_data.today_water_total,
                yesterdayTotalWater: res.data.water_data.yesterday_water_total,
                todayCashWater: res.data.water_data.today_cash_total,
                yesterdayCashWater: res.data.water_data.yesterday_cash_total,
                todayOrderWater: res.data.water_data.today_order_total,
                yesterdayOrderWater: res.data.water_data.yesterday_order_total,
                currentWeekWater: res.data.water_data.current_week_total,
                lastWeekWater: res.data.water_data.last_week_total,
                types: res.data.types,
                isDisplayToday: res.data.isDisplayToday,
                columns: columns
            });
        } else {
            message.error(res.msg)
        }
    }

    render() {

        const {RangePicker} = DatePicker;

        const {
            todayTotalWater, yesterdayTotalWater, currentWeekWater, lastWeekWater, tableScrollX
        } = this.state;


        return (
            <article className="page-broker-money">
                <section className="pbm-monitor">
                    <div className="pbm-monitor-header">{Utility.language('公会流水监控', this.state.lang)}</div>
                    <Space className="pbm-monitor-body">
                        <Card>
                            <Statistic
                                title={Utility.language('今日总流水', this.state.lang)}
                                value={this.state.todayTotalWater}
                                precision={0}
                            />
                            <Statistic
                                title={Utility.language('环比昨日', this.state.lang)}
                                value={this.caclRate(todayTotalWater, yesterdayTotalWater)}
                                precision={1}
                                valueStyle={{color: this.caclColor(todayTotalWater, yesterdayTotalWater)}}
                                suffix="%"
                            />
                        </Card>
                        <Card>
                            <Statistic
                                title={Utility.language('本周期总流水', this.state.lang)}
                                value={this.state.currentWeekWater}
                                precision={0}
                            />
                            <Statistic
                                title={Utility.language('环比上周', this.state.lang)}
                                value={this.caclRate(currentWeekWater, lastWeekWater)}
                                precision={1}
                                valueStyle={{color: this.caclColor(currentWeekWater, lastWeekWater)}}
                                suffix="%"
                            />
                        </Card>
                    </Space>
                </section>


                {/*<div*/}
                {/*    <span style={{*/}
                {/*        textAlign: 'center',*/}
                {/*        margin: "0 auto",*/}
                {/*        fontSize: 16*/}
                {/*    }}>{Utility.language('公会流水监控', this.state.lang)}</span>*/}
                {/*</div>*/}

                {/*<div className="site-card-wrapper">
                    <Row gutter={16}>

                        {!this.state.isDisplayToday ? <Col span={6}>
                            <Card title={Utility.language('今日总流水', this.state.lang)} bordered={false}>
                                {this.state.todayTotalWater}
                            </Card>
                        </Col> : ''}*/}
                {/*<Col span={6}>*/}
                {/*    <Card title="今日打赏流水" bordered={false}>*/}
                {/*        {this.state.todayCashWater}*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col span={6}>*/}
                {/*    <Card title="今日订单流水" bordered={false}>*/}
                {/*        {this.state.types ? this.state.todayOrderWater : '-'}*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col span={6}>
                            <Card title={Utility.language('本周期总流水', this.state.lang)} bordered={false}>
                                {this.state.currentWeekWater}
                            </Card>
                        </Col>
                    </Row>
                </div>*/}

                {/*<div className="site-card-wrapper">
                    <Row gutter={16}>

                        {!this.state.isDisplayToday ? <Col span={6}>
                            <Card title={Utility.language('环比昨日', this.state.lang)} bordered={false}>
                                {this.caclNum(this.state.todayTotalWater, this.state.yesterdayTotalWater)}
                            </Card>
                        </Col> : ''}*/}
                {/*<Col span={6}>*/}
                {/*    <Card title="环比昨日" bordered={false}>*/}
                {/*        {this.caclNum(this.state.todayCashWater, this.state.yesterdayCashWater)}*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col span={6}>*/}
                {/*    <Card title="环比昨日" bordered={false}>*/}
                {/*        {this.state.types ? this.caclNum(this.state.todayOrderWater, this.state.yesterdayOrderWater) : '-'}*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col span={6}>
                            <Card title={Utility.language('环比上周', this.state.lang)} bordered={false}>
                                {this.caclNum(this.state.currentWeekWater, this.state.lastWeekWater)}
                            </Card>
                        </Col>
                    </Row>
                </div>*/}


                <section className="pbm-filter">
                    <Space>
                        <div span={9}>
                            {Utility.language('日期', this.state.lang)}：
                            <RangePicker format="YYYY-MM-DD" onChange={this.onChange} onOk={this.onOk}/>
                        </div>

                        <Button type="primary"
                                onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
                        {this.state.types || true ?
                            <Link to="/static/orderwater">
                                <Button type="primary">
                                    {Utility.language('订单详情', this.state.lang)}
                                </Button>
                            </Link>
                            : ''}
                    </Space>
                </section>


                <Table
                    className="pbm-table"
                    scroll={{ x: tableScrollX }}
                    rowKey={record => record.id}
                    columns={this.state.columns}
                    dataSource={this.state.data}
                    bordered
                    pagination={false}
                    onChange={this.tableChange}
                />

            </article>
        );
    }
}
