import React, { Component} from 'react';
import {
	Table, Button, DatePicker, InputNumber, message, Input, Space
} from 'antd';
import Xhr from '../libs/Xhr';
import Utility from "../libs/Utility";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import './scss/HostWater.scss';

export default class ReceptionWater extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			currentPage: 1,
			pageSize: 10,
			searchText: '',
			searchedColumn: '',
			startTime: "",
			endTime: "",
			ruidValue: 0,
			loading: false,
			types: '',
			columns: [],
			reception_id: 0,
			lang: 'zh_cn',
			tableScrollX: 1200,
		}
		this._columns = this.state.columns

		this._columns = [
			{
				title:Utility.language('日期', this.state.lang),
				dataIndex:'date',
				key:'date',
				render: (text, record) => {
					let br = <br></br>;
					text = text.split('$');
					let result = null;
					for (let j = 0; j < text.length; j++) {
						// eslint-disable-next-line
						if (j == 0) {
							result = text[j];
						} else {
							result = <span>{result}{br}{text[j]}</span>;
						}
					}
					return <div>{result}</div>;
				},
			},
			{
				title: Utility.language('房间id', this.state.lang),
				dataIndex: 'rid',
				key: 'rid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.rid - b.rid,
				...this.getColumnSearchProps('rid'),
			}, {
				title: Utility.language('房间昵称', this.state.lang),
				dataIndex: 'rname',
				key: 'rname',
				...this.getColumnSearchProps('rname'),
			},
			{
				title:Utility.language('接待id', this.state.lang),
				dataIndex:'uid',
				key:'uid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.uid - b.uid,
				...this.getColumnSearchProps('uid'),
			},
			{
				title: Utility.language('接待昵称', this.state.lang),
				dataIndex: 'name',
				key: 'name',
				...this.getColumnSearchProps('name'),
			},
			{
				title: Utility.language('在档房间流水', this.state.lang),
				dataIndex: 'totalpay',
				key: 'totalpay',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.totalpay - b.totalpay,
			},
			{
				title: Utility.language('在坑流水', this.state.lang),
				dataIndex: 'income',
				key: 'income',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.income - b.income,
			},
		]

		this._load();
	}

	componentDidMount() {
        this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}


	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({
			searchText: ''
		});
	};

	handleTableChange = (pagination) => {
		// this.setState({
		//   currentPage: pagination,
		// }, () =>{
		// 	this._load();
		// })
	}

	onSearch = () => {
		this.setState({
			currentPage: 1,
		},() => {
			this._load();
		})
	}

	onChange = (value, dateString) => {
		this.setState({
			startTime: dateString[0],
			endTime: dateString[1],
		})
	}

	// onChange = (value, dateString) => {
	// 	this.setState({
	// 		startTime: dateString,
	// 	})
	// }

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}
	  
	tableChange = (pagination, filters, sorter, extra) => {
		if(extra.action !== 'paginate')
			return false;
		this.setState({
				currentPage: pagination.current,
				pageSize: pagination.pageSize,
			},()=>this._load()

		);
	}


	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
		  this.setState({
			loading: false,
		  });
		}, 500);
	};



	async _load() {

		let page = this.state.currentPage;
		let limit = this.state.pageSize;
		let ruid = this.state.ruidValue;
		let start_time = this.state.startTime;
		let end_time = this.state.endTime;
		var bid = localStorage.getItem('bid');
		var reception_id = this.state.reception_id;
		let res = await Xhr.get('reception/getReceptionWater?bid=' + bid + '&ruid=' + ruid + '&reception_id=' + reception_id + '&start_time=' + start_time + '&end_time=' + end_time + '&page=' + page + '&limit=' + limit)
		if (res.success) {
			this.state.columns = [
				{
					title:Utility.language('日期', this.state.lang),
					dataIndex:'date',
					key:'date',
					render: (text, record) => {
						let br = <br></br>;
						text = text.split('$');
						let result = null;
						for (let j = 0; j < text.length; j++) {
							// eslint-disable-next-line
							if (j == 0) {
								result = text[j];
							} else {
								result = <span>{result}{br}{text[j]}</span>;
							}
						}
						return <div>{result}</div>;
					},
				},
				{
					title: Utility.language('房间id', this.state.lang),
					dataIndex: 'rid',
					key: 'rid',
					defaultSortOrder: 'descend',
					sorter: (a, b) => a.rid - b.rid,
					...this.getColumnSearchProps('rid'),
				}, {
					title: Utility.language('房间昵称', this.state.lang),
					dataIndex: 'rname',
					key: 'rname',
					...this.getColumnSearchProps('rname'),
				},
				{
					title:Utility.language('接待id', this.state.lang),
					dataIndex:'uid',
					key:'uid',
					defaultSortOrder: 'descend',
					sorter: (a, b) => a.uid - b.uid,
					...this.getColumnSearchProps('uid'),
				},
				{
					title: Utility.language('接待昵称', this.state.lang),
					dataIndex: 'name',
					key: 'name',
					...this.getColumnSearchProps('name'),
				},
				{
					title: Utility.language('在档房间流水', this.state.lang),
					dataIndex: 'totalpay',
					key: 'totalpay',
					defaultSortOrder: 'descend',
					sorter: (a, b) => a.totalpay - b.totalpay,
				},
				{
					title: Utility.language('日接待时长', this.state.lang),
					dataIndex: 'totaltime',
					key: 'totaltime',
					defaultSortOrder: 'descend',
					render: (text, record) => {
						return text ? Utility.secondsToDate(text) : '';
					},
					sorter: (a, b) => a.totaltime - b.totaltime,
				},
				{
					title: Utility.language('在坑流水', this.state.lang),
					dataIndex: 'income',
					key: 'income',
					defaultSortOrder: 'descend',
					sorter: (a, b) => a.income - b.income,
				},
			];
			let col = [];
			let columns = res.data.column;
			for(var i in columns)
			{
				columns[i]['defaultSortOrder'] = 'descend';
				// eslint-disable-next-line
				switch (columns[i]['dataIndex']) {
					case 'order_money':
						columns[i]['sorter'] = (a, b) => a.order_money - b.order_money;
						break;
				}
				col.push(columns[i]);
			}
			this.setState({
				data: res.data.data,
				total: res.data.total,
				types: res.data.type,
				columns: [...this.state.columns, ...col],
			});
		}else{
			message.error(res.msg);
		}
	}

	render() {
		const paginationProps = {
		  onChange : (page) => this.handleTableChange(page),
		  	total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage
		}
		const { RangePicker } = DatePicker;
		const{ tableScrollX} = this.state;
		return (
			<article className="page-reception-water">
				<header className="prew-header">
					<Space>
						<RangePicker
							format="YYYY-MM-DD"
							onChange={this.onChange}
							onOk={this.onOk}
						/>
						{/*<DatePicker*/}
						{/*format="YYYY-MM-DD"*/}
						{/*onChange={this.onChange}*/}
						{/*onOk={this.onOk}*/}
						{/*/>*/}
						<InputNumber  name="reception_id" onChange={value=>this.getInputValue(value, 'reception_id')} placeholder={Utility.language('接待id', this.state.lang)} />
						<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
					</Space>
				</header>
						<Table
							className="prew-table"
							scroll={{ x: tableScrollX }}
							rowKey={record => record.id}
							columns={this.state.columns}
							dataSource={this.state.data}
							bordered
							pagination={paginationProps}
							onChange={this.tableChange}
						/>
			</article>
		);
	}
}