import React, {Component} from 'react';
import {
	Table, Button, InputNumber, PageHeader, message, Space, Select
} from 'antd';
import Xhr from "../../libs/Xhr";
import './RateData.scss';
import Utility from "../../libs/Utility";

export default class RateData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			currentPage: 1,
			pageSize: 10,
			selectedRowKeys: [],
			loading: false,
			uid: 0,
			statusArr:[],
			status: 0,
			tableScrollX: 1200,
		}

		this._columns = [
			{
				title: Utility.language('编号', this.state.lang),
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: Utility.language('成员id', this.state.lang),
				dataIndex: 'uid',
				key: 'uid',
			},
			{
				title: Utility.language('成员昵称', this.state.lang),
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: Utility.language('申请时间', this.state.lang),
				dataIndex: 'apply_time',
				key: 'apply_time',
			},
			{
				title: Utility.language('调整前比例', this.state.lang),
				dataIndex: 'now_rate',
				key: 'now_rate',
			},
			{
				title: Utility.language('调整后比例', this.state.lang),
				dataIndex: 'apply_rate',
				key: 'apply_rate',
			},
			{
				title: Utility.language('状态', this.state.lang),
				dataIndex: 'status',
				key: 'status',
			},
		]

		this._load();
	}

	componentDidMount() {
		this.userinfo();
	}

	userinfo = () => {
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
	}

	handleTableChange = (pagination, size) => {
		//console.log('page', pagination);
		this.setState({
			currentPage: pagination,
			pageSize: size
		}, () => {
			this._load();
		})
	}

	onSearch = () => {
		this.setState({
			currentPage: 1,
		}, () => {
			this._load();
		})
	}

	onOk(value) {
		//console.log('onOk: ', value);
	}

	onChange = (value, dateString) => {
		this.setState({
			startTime: dateString[0],
			endTime: dateString[1],
		})
	}

	tableChange = (pagination, filters, sorter, extra) => {
		//console.log('tableChange', pagination, filters, sorter, extra);
	}

	onSelectChange = selectedRowKeys => {
		this.setState({selectedRowKeys});
	};

	start = () => {
		this.setState({loading: true});
		setTimeout(() => {
			this.setState({
				selectedRowKeys: [],
				loading: false,
			});
		}, 500);
	};

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}

	selectStatus = (value) => {
		this.setState({
			status: value,
			currentPage: 1,
		}, () =>{
			this._load();
		})
	}

	async _load() {
		const {currentPage, pageSize, uid, status} = this.state;

		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('broker/getRateData?bid=' + bid + '&uid=' + uid + '&status=' + status + '&page=' + currentPage + '&limit=' + pageSize)
		if (res.success) {
			this.setState({
				data: res.data,
				total: res.data,
				statusArr: res.status
			});
		} else {
			message.error(res.msg)
		}
	}

	render() {
		const paginationProps = {
			onChange: (page, size) => this.handleTableChange(page, size),
			total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage
		}

		const { tableScrollX } = this.state;
		const {Option} = Select;
		return (
			<article className="page-hot-manage">
				<PageHeader
					className="site-page-header"
					onBack={() => window.history.back()}
					title={Utility.language('返回成员列表', this.state.lang)}
					subTitle=""
				/>
				<header className="phm-header">
					<Space>
						<InputNumber name="uid" onChange={value => this.getInputValue(value, 'uid')}
										  placeholder="uid"/>
						<Select
							showSearch
							placeholder={Utility.language('选择状态', this.state.lang)}
							optionFilterProp="children"
							onChange={this.selectStatus}
							style={{ width: 120 }}
						>
							<Option value="">All</Option>
							{Object.keys(this.state.statusArr).map((item, key) => {
								return (
									<Option key={key} value={item}>{this.state.statusArr[item]}</Option>
								)
							})}
						</Select>
						<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
					</Space>
				</header>
				<Table
					className="phm-table"
					scroll={{ x: tableScrollX }}
					rowKey={record => record.id}
					columns={this._columns}
					dataSource={this.state.data}
					bordered
					pagination={paginationProps}
					onChange={this.tableChange}
				/>
			</article>
		);
	}
}