var vi = {};
vi.l = {
    "今日总流水": "Tổng doanh thu hôm nay",
    "本周期总流水": "Tổng doanh thu tuần này",
    "环比昨日": "So với dữ liệu hôm qua",
    "环比上周": "So với dữ liệu tuần qua",
    "日期": "Ngày",
    "订单详情": "Chi tiết đơn đơn hàng",
    "账期": "Kỳ tài khoản",
    "成员ID": "Member ID",
    "昵称": "Nickname",
    "人气值奖励": "Thưởng độ phổ biến",
    "人气值审核": "Đánh giá mức độ phổ biến",
    "成员vip等级": "Cấp độ VIP thành viên",
    "结算类型": "Loại thanh toán",
    "打包结算": "Thanh toán",
    "普通结算": "Kết toán phổ thông",
    "加入时间": "Thời gian tham gia",
    "最后登录时间": "Lần đăng nhập cuối",
    "操作": "Thao tác",
    "确定通过该成员的入会申请？": "Bạn có muốn thành viên này thông qua?",
    "确定拒绝该成员的入会申请？": "Bạn có muốn từ chối thành viên này?",
    "入会审核通过": "Gia nhập thông qua",
    "入会审核拒绝": "Đơn đăng ký của bạn bị từ chối",
    "确定同意该成员的退会申请？": "Bạn có đồng ý cho thành viên này rút nhóm?",
    "同意退会": "Đồng ý rời khỏi",
    "确定拒绝该成员的退会申请？": "Bạn có muốn từ chối thành viên này?",
    "拒绝退会": "Từ chối rời khỏi",
    "确定从公会移除该成员？": "Bạn có muốn xóa thành viên này ra khỏi nhóm?",
    "移出公会": "Xóa khỏi nhóm",
    "加入待审核": "Đi đến phê duyệt",
    "已通过": "Đã thông qua",
    "已拒绝": "Đã từ chối",
    "退会待审核": "Thoát Agency chờ phê duyệt",
    "确认": "Ok",
    "取消": "Hủy",
    "所选个数存在问题": "Có sự cố với số đã chọn",
    "输入uid": "Nhập UID",
    "状态筛选": "Lọc",
    "全部状态": "Toàn bộ",
    "加入批量通过": "Đồng ý tất cả",
    "退会批量拒绝": "Từ chối tất cả",
    "主播ID": "ID thành viên",
    "主播昵称": "Nickname thành viên",
    "返回公会奖励": "Phần thưởng Agency",
    "同意": "Đồng ý",
    "时间": "Thời gian",
    "人气变更对象": "Thay đổi độ phổ biến",
    "房间": "Phòng",
    "人气变更详细": "Chi tiết thay đổi độ phổ biến",
    "生效时间": "Hạn thời gian",
    "人气值变化": "Độ phổ biến thay đổi",
    "时长": "Thời lượng",
    "操作人id": "ID người thao tác",
    "返回房间管理": "Quay lại quản lý phòng",
    "房间id": "ID phòng",
    "欢迎光临": "Chào mừng đến với",
    "请输入手机号码": "Điền SĐT",
    "请输入正确的图形验证码": "Nhập mã xác minh hình ảnh chính xác",
    "请填写正确的手机验证码": "Nhập mã xác minh SĐT",
    "正在登录中": "Đang đăng nhập",
    "你未被邀请开通公会": "Bạn chưa được mời mở agency",
    "手机号": "SĐT",
    "请输入右边的图形验证码!": "Nhập mã xác minh hình ảnh ở bên phải",
    "图形码": "Mã hình ảnh",
    "验证码": "Mã xác minh",
    "请输入你收到的验证码!": "Vui lòng nhập mã xác minh",
    "获取验证码": "Mã xác minh",
    "秒后重发": "giây sau gửi lại",
    "登录": "Đăng nhập",
    "订单流水": "Doanh thu đơn hàng",
    "订单成功数": "Số đơn hàng thành công",
    "订单失败数": "Số đơn hàng thất bại",
    "有单人数": "Số người có đơn",
    "返回公会流水": "Trả về doanh thu Agency",
    "公会流水监控": "Giám sát doanh thu Agency",
    "今日订单总流水": "Tổng doanh thu đặt hàng hôm nay\n",
    "本周期订单总流水": "Tổng doanh thu đặt hàng tuần này",
    "本周订单成功数": "Số đơn hàng thành công tuần này",
    "本周期订单失败数": "Số đơn hàng thất bại tuần này",
    "本周期有单人数": "Số người có đơn tuần này",
    "房间昵称": "Tên phòng",
    "接待id": "MC ID",
    "接待昵称": "Nickname MC",
    "在档房间流水": "Doanh thu phòng hiện tại",
    "在坑流水": "Doanh thu trên mic",
    "开始日期": "Ngày bắt đầu ",
    "结束日期": "Ngày kết thúc",
    "魅力值": "Độ thu hút",
    "缺少房间参数，请从上个页面重新进来": "Thiếu tham số phòng, vui lòng nhập lại từ trang trước",
    "返回房间流水": "Trở về doanh thu phòng",
    "房间魅力榜单": "BXH phòng thu hút\n",
    "房主昵称": "Nickname chủ phòng",
    "房主ID": "ID chủ phòng",
    "房间状态": "Trạng thái phòng",
    "房间业务": "Phòng hợp tác",
    "麦时（本周期）": "Giờ mic (tuần này)",
    "设置房间人气值": "Cài đặt độ phổ biến của phòng\n",
    "房间信息": "Thông tin phòng",
    "公会人气值": "Độ phổ biến Agency",
    "人气设置": "Cài đặt độ phổ biến",
    "使用时长": "Thời lượng sử dụng",
    "提交设置": "Cài đặt gửi",
    "数据格式错误": "Lỗi định dạng dữ liệu",
    "人气设置不能为空": "Cài đặt độ phổ biến không được trống",
    "时长不能为空": "Thời lượng không thể trống",
    "人气只能是大于100的正整数": "Độ phổ biến chỉ có thể lớn hơn 100",
    "时长只能是大于1的正整数": "Thời lượng chỉ có thể lớn hơn 1",
    "待创建": "Chờ tạo",
    "正常": "Bình thường",
    "关闭": "Đóng",
    "删除": "Xóa",
    "房间频道": "Loại phòng",
    "房间流水": "Doanh thu phòng",
    "麦时": "giờ mic",
    "房间流水详细": "Chi tiết doanh thu phòng",
    "时段": "Giai đoạn",
    "流水": "Doanh thu",
    "房间流水监控": "Giám sát doanh thu phòng",
    "公会类型": "Loại hình Agency",
    "结算金额": "Số tiền thanh toán",
    "查看详细": "Xem chi tiết",
    "说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准": "Lưu ý: Số tiền thanh toán này không bao gồm các khoản trả về khi live, tùy thuộc vào kết quả được công bố bởi nhân viên",
    "缺少日期参数，请从上个页面重新进来": "Thiếu tham số ngày, vui lòng nhập lại từ trang trước",
    "缺少跳转参数，请从上个页面重新进来": "Thiếu tham số nhảy, vui lòng nhập lại từ trang trước",
    "返回结算管理": "Quay lại quản lý thanh toán",
    "未发放": "Chưa chuyển",
    "已发放": "Đã chuyển",
    "Partying业务": "Hợp tác Partying ",
    "签约成员": "Thành viên hợp đồng",
    "房间管理": "Quản lý phòng",
    "流水信息": "Thông tin doanh thu",
    "公会流水": "Doanh thu Agency",
    "主播流水": "Doanh thu idol",
    "接待流水": "Doanh thu MC",
    "结算信息": "Thông tin thanh toán",
    "结算管理": "Quản lý thanh toán",
    "登录方式": "Phương pháp đăng nhập",
    "手机号登录": "Mã điện thoại di động",
    "账号登录": "Tên tài khoản",
    "请输入你的账号": "Xin hãy nhập số tài khoản.",
    "请输入你的密码": "Hãy nhập mật khẩu.",
    "密码": "mật khẩu",
    "手機號碼格式不合理": "Định dạng số điện thoại không hợp lệ",
    "驗證碼錯誤": "Sai mã xác minh",
    "驗證碼好像不對哦…": "Mã xác minh không chính xác",
    "区号": "Mã vùng",
    "韩语": "Tiếng Hàn",
    "印尼语": "Tiếng Indonesia",
    "泰语": "Tiếng Thái",
    "阿拉伯语": "Tiếng Ả Rập",
    "账号": "Tài khoản",
    "越南语": "Tiếng Việt",
    "日接待时长": "Ngày tiếp nhận",
    "当前任务等级": "Level hiện tại",
    "1级任务完成度": "Độ hoàn thành Lv.1",
    "2级任务完成度": "Độ hoàn thành Lv.2",
    "3级任务完成度": "Độ hoàn thành Lv.3",
    "4级任务完成度": "Độ hoàn thành Lv.4",
    "5级任务完成度": "Độ hoàn thành Lv.5",
    "接待任务": "Nhiệm Vụ MC",
    "主播任务": "Nhiệm vụ idol",
    "萌新任务": "Nhiệm vụ mới",
    "任务等级": "Level",
    "完成状态": "Trạng thái",
    "完成": "Done",
    "未完成": "Chưa xong",
    "周期": "Thời gian",
    "任务1": "Nhiệm vụ 1",
    "任务2": "Nhiệm vụ 2",
    "任务3": "Nhiệm vụ 3",
    "任务4": "Nhiệm vụ 4",
    "任务5": "Nhiệm vụ 5",
    "任务6": "Nhiệm vụ 6",
    "任务7": "Nhiệm vụ 7",
    "任务8": "Nhiệm vụ 8",
    "任务9": "Nhiệm vụ 9",
    "任务10": "Nhiệm vụ 10",
    "任务11": "Nhiệm vụ 11",
    "任务12": "Nhiệm vụ 12",
    "任务13": "Nhiệm vụ 13",
    "返回任务数据": "Trở về trang nhiệm vụ",
    "运营数据":"Dữ liệu vận hành",
    "任务数据":"Dữ liệu nhiệm vụ",
    "有效天":"Ngày hiệu quả",
    "数据是否聚合":"Dữ liệu tổng hợp",
    "是":"Có",
    "否":"Không",
    "直播房间流水":"Doanh thu phòng live",
    "房间流水汇总":"Tóm tắt dòng chảy phòng",
    "当商业厅内某用户给A主播赠送了100钻礼物": "Khi một người dùng trong sảnh hợp tác tặng cho idol A một món quà 100 KC",
    "1、公会长设定A主播的人气比为0%：": "1. Boss Agency đặt tỷ lệ phổ biến của idol A là 0%：",
    "则A主播立即收到0公会魅力值，Ta的公会长立即收到70公会魅力值；": "A nhận ngay 0 độ thu hút Agency, Boss Agency nhận 70 độ thu hút",
    "2、公会长设定A主播的人气比为90%：": "2. Boss Agency đặt tỷ lệ phổ biến của idol A là 90%：",
    "则A主播立即收到63公会魅力值，Ta的公会长立即收到7公会魅力值": "A nhận ngay 63 độ thu hút Agency, Boss Agency nhận 7 độ thu hút",
    "会长成功配置A主播的比例后，A主播将能收到【该比例部分】的人气魅力值": "Sau khi Boss Agency cài tỷ lệ cho idol A, A sẽ có thể nhận được độ thu hút [tỷ lệ phân bổ]",
    "当主播和平台审核通过后，分成比会实时生效": "Khi idol và nền tảng phê duyệt, tỷ lệ chia sẻ sẽ có hiệu lực trong thời gian thực",
    "设置公会成员与公会人气分配比例": "Đặt tỷ lệ phân bổ thành viên và mức độ phổ biến Agency",
    "成员uids集合": "Tập hợp UIDS thành viên",
    "支持手动在后面用英文逗号拼接uid": "Hỗ trợ thủ công nối uid với dấu phẩy phía sau",
    "人气分配比": "Tỷ lệ phân phối mức độ phổ biến",
    "数字必须在0~100之间的整数，没有小数": "Số phải là số nguyên từ 0 đến 100, không có số thập phân",
    "提交": "Gửi",
    "人气分配比例说明": "Giải thích tỷ lệ phân phối phổ biến",
    "批量设置人气分配": "Cài đặt phân phối độ phổ biến theo lô",
    "人气比例调整记录": "Lịch sử điều chỉnh tỷ lệ phổ biến",
    "编号": "Số",
    "成员id": "ID thành viên",
    "成员昵称": "Nickname thành viên",
    "申请时间": "Thời gian đăng ký",
    "调整前比例": "Tỷ lệ trước khi điều chỉnh",
    "调整后比例": "Tỷ lệ sau khi điều chỉnh",
    "状态": "Trạng thái",
    "返回成员列表": "Trở về danh sách thành viên",
    "成员uid": "uid thành viên",
    "选择状态": "Chọn trạng thái",
    "全部": "Toàn bộ",
    "查询": "Tìm",
    "人气分配比例变更": "Giải thích tỷ lệ thay đổi",
    "用户ID": "UID",
    "用户昵称": "Nickname",
    "公会ID": "ID Agency",
    "公会昵称": "Tên Agency",
    "个人与公会人气分配比例变化": "Thay đổi tỷ lệ phân bổ tỷ lệ phổ biến của cá nhân và Agency",
    "当前": "Hiện tại",
    "调整后": "Sau điều chỉnh",
    "人气分配比说明": "Giải thích tỷ lệ phân phối phổ biến",
    "例：": "Ví dụ：",
    " 1、公会长设定A主播的人气比为0%：": " 1. Boss Agency đặt tỷ lệ phổ biến của idol A là 0%：",
    "则A主播立即收到63公会魅力值，Ta的公会长立即收到7公会魅力值；": "A nhận ngay 63 độ thu hút Agency, Boss Agency nhận 7 độ thu hút；",
    "拒绝调整": "Từ chối điều chỉnh",
    "同意调整": "Đồng ý điều chỉnh",
    "缺少表更信息！": "Bảng thiếu thông tin thêm!",
    "正在加载中": "Đang tải",
    "确定拒绝这次人气分配比例变更": "Xác nhận hủy thanh đổi tỷ lệ lần này",
    "确定同意这次人气分配比例变更": "Xác nhận đồng ý thanh đổi tỷ lệ lần này",
    "是否签约":"Thành viên ký ước hay không",
    "成员类型":"Loại hình thành viên",
};

export default vi;