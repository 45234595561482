import React, { Component} from 'react';
import {
	Table, Button, DatePicker, Card, PageHeader, Space, Statistic
} from 'antd';
import Xhr from '../libs/Xhr';
import Utility from '../libs/Utility';
import "./scss/OrderWater.scss";

export default class OrderWater extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			currentPage: 1,
			pageSize: 10,
			selectedRowKeys: [],
			loading: false,
			startTime: "",
			endTime: "",
			todayOrderTotalWater: 0,
			yesterdayOrderTotalWater: 0,
			currentWeekOrderSuccessTotal: 0,
			lastWeekOrderSuccessTotal: 0,
			currentWeekOrderFailTotal: 0,
			lastWeekOrderFailTotal: 0,
			currentOrderWeekTotal: 0,
			lastOrderWeekTotal: 0,
			currentWeekOrderHasTotal: 0,
			lastWeekOrderHasTotal: 0,
			searchText: '',
			searchedColumn: '',
			lang: 'zh_cn',
			tableScrollX: 1200,
		}

		this._columns = [
			{
				title:Utility.language('日期', this.state.lang),
				dataIndex:'day',
				key:'day',
				render: (text, record) => {
					return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy-MM-dd') : '';
				},
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.day - b.day,
			},
			{
				title: Utility.language('订单流水', this.state.lang),
				dataIndex: 'order_total',
				key: 'order_total',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.order_total - b.order_total,
			}, {
				title: Utility.language('订单成功数', this.state.lang),
				dataIndex: 'success_num',
				key: 'success_num',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.success_num - b.success_num,
			}, {
				title: Utility.language('订单失败数', this.state.lang),
				dataIndex: 'fail_num',
				key: 'fail_num',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.fail_num - b.fail_num,
			},{
				title: Utility.language('有单人数', this.state.lang),
				dataIndex: 'has_num',
				key: 'has_num',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.has_num - b.has_num,
			}
		]

		this._load();
	}

	componentDidMount() {
        this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}


	handleTableChange = (pagination, size) => {
		//console.log('page', pagination);
		this.setState({
			currentPage: pagination,
			pageSize: size
		}, () =>{
			this._load();
		})
	}

	onSearch = () => {

		this.setState({
			currentPage: 1,
		}, () =>{
			this._load();
		})

	}

	onOk(value) {
		//('onOk: ', value);
	}

	onChange = (value, dateString) => {

		this.setState({
			startTime: dateString[0],
			endTime: dateString[1],
		})
	}

	tableChange = (pagination, filters, sorter, extra) => {
		//console.log('tableChange', pagination, filters, sorter, extra);
	}

	onSelectChange = selectedRowKeys => {
		//console.log('selectedRowKeys changed: ', selectedRowKeys);
		this.setState({ selectedRowKeys });
	};

	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({
				selectedRowKeys: [],
				loading: false,
			});
		}, 500);
	};

	caclNum = (num1, num2) => {
		let dealer = ((num1 - num2)/ num2).toFixed(1);
		// eslint-disable-next-line
		if(num2 == 0) dealer = num1;
		dealer = (dealer * 100).toFixed(1);
		let res = <span><span style={{color:'gray'}}>0</span>%</span>;
		if(dealer >= 0) res = <span><span style={{color:'red'}}>{'+' + dealer}</span>%</span>;
		if(dealer < 0) res = <span><span style={{color:'green'}}>{dealer}</span>%</span>;
		return res;
	}

	caclRate = (num1, num2) => {
		let dealer = ((num1 - num2) / num2).toFixed(1);
		// eslint-disable-next-line
		if (num2 == 0) dealer = num1;
		dealer = (dealer * 100).toFixed(1);
		return dealer;
	}
	caclColor = (num1, num2) => {
		let dealer = ((num1 - num2) / num2).toFixed(1);
		// eslint-disable-next-line
		if (num2 == 0) dealer = num1;
		dealer = (dealer * 100).toFixed(1);
		return dealer >= 0 ? 'red' : 'green';
	}


	async _load() {

		const { currentPage, pageSize,  startTime, endTime} = this.state;

		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('brokerwater/getOrderList?bid= ' + bid+ '&page=' + currentPage + '&limit=' + pageSize + '&start_time=' + startTime + '&end_time=' + endTime)
		if (res.success) {
			this.setState({
				data: res.data.data,
				total: res.data.total,
				todayOrderTotalWater: res.data.water_data.today_order_water_total,
				yesterdayOrderTotalWater: res.data.water_data.yesterday_order_water_total,
				currentWeekOrderSuccessTotal: res.data.water_data.current_week_order_success_total,
				lastWeekOrderSuccessTotal: res.data.water_data.last_week_order_success_total,
				currentWeekOrderFailTotal: res.data.water_data.current_week_order_fail_total,
				lastWeekOrderFailTotal: res.data.water_data.last_week_order_fail_total,
				currentOrderWeekTotal: res.data.water_data.current_order_week_total,
				lastOrderWeekTotal: res.data.water_data.last_order_week_total,
				currentWeekOrderHasTotal: res.data.water_data.current_week_order_has_total,
				lastWeekOrderHasTotal: res.data.water_data.last_week_order_has_total,
			});
		}
	}

	render() {
		const paginationProps = {
			onChange : (page, size) => this.handleTableChange(page, size),
			total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
		}

		const { RangePicker } = DatePicker;
		const { tableScrollX } = this.state;
		return (
			<article className="page-broker-money">
				<PageHeader
					className="site-page-header"
					onBack={() => window.history.back()}
					title={Utility.language('返回公会流水', this.state.lang)}
					subTitle=""
				/>
				<section className="pbm-monitor">
					<div className="pbm-monitor-header">{Utility.language('公会流水监控', this.state.lang)}</div>
					<Space className="pbm-monitor-body">
						<Card>
							<Statistic
								title={Utility.language('今日订单总流水', this.state.lang)}
								value={this.state.todayOrderTotalWater}
								precision={0}
							/>
							<Statistic
								title={Utility.language('环比昨日', this.state.lang)}
								value={this.caclRate(this.state.todayOrderTotalWater, this.state.yesterdayOrderTotalWater)}
								precision={1}
								valueStyle={{color: this.caclColor(this.state.todayOrderTotalWater, this.state.yesterdayOrderTotalWater)}}
								suffix="%"
							/>
						</Card>
						<Card>
							<Statistic
								title={Utility.language('本周期订单总流水', this.state.lang)}
								value={this.state.currentOrderWeekTotal}
								precision={0}
							/>
							<Statistic
								title={Utility.language('环比上周', this.state.lang)}
								value={this.caclRate(this.state.currentOrderWeekTotal, this.state.lastOrderWeekTotal)}
								precision={1}
								valueStyle={{color: this.caclColor(this.state.currentOrderWeekTotal, this.state.lastOrderWeekTotal)}}
								suffix="%"
							/>
						</Card>
						<Card>
							<Statistic
								title={Utility.language('本周订单成功数', this.state.lang)}
								value={this.state.currentWeekOrderSuccessTotal}
								precision={0}
							/>
							<Statistic
								title={Utility.language('环比上周', this.state.lang)}
								value={this.caclRate(this.state.currentWeekOrderSuccessTotal, this.state.lastWeekOrderSuccessTotal)}
								precision={1}
								valueStyle={{color: this.caclColor(this.state.currentWeekOrderSuccessTotal, this.state.lastWeekOrderSuccessTotal)}}
								suffix="%"
							/>
						</Card>
						<Card>
							<Statistic
								title={Utility.language('本周期订单失败数', this.state.lang)}
								value={this.state.currentOrderWeekTotal}
								precision={0}
							/>
							<Statistic
								title={Utility.language('环比上周', this.state.lang)}
								value={this.caclRate(this.state.currentWeekOrderFailTotal, this.state.lastWeekOrderFailTotal)}
								precision={1}
								valueStyle={{color: this.caclColor(this.state.currentWeekOrderFailTotal, this.state.lastWeekOrderFailTotal)}}
								suffix="%"
							/>
						</Card>
						<Card>
							<Statistic
								title={Utility.language('本周期有单人数', this.state.lang)}
								value={this.state.currentWeekOrderHasTotal}
								precision={0}
							/>
							<Statistic
								title={Utility.language('环比上周', this.state.lang)}
								value={this.caclRate(this.state.currentWeekOrderHasTotal, this.state.lastWeekOrderHasTotal)}
								precision={1}
								valueStyle={{color: this.caclColor(this.state.currentWeekOrderHasTotal, this.state.lastWeekOrderHasTotal)}}
								suffix="%"
							/>
						</Card>
					</Space>
				</section>

				<section className="pbm-filter">
					<Space>
						{Utility.language('日期', this.state.lang)}：<RangePicker
						format="YYYY-MM-DD"
						onChange={this.onChange}
						onOk={this.onOk}
					/>

						<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
					</Space>
				</section>


				<Table
					className="pow-table"
					scroll={{ x: tableScrollX }}
					rowKey={record => record.id}
					columns={this._columns}
					dataSource={this.state.data}
					bordered
					pagination={paginationProps}
					onChange={this.tableChange}
				/>
			</article>
		);
	}
}