import React, { Component} from 'react';
import {
	Table, Button, DatePicker, message, Space
} from 'antd';
import Xhr from '../libs/Xhr';
import {Link} from "react-router-dom";
import Utility from "../libs/Utility";
import './scss/HostWater.scss';

export default class SettleManage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			currentPage: 1,
			pageSize: 10,
			searchText: '',
			searchedColumn: '',
			startTime: "",
			endTime: "",
			uid: 0,
			selectedRowKeys: [],
			loading: false,
			startValue: '',
			endValue: '',
			endOpen: false,
			types: '',
			lang: 'zh_cn',
			tableScrollX: 1000,
		}


		this._columns = [
			{
				title:Utility.language('账期', this.state.lang),
				dataIndex:'date',
				key:'date',
			},
			{
				title:Utility.language('结算类型', this.state.lang),
				dataIndex:'settle_type',
				key:'settle_type',
			}, {
				title: Utility.language('公会类型', this.state.lang),
				dataIndex: 'type',
				key: 'type',
			},
			{
				title: Utility.language('结算金额', this.state.lang),
				dataIndex: 'total',
				key: 'total',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.total - b.total,
			},
			{
				title: Utility.language('状态', this.state.lang),
				dataIndex: 'status',
				key: 'status',
			},
			{
				title: Utility.language('操作', this.state.lang),
				key: 'action',
				render: (text, record) => (
					<span>
						<p><Link onClick={() => {this.setLocalstroge(record)}} to={{
							pathname: '/static/settlemanagedetail',
						}}>{Utility.language('查看详细', this.state.lang)}</Link></p>
					</span>
				),
			},
		]

		this._load();
	}

	componentDidMount() {
        this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}

	setLocalstroge= (r)=>{
		localStorage.setItem('settleDate', r.date);
		localStorage.setItem('isFrom', r.isFrom);
	}

	handleTableChange = (pagination) => {
		this.setState({
		  currentPage: pagination,
		}, () =>{
			this._load();
		})
	}


	onSearch = () => {
		this.setState({
			currentPage: 1,
		},() => {
			this._load();
		})
	}

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}
	  
	tableChange = (pagination, filters, sorter, extra) => {
		this.setState({
			currentPage: pagination.current,
			pageSize: pagination.pageSize,
		});
	}

	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
		  this.setState({
			selectedRowKeys: [],
			loading: false,
		  });
		}, 500);
	};





	async _load() {


		let uid = this.state.uid;
		let start_time = this.state.startValue;
		let end_time = this.state.endValue;
		if(start_time)
			start_time = start_time.format('YYYY-MM-DD');
		if(end_time)
			end_time = end_time.format('YYYY-MM-DD');
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('broker/settleManage?bid=' + bid + '&start_time=' + start_time + '&end_time=' + end_time + '&uid=' + uid)
		if (res.success) {
			this.setState({
				data: res.data.data,
				types: res.data.type,
			});
		}else{
			message.error(res.msg)
		}
	}



	disabledStartDate = (startValue) => {
		const { endValue } = this.state;
		if (endValue) {
			return (
				startValue.weekday() !== 3 || startValue.valueOf() >= endValue.valueOf()
			);
		} else {
			return startValue.weekday() !== 3;
		}
	};

	disabledEndDate = (endValue) => {
		const { startValue } = this.state;
		if (startValue) {
			return (
				endValue.weekday() !== 2 || (startValue.valueOf() + 24*6*3600*1000) >= endValue.valueOf()
			);
		} else {
			return endValue.weekday() !== 2;
		}
	};

	onChange = (field, value) => {
		this.setState({
			[field]: value
		});
	};

	onStartChange = (value, dateString) => {
		this.onChange("startValue", value);
	};

	onEndChange = (value, dateString) => {
		this.onChange("endValue", value);
	};

	handleStartOpenChange = (open) => {
		if (!open) {
			this.setState({ endOpen: true });
		}
	};

	handleEndOpenChange = (open) => {
		this.setState({ endOpen: open });
	};

	render() {
		const { startValue, endValue, endOpen, tableScrollX } = this.state;
		return (
			<article className="page-settle-manage">
				<header className="psm-header">
					<Space>
						<DatePicker
							disabledDate={this.disabledStartDate}
							value={startValue}
							placeholder="Start"
							onChange={this.onStartChange}
							onOpenChange={this.handleStartOpenChange}
						/>
						<DatePicker
							disabledDate={this.disabledEndDate}
							value={endValue}
							placeholder="End"
							onChange={this.onEndChange}
							open={endOpen}
							onOpenChange={this.handleEndOpenChange}
						/>
						<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
					</Space>
					<Space>
						<span style={{ textAlign: 'center', margin: "0 auto", fontSize: 16, top:"30px", color:"red"}}>{Utility.language('说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准', this.state.lang)}</span>
					</Space>
				</header>

						<Table
							className="psm-table"
							scroll={{ x: tableScrollX }}
							rowKey={record => record.key}
							columns={this._columns}
							dataSource={this.state.data}
							bordered
							onChange={this.tableChange}
						/>
			</article>
		);
	}
}