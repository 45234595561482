import React, { Component} from 'react';
import moment from 'moment';
import {
	Table, Button, DatePicker, InputNumber, PageHeader, message, Input, Space
} from 'antd';
import Xhr from '../libs/Xhr';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Utility from "../libs/Utility";
import './scss/HotCheck.scss';

export default class HotCheck extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			types: [],
			pack: [],
			currentPage: 1,
			pageSize: 50,
			searchText: '',
			searchedColumn: '',
			startTime: "",
			endTime: "",
			uid: 0,
			selectedRowKeys: [],
			loading: false,
			lang: 'zh_cn',
			tableScrollX: 1200,
		}


		this._columns = [
			{
				title:Utility.language('账期', this.state.lang),
				dataIndex:'date',
				key:'date',
			},
			{
				title:Utility.language('成员ID', this.state.lang),
				dataIndex:'uid',
				key:'uid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.uid - b.uid,
				...this.getColumnSearchProps('uid'),
			}, {
				title: Utility.language('昵称', this.state.lang),
				dataIndex: 'name',
				key: 'name',
				...this.getColumnSearchProps('name'),
			},
			// {
			// 	title:'所属频道',
			// 	dataIndex:'channel',
			// 	key:'channel',
			// 	render: (text, record) => {
			// 		return this._columnsStateRender(text, record)
			// 	},
			// },
			{
				title: Utility.language('人气值奖励', this.state.lang),
				dataIndex: 'money',
				key: 'money',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.uid - b.uid,
			},
		]

		this._load();
	}

	componentDidMount() {
        this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({
			searchText: ''
		});
	};

	_columnsStateRender(text, record) {
		if (this.state.pack[text]) {
			return this.state.pack[text];
		}
		return text;
	}

	handleTableChange = (pagination) => {
		// this.setState({
		//   currentPage: pagination,
		// }, () =>{
		// 	this._load();
		// })
	}


	onSearch = () => {
		this.setState({
			currentPage: 1,
		},() => {
			this._load();
		})
	}

	onChange = (value, dateString) => {
		this.setState({
			startTime: dateString[0],
			endTime: dateString[1],
		})
	}

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}
	  
	tableChange = (pagination, filters, sorter, extra) => {
		if(extra.action !== 'paginate')
			return false;
		this.setState({
				currentPage: pagination.current,
				pageSize: pagination.pageSize,
			},()=>this._load()

		);
	}

	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
		  this.setState({
			selectedRowKeys: [],
			loading: false,
		  });
		}, 500);
	};

	 range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	}


	disabledRangeTime = (_, type) => {
		if (type === 'start') {
			return {
				disabledHours: () => this.range(1, 60),
				disabledMinutes: () => this.range(1, 60),
				disabledSeconds: () => this.range(1, 60),
			};
		}
		return {
			disabledHours: () => this.range(0, 23),
			disabledMinutes: () => this.range(0, 59),
			disabledSeconds: () => this.range(0,59),
		};
	}




	async _load() {

		let page = this.state.currentPage;
		let limit = this.state.pageSize;
		let uid = this.state.uid;
		let start_time = this.state.startTime;
		let end_time = this.state.endTime;
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('broker/brokerReward?bid=' + bid + '&bstate=0&start_time=' + start_time + '&end_time=' + end_time + '&page=' + page + '&limit=' + limit + '&uid=' + uid)
		if (res.success) {
			this.setState({
				data: res.data.data,
				total: res.data.total,
				types: res.data.types,
				pack: res.data.pack,
			});
		}
	}


	async _agreeAll() {
		this.setState({ loading: true });
		var ids = this.state.selectedRowKeys;
		if (ids.length < 1) {
			message.error(Utility.language('所选个数存在问题', this.state.lang));
			this.start();
			return false;
		}

		var bid = localStorage.getItem('bid');
		var res = await Xhr.post('broker/useradmin', { ids: ids.join(','), ac: 'multagree', bid : bid });
		if (res.success) {
			message.success('success');
			this.start();
			this._load();
		} else {
			this.start();
			message.error(res.msg);
		}

	};

	render() {
		const paginationProps = {
		  onChange : (page) => this.handleTableChange(page),
		  	total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage
		}

		const { RangePicker } = DatePicker;
		const {selectedRowKeys, loading, tableScrollX} = this.state;

		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};

		const hasSelected = selectedRowKeys.length > 0;

		return (
			<article className="page-hot-check">
				<PageHeader
					className="site-page-header"
					onBack={() => window.history.back()}
					title={Utility.language('返回公会奖励', this.state.lang)}
					subTitle=""
				/>
				<header className="phc-header">
					<Space>
						<RangePicker
						onChange={this.onChange}
						onOk={this.onOk}
						disabledTime={this.disabledRangeTime}
						showTime={{
							hideDisabledOptions: true,
							defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
						}}
						format="YYYY-MM-DD HH:mm:ss"
					/>

					{Utility.language('成员ID', this.state.lang)}：<InputNumber  name="uid" onChange={value=>this.getInputValue(value, 'uid')} placeholder={Utility.language('成员ID', this.state.lang)}/>

					<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
					</Space>
					<Space>
					<Button type="primary" onClick={() => {this._agreeAll()}} disabled={!hasSelected} loading={loading}>
						{Utility.language('同意', this.state.lang)}
					</Button>
					<span>
						{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
					</span>
				</Space>
			</header>

				<Table
					className="phc-table"
					scroll={{ x: tableScrollX }}
					rowSelection={rowSelection}
					rowKey={record => record.id}
					columns={this._columns}
					dataSource={this.state.data}
					bordered
					pagination={paginationProps}
					onChange={this.tableChange}
				/>
			</article>
		);
	}
}