var ar = {};
ar.l = {
    "今日总流水": "الحساب اليومي",
    "本周期总流水": "دوري الحساب اليومي",
    "环比昨日": "قارن أمس",
    "环比上周": "قارن قبل أسبوع",
    "日期": "التاريخ",
    "订单详情": "تفاصيل الطلبات",
    "账期": "فترة الحساب",
    "成员ID": "ID",
    "昵称": "اسم الحساب",
    "人气值奖励": "مكافآت القيمة الشعبية",
    "查询": "تفتيش",
    "人气值审核": "تدقيق قيمة الشعبية",
    "成员昵称": "اسم أعضاء",
    "成员vip等级": "مستوىVIP الأفراد",
    "状态": "الحالة",
    "结算类型": "نوع الحساب",
    "打包结算": "الحساب المجمعة",
    "普通结算": "الحساب العادي",
    "加入时间": "تاريخ بالإضافة",
    "最后登录时间": "النشاط الأخير",
    "操作": "تشغيل",
    "确定通过该成员的入会申请？": "تأكيد هذا مستخدم  إضافة بالوكالتك؟",
    "确定拒绝该成员的入会申请？": "تأكيد رفض هذا المستخدم إضافة بوكالتك؟",
    "入会审核通过": "نجاح بالإضافة الوكالة",
    "入会审核拒绝": "رفض بالإضافة الوكالة",
    "确定同意该成员的退会申请？": "موافق هذا مستخدم يخرج من وكالتك؟ ",
    "同意退会": "موافق من خروج الوكالة",
    "确定拒绝该成员的退会申请？": "تأكيد رفض هذا المستخدم خروج من وكالتك؟",
    "拒绝退会": "رفض الانسحاب",
    "确定从公会移除该成员？": "تأكيد طرد من الوكالتك",
    "移出公会": "طرد من وكالتك ",
    "加入待审核": "إضافة الوكالة تدقيق الآن",
    "已通过": "موافق!",
    "已拒绝": "مرفوض",
    "退会待审核": "خروج من الوكالة تدقيق الآن",
    "确认": "تأكيد",
    "取消": "إلغاء",
    "所选个数存在问题": "مشكلة اختيار من  عدد",
    "输入uid": "تسجيلID",
    "状态筛选": "تصفية الحالة",
    "全部状态": "جميع الحالات",
    "加入批量通过": "الموافقة على تطبيقات المستخدمين المتعددة",
    "退会批量拒绝": "رفض التطبيقات من عدة مستخدمين",
    "主播ID": "مضيف ID",
    "主播昵称": "اسم للمضيف",
    "返回公会奖励": "مكافآت الرجع  إلى الوكيل",
    "同意": "موافق",
    "时间": "الوقت",
    "人气变更对象": "الهدف من تغيير شعبية",
    "房间": "غرفة",
    "人气变更详细": "مفصل التغيير في شعبية",
    "生效时间": "الوقت الفعال",
    "人气值变化": "التغيرات في شعبية",
    "时长": "المدّة",
    "操作人id": "ID المشغل",
    "返回房间管理": "الرجع إلى إدارة الغرفة",
    "房间id": "الغرفة ID",
    "欢迎光临": "مرحبا بكم",
    "请输入手机号码": "الرجاء إدخال رقم الجوال",
    "请输入正确的图形验证码": "الرجاء إدخال رمز التحقق من صحة الرسومات",
    "请填写正确的手机验证码": "يرجئ ملء رموز التحقيق الصحيحة من الرسالة",
    "正在登录中": "تم تسجيل الدخول",
    "你未被邀请开通公会": "أنت غير مدعو لفتح وكالة",
    "手机号": "رقم الجوال",
    "请输入右边的图形验证码!": "الرجاء إدخال رمز التحقق الرسومي على اليمين!",
    "图形码": "كود الرسم",
    "验证码": "رمز التحقق",
    "请输入你收到的验证码!": "الرجاء إدخال رمز التحقق الذي تلقيته!",
    "获取验证码": "الحصول على رمز التحقق",
    "秒后重发": " إعادة رسُل بعد 1 ثانية",
    "登录": "تسجيل الدخول",
    "订单流水": "حساب تدفق الأوامر",
    "订单成功数": "عدد حالات نجاح الطلب",
    "订单失败数": "عدد حالات فشل الأوامر",
    "有单人数": "عدد الأوامر التي تم إنشاؤها",
    "返回公会流水": "حساب رجوع  إلى الوكالة",
    "公会流水监控": "مراقبة حساب الوكيل",
    "今日订单总流水": "\nأوامر اليوم الحساب ",
    "本周期订单总流水": "أوامر منذ الزمن الحساب ",
    "本周订单成功数": "عدد الصفقات هذا الأسبوع",
    "本周期订单失败数": "عدد حالات فشل الطلب لهذه الدورة",
    "本周期有单人数": "عدد الطلبات المستلمة خلال الدورة",
    "房间昵称": "اسم مستعار للغرفة",
    "接待id": "استقبال ID",
    "接待昵称": "اسم الحساب الاستقبال",
    "在档房间流水": "غرفة الحساب",
    "在坑流水": "حساب علي صاحب الكرسي",
    "开始日期": "تاريخ البداية",
    "结束日期": "تاريخ نهاية النشر",
    "魅力值": "قيمة السحر",
    "缺少房间参数，请从上个页面重新进来": "معلمات الغرفة مفقودة، الرجاء العودة من الصفحة الأخيرة",
    "返回房间流水": "إرجاع عدد الغرف",
    "房间魅力榜单": "قائمة سحر الغرفة",
    "房主昵称": "اسم مستعار لصاحب الغرفة",
    "房主ID": "IDصاحب الغرفة",
    "房间状态": "حالة الغرفة",
    "房间业务": "غرفة الأعمال",
    "麦时（本周期）": "زمان علي الميك",
    "设置房间人气值": "الإعدادات قيمة شعبية الغرفة",
    "房间信息": "معلومات الغرفة ",
    "公会人气值": "قيمة شعبية الوكالة",
    "人气设置": "إعدادات شعبية",
    "使用时长": "وقت الاستخدام",
    "提交设置": "تسليم الإعدادات",
    "数据格式错误": "البيانات في تنسيق خاطئ",
    "人气设置不能为空": "لا يمكن أن يكون إعداد الشعبية فارغًا",
    "时长不能为空": "الوقت لا يمكن أن يكون فارغا",
    "人气只能是大于100的正整数": "يمكن أن تكون شعبية فقط أكبر من 100 عدد صحيح",
    "时长只能是大于1的正整数": "يمكن أن يكون الطول فقط عدد صحيح أكبر من 1",
    "待创建": "انتظار الإنشاء",
    "正常": "عادي",
    "关闭": "إغلاق",
    "删除": "حذف",
    "房间频道": "قناة غرفة",
    "房间流水": "غرفة الحساب اليومي ",
    "麦时": "زمان علي المايك",
    "房间流水详细": "حساب تدفق الغرفة مفصل",
    "时段": "الفترة الزمنية",
    "流水": "الحساب اليومي ",
    "房间流水监控": "مراقبة حساب تدفق الغرف",
    "公会类型": "نوع الوكالة",
    "结算金额": "مبلغ التسوية",
    "查看详细": "عرض الكل",
    "说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准": "ملاحظة: لا يشمل مبلغ التسوية هذا حسومات البث المباشر، والتي تخضع لنتائج إعلان العملية الرسمية",
    "缺少日期参数，请从上个页面重新进来": "معلمات التاريخ المفقودة، الرجاء العودة من الصفحة الأخيرة",
    "缺少跳转参数，请从上个页面重新进来": "المعلمة القفز مفقود، يرجى العودة من الصفحة الأخيرة",
    "返回结算管理": "إرجاع إدارة التسوية",
    "未发放": "لم ترسل",
    "已发放": "تم ارسالة",
    "Partying业务": " Partying الأعمال",
    "签约成员": "فرد موقّع",
    "房间管理": "إدارة الغرف",
    "流水信息": "معلومات الحساب",
    "公会流水": "حساب الوكالة",
    "主播流水": "حساب المضيف",
    "接待流水": "حساب الاستقبال",
    "结算信息": "معلومات الحساب",
    "结算管理": "إدارة الحساب",
    "登录方式": " طريقة الدخول",
    "手机号登录": "رقم الجوال تسجيل الدخول",
    "账号登录": "تسجيل الدخول",
    "请输入你的账号": "الرجاء إدخال رقم الحساب الخاص بك",
    "请输入你的密码": "الرجاء إدخال كلمة السر الخاصة بك",
    "密码": "باسوورد",
    "手機號碼格式不合理": "رقم الهاتف غير صحيح",
    "驗證碼錯誤": "خطأ في رمز التحقق",
    "驗證碼好像不對哦…": "خطأ في رمز التحقق",
    "区号": "رمز المنطقة",
    "韩语": "الكورية",
    "印尼语": "الإندونيسية",
    "泰语": "التايلاندية",
    "阿拉伯语": "العربية",
    "账号": "الحساب",
    "越南语": "الفيتنامية",
    "日接待时长": " طول اليوم",
    "当前任务等级": "مستوى المهمة الحالي",
    "1级任务完成度": "حال إنجاز المهمة 1",
    "2级任务完成度": "حال إنجاز المهمة 2",
    "3级任务完成度": "حال إنجاز المهمة 3",
    "4级任务完成度": "حال إنجاز المهمة 4",
    "5级任务完成度": "حال إنجاز المهمة 5",
    "接待任务": "مهمة الاستقبال",
    "主播任务": "مهمة المضيف",
    "萌新任务": "مهمة المبتدئ",
    "任务等级": "مستوى المهمة",
    "完成状态": "حال الإنجاز",
    "完成": "إنجاز",
    "未完成": "لم ينجز",
    "周期": "دورة",
    "任务1": "المهمة 1",
    "任务2": "المهمة 2",
    "任务3": "المهمة 3",
    "任务4": "المهمة 4",
    "任务5": "المهمة 5",
    "任务6": "المهمة 6",
    "任务7": "المهمة 7",
    "任务8": "المهمة 8",
    "任务9": "المهمة 9",
    "任务10": "المهمة 10",
    "任务11": "المهمة 11",
    "任务12": "المهمة 12",
    "任务13": "المهمة 13",
    "返回任务数据": "إرجاع بيانات المهمة",
    "任务数据": "بيانات المهمة",
    "运营数据": "بيانات التشغيل",
    "有效天":"أيام فعلية",
    "数据是否聚合":"إذا كانت البيانات المجمعة",
    "是":"نعم",
    "否":"لا",
    "直播房间流水":"تداول روم البث",
    "房间流水汇总":"ملخص الغرفة",
    "是否签约":"هل انت عضومتعاقد؟",
    "成员类型":"نوع العضو",
}
export default ar;