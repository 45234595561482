import React, {Component} from 'react';
import {Select, Avatar, Layout, Menu, Dropdown, Space, Button} from "antd";
import {
    LogoutOutlined,
    CaretDownOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    TranslationOutlined
} from "@ant-design/icons";
import Utility from '../libs/Utility';
import Xhr from '../libs/Xhr';
import './scss/Head.scss';
import {isPaUrl} from "../pages/Constant";

export default class Head extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeaderCover: false,
            showUInfo: false,
            uid: 0,
            uname: '',
            uicon: '',
            broker: 0,
            brokerlist: [],
            defaultBid: 0,
            bid: 0,
            defaultLang: 'en',
        }
        this._load();
    }

    componentDidMount() {
        this.userinfo();
    }

    async _load() {
        var res = await Xhr.get("broker/brokerlist");
        if (res && res.success) {
            this.setState({
                brokerlist: res.data,
                defaultBid: res.data[0].bid,
                bid: res.data[0].bid
            });
            localStorage.setItem('bid', res.data[0].bid);
            localStorage.setItem('broker_language', res.data[0].language);
        }
    }

    userinfo = () => {
        var uinfo = localStorage.getItem('uinfo');
        uinfo = JSON.parse(uinfo);
        // console.log('uinfo', uinfo);
        if (uinfo && uinfo['uid'] && uinfo['uid'] > 0) {
            this.setState({
                showUInfo: true,
                uid: uinfo['uid'],
                uname: uinfo['name'],
                uicon: uinfo['icon'],
                broker: uinfo['broker']
            });
        }
    }

    handleChange(value) {
        // console.log(value);
        this.setState({
            bid: value,
        });
        localStorage.setItem('bid', value);
        // this.props.history.push('/');
        // window.location.href='./';
    }

    handleLangChange = (value) => {
        console.log(value);
        this.setState({
            defaultLang: value,
        });
        localStorage.setItem('lang', value);
        window.location.reload();
    }

    async _logOut() {

        localStorage.removeItem('uid');
        localStorage.removeItem('token');
        localStorage.removeItem('vtoken');
        localStorage.removeItem('uinfo');
        localStorage.removeItem('cookieKey');
        localStorage.removeItem('fu');
        localStorage.removeItem('bid');
        this.setState({
            showUInfo: false,
            uid: 0,
            uname: '',
            uicon: '',
            broker: 0,
        })
        window.location.href = '/';

    }

    handleMenuClick = ({key}) => {
        if (key === "logout") this._logOut();
    }

    goToNewFamily = async() => {
        const { data } = await Xhr.get("api/auth/login/callback");
        window.location.href = 'https://'+window.location.hostname+'/new/'
    };

    render() {
        const {Option} = Select;
        const {Header} = Layout;
        const {showUInfo, uname, uicon, defaultLang} = this.state;
        const uicon2 = Utility.getImageUrl(uicon, 'head150');
        let defaultL = localStorage.getItem('lang');
        if (!defaultL) defaultL = defaultLang;

        return (
            <Header className="page-header" style={{backgroundColor: this.props.noFrame ? 'transparent' : '#fff'}}>
                {/*左侧展开收缩按钮*/}
                {this.props.noFrame ?
                    <div className="ph-trigger"/>
                    :
                    React.createElement(this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'ph-trigger',
                        onClick: this.props.onCollapse,
                    })}
                {/*右侧工具区*/}
                <Space>
                    {showUInfo && !this.props.noFrame && !isPaUrl ? (
                        <Button type="link" onClick={() => this.goToNewFamily()} className="ph-user-name">{Utility.language('新公会长后台', this.state.lang)}</Button>
                    ) : (
                        ""
                    )}
                    {
                        this.state.brokerlist.length > 0 && showUInfo && !this.props.noFrame &&
                        <Select optionFilterProp="children" defaultValue={this.state.defaultBid}
                                onChange={this.handleChange.bind(this)}
                        >
                            {this.state.brokerlist.map((item, index) => (
                                <Option key={index} value={item.bid}>{item.bname}</Option>
                            ))}
                        </Select>
                    }

                    <Dropdown trigger="click" placement="bottomRight" className="ph-lang"
                              overlay={
                                  <Menu selectedKeys={[]} onClick={(ev) => this.handleLangChange(ev.key)}>
                                      <Menu.Item key='zh_cn' value='zh_cn'>简体中文</Menu.Item>
                                      <Menu.Item key='en' value='en'>English</Menu.Item>
                                      <Menu.Item key='ar' value='ar'> العربية </Menu.Item>
                                      <Menu.Item key='ko' value='ko'>한국어</Menu.Item>
                                      <Menu.Item key='id' value='id'>Bahasa Indonesia</Menu.Item>
                                      <Menu.Item key='th' value='th'>ภาษาไทย</Menu.Item>
                                      <Menu.Item key='vi' value='vi'>Tiếng Việt</Menu.Item>
                                      <Menu.Item key='tu' value='tu'>Türkçe</Menu.Item>
                                  </Menu>
                              }
                    >
                        <TranslationOutlined
                            style={{
                                fontSize: '20px',
                                color: this.props.noFrame ? '#fff' : '#000'
                            }}
                        />
                    </Dropdown>

                    {
                        !this.props.noFrame && (showUInfo ?
                            <Dropdown trigger="click" placement="bottomRight"
                                      overlay={
                                          <Menu selectedKeys={[]} onClick={this.handleMenuClick}>
                                              <Menu.Item key="logout"><LogoutOutlined/>sign out</Menu.Item>
                                          </Menu>
                                      }
                            >
                            <span className="ph-user">
                                <Avatar size={32} src={uicon2}/>
                                <span className="ph-user-name">{uname}</span>
                                <CaretDownOutlined/>
                            </span>
                            </Dropdown>
                            :
                            <div className="ph-user">
                                <Avatar size={40} src={uicon2}/>
                                <span className="ph-user-name">{uname}</span>
                            </div>)
                    }
                </Space>
            </Header>
        );

    }
}
