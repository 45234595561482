var id = {};
id.l = {
    "今日总流水": "Pendapatan total hari ini",
    "本周期总流水": "Pendapatan total minggu ini",
    "环比昨日": "Rasio rantai dibantingkan kemarin",
    "环比上周": "Rasio rantai dibantingkan minggu lalu",
    "日期": "Tanggal",
    "订单详情": "Rincian Order",
    "账期": "Periode akun",
    "成员ID": "ID Anggota",
    "昵称": "Nickname",
    "人气值奖励": "Hadiah Popularitas",
    "人气值审核": "Tinjauan Popularitas",
    "成员vip等级": "Tingkat VIP Anggota",
    "结算类型": "Jenis penyelesaian",
    "打包结算": "Penyelesaian Bersama",
    "普通结算": "Penyelesaian Biasa",
    "加入时间": "Tanggal Gabung",
    "最后登录时间": "Aktif terakhir",
    "操作": "Operasi",
    "确定通过该成员的入会申请？": "Apakah yakin lulus permintaannya untuk keanggotaan?",
    "确定拒绝该成员的入会申请？": "Apakah tolak permintaannya untuk keanggotaan?",
    "入会审核通过": "Diizin Keanggotaan",
    "入会审核拒绝": "Ditolak Keanggotaan",
    "确定同意该成员的退会申请？": "Apakah izin anggota ini keluar agency?",
    "同意退会": "Setuju",
    "确定拒绝该成员的退会申请？": "Apakah tolak anggota ini keluar agency?",
    "拒绝退会": "Tolak",
    "确定从公会移除该成员？": "Apakah keluarkan anggota ini dari agency?",
    "移出公会": "Keluarkan",
    "加入待审核": "Keanggotaan sedang ditinjau",
    "已通过": "Disetujui",
    "已拒绝": "Ditolak",
    "退会待审核": "Keluar sedang ditinjau",
    "确认": "Konfirmasi",
    "取消": "Batalkan",
    "所选个数存在问题": "Kesalahan jumlah dipilih",
    "输入uid": "masukkan uid",
    "状态筛选": "Filter",
    "全部状态": "Semua",
    "加入批量通过": "Setujui Gabung Massal",
    "退会批量拒绝": "Tolak Massal",
    "主播ID": "ID host",
    "主播昵称": "Nickname host",
    "返回公会奖励": "Kembali ke hadiah agency",
    "同意": "Setuju",
    "时间": "Waktu",
    "人气变更对象": "Objek pengubahan popularitas",
    "房间": "Kamar",
    "人气变更详细": "Rincian pengubahan popularitas",
    "生效时间": "Valid sejak",
    "人气值变化": "Nilai Popularitas berubah",
    "时长": "Durasi",
    "操作人id": "id operator",
    "返回房间管理": "Kembali ke managemen kamar",
    "房间id": "id kamar",
    "欢迎光临": "Selamat datang di",
    "请输入手机号码": "Masukkan nomor ponsel",
    "请输入正确的图形验证码": "Masukkan kode gambar yg benar",
    "请填写正确的手机验证码": "Masukkan nomor verifikasi dengan benar",
    "正在登录中": "Sedang Login",
    "你未被邀请开通公会": "Anda belum diajak buka agency",
    "手机号": "Ponsel",
    "请输入右边的图形验证码!": "Masukkan kode gambar",
    "图形码": "Kode gambar",
    "验证码": "Kode verifikasi",
    "请输入你收到的验证码!": "Masukkan kode verifikasi!",
    "获取验证码": "Kode verifikasi",
    "秒后重发": " detik lagi kirim ulang",
    "登录": "Login",
    "订单流水": "Pendapatan Order",
    "订单成功数": "Jumlah order yang berhasil",
    "订单失败数": "Jumlah order yang gagal",
    "有单人数": "Jumlah orang yang dipesan",
    "返回公会流水": "Kembali ke pendapatan agency",
    "公会流水监控": "Pemantauan pendapatan agency",
    "今日订单总流水": "Pendapatan order hari ini",
    "本周期订单总流水": "Pendapatan order periode ini",
    "本周订单成功数": "Pendapatan order minggu ini",
    "本周期订单失败数": "Jumlah order yang gagal periode ini",
    "本周期有单人数": "Jumlah orang dipesan periode ini",
    "房间昵称": "Nickname Kamar",
    "接待id": "id MC",
    "接待昵称": "Nickname MC",
    "在档房间流水": "Pendapatan kamar waktu dia siaran",
    "在坑流水": "Pendapatan dalam kursi",
    "开始日期": "Tanggal Awal",
    "结束日期": "Tanggal Akhir",
    "魅力值": "nilai pesona",
    "缺少房间参数，请从上个页面重新进来": "Parameter kamar tidak ada, silakan masukkan kembali dari halaman sebelumnya",
    "返回房间流水": "Kembali ke pendapatan kamar",
    "房间魅力榜单": "Peringkat pesona kamar",
    "房主昵称": "Nickname Owner",
    "房主ID": "ID Owner",
    "房间状态": "Status Kamar",
    "房间业务": "Bisnis Kamar",
    "麦时（本周期）": "Durasi Mic(Siklus ini)",
    "设置房间人气值": "Tetapkan nilai popularitas kamar",
    "房间信息": "Informasi Kamar",
    "公会人气值": "Popularitas Kamar",
    "人气设置": "Penetapan Popularitas",
    "使用时长": "Durasi digunakan",
    "提交设置": "Ajukan penetapan",
    "数据格式错误": "Kesalahan format data",
    "人气设置不能为空": "Penetapan popularitas tidak bisa kosong",
    "时长不能为空": "Durasi tidak bisa kosong",
    "人气只能是大于100的正整数": "Nilai popularitas berupa bilangan bulat positif yang lebih besar dari 100",
    "时长只能是大于1的正整数": "Durasi berupa bilangan bulat positif yang lebih besar dari 1",
    "待创建": "Belum dibuat",
    "正常": "Normal",
    "关闭": "Tutup",
    "删除": "Hapus",
    "房间频道": "Jenis Kamar",
    "房间流水": "Pendapatan Kamar",
    "麦时": "Waktu Mic",
    "房间流水详细": "Rincian pendapatan kamar",
    "时段": "Periode",
    "流水": "Pendapatan",
    "房间流水监控": "Pemantauan pendapatan kamar",
    "公会类型": "Jenis Agency",
    "结算金额": "Jumlahnya",
    "查看详细": "Rincian",
    "说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准": "Penerangan：Jumlah penyelesaian ini tidak termasuk rabat siaran, yang tunduk pada hasil yang diumumkan oleh operasi resmi",
    "缺少日期参数，请从上个页面重新进来": "Tanggal tidak ada, silakan masukkan kembali dari halaman sebelumnya",
    "缺少跳转参数，请从上个页面重新进来": "Parameter lompatan tidak ada, harap masuk kembali dari halaman sebelumnya",
    "返回结算管理": "Kembali ke manajemen penyelesaian",
    "未发放": "Belum bayar",
    "已发放": "Telah bayar",
    "Partying业务": "Bisnis Partying",
    "签约成员": "Anggota Kontrak",
    "房间管理": "Manajemen kamar",
    "流水信息": "Informasi Pendapatan",
    "公会流水": "Pendapatan Agency",
    "主播流水": "Pendapatan Host",
    "接待流水": "Pendapatan MC",
    "结算信息": "Informasi Penyelesaian",
    "结算管理": "Manajemen Penyelesaian",
    "登录方式": "Metode daftar masuk",
    "手机号登录": "Daftar masuk nomor ponsel",
    "账号登录": "Login Akaun",
    "请输入你的账号": "Silakan masukkan nomor rekening Anda",
    "请输入你的密码": "Silakan masukkan sandi anda",
    "密码": "sandi",
    "手機號碼格式不合理": "Format Nomor HP salah",
    "驗證碼錯誤": "Kode Verifikasi salah",
    "驗證碼好像不對哦…": "Kode verifikasi salah…",
    "区号": "Kode Area",
    "韩语": "Bahasa Korea",
    "印尼语": "Bahasa Indonesia",
    "泰语": "Bahasa Thailand",
    "阿拉伯语": "Bahasa Arab",
    "账号": "Nomor Akun",
    "越南语": "Bahasa Vienam",
    "日接待时长": "Waktu penerimaan sehari",
    "当前任务等级": "Level tugas saat ini",
    "1级任务完成度": "Penyelesaian tugas Lv. 1",
    "2级任务完成度": "Penyelesaian tugas Lv. 2",
    "3级任务完成度": "Penyelesaian tugas Lv. 3",
    "4级任务完成度": "Penyelesaian tugas Lv. 4",
    "5级任务完成度": "Penyelesaian tugas Lv. 5",
    "接待任务": "Tugas MC",
    "主播任务": "Tugas host",
    "萌新任务": "Tugas pemula",
    "任务等级": "Level tugas",
    "完成状态": "Status selesai",
    "完成": "Selesai",
    "未完成": "Belum selesai",
    "周期": "Periode",
    "任务1": "Tugas 1",
    "任务2": "Tugas 2",
    "任务3": "Tugas 3",
    "任务4": "Tugas 4",
    "任务5": "Tugas 5",
    "任务6": "Tugas 6",
    "任务7": "Tugas 7",
    "任务8": "Tugas 8",
    "任务9": "Tugas 9",
    "任务10": "Tugas 10",
    "任务11": "Tugas 11",
    "任务12": "Tugas 12",
    "任务13": "Tugas 13",
    "返回任务数据": "Kembali ke data tugas",
    "任务数据": "Data Tugas",
    "运营数据": "Data Operasi",
    "有效天":"Hari Valid",
    "数据是否聚合":"Apakah data dikumpulkan",
    "是":"Ya",
    "否":"Tidak",
    "直播房间流水":"Pendapatan kamar",
    "房间流水汇总":"Samuzo di chambro-fluo",
    "当商业厅内某用户给A主播赠送了100钻礼物": "Ketika seorang user di kamar bisnis memberi host A hadiah 100 berlian",
    "1、公会长设定A主播的人气比为0%：": "1. Kepla agency serikat menetapkan rasio popularitas host A menjadi 0%：",
    "则A主播立即收到0公会魅力值，Ta的公会长立即收到70公会魅力值；": "Maka host A akan segera mendapatkan 0 pesona agency, Agency dia akan segera menerima 70 pesona agency",
    "2、公会长设定A主播的人气比为90%：": "2. Kepla agency serikat menetapkan rasio popularitas host A menjadi 90%：",
    "则A主播立即收到63公会魅力值，Ta的公会长立即收到7公会魅力值": "Maka host A akan segera mendapatkan 63 pesona agency, Agency dia akan segera menerima 7 pesona agency",
    "会长成功配置A主播的比例后，A主播将能收到【该比例部分】的人气魅力值": "Setelah kepala agency berhasil mengkonfigurasi rasio host A, host A akan dapat menerima nilai popularitas [Bagian proporsional ini]",
    "当主播和平台审核通过后，分成比会实时生效": "Setelah host lulus peninjauan platform, rasio akan berlaku secara real time",
    "设置公会成员与公会人气分配比例": "Atur rasio anggota agency dengan popularitas agency",
    "成员uids集合": "koleksi uid anggota",
    "支持手动在后面用英文逗号拼接uid": "Mendukung penyambungan manual uid dengan koma di belakang",
    "人气分配比": "Rasio distribusi popularitas",
    "数字必须在0~100之间的整数，没有小数": "Angka harus berupa bilangan bulat antara 0 dan 100, tanpa desimal",
    "提交": "Kirimkan",
    "人气分配比例说明": "Penjelasan Rasio Distribusi Popularitas",
    "批量设置人气分配": "Tetapkan distribusi popularitas dalam batch",
    "人气比例调整记录": "Catatan penyesuaian rasio popularitas",
    "编号": "Num",
    "成员id": "ID anggota",
    "成员昵称": "Nickname Anggota",
    "申请时间": "Waktu Permintaan",
    "调整前比例": "Rasio sebelum penyesuaian",
    "调整后比例": "Rasio setelah penyesuaian",
    "状态": "Status",
    "返回成员列表": "Kembali ke daftar anggota",
    "成员uid": "UID anggota",
    "选择状态": "Pilih status",
    "全部": "Semua",
    "查询": "Pencarian",
    "人气分配比例变更": "Peruahan Rasio Distribusi Popularitas",
    "用户ID": "ID User",
    "用户昵称": "Nickname user",
    "公会ID": "ID Agency",
    "公会昵称": "Nama Agency",
    "个人与公会人气分配比例变化": "Perubahan dalam rasio distribusi popularitas pribadi dan agency",
    "当前": "Saat ini",
    "调整后": "Setelah penyesuaian",
    "人气分配比说明": "Penjelasan Rasio Distribusi Popularitas",
    "例：": "Contoh:",
    " 1、公会长设定A主播的人气比为0%：": " 1. Kepla agency serikat menetapkan rasio popularitas host A menjadi 0%：",
    "则A主播立即收到63公会魅力值，Ta的公会长立即收到7公会魅力值；": "Maka host A akan segera mendapatkan 63 pesona agency, Agency dia akan segera menerima 7 pesona agency",
    "拒绝调整": "Tolak untuk menyesuaikan",
    "同意调整": "Setuju untuk menyesuaikan",
    "缺少表更信息！": "Tidak ada informasi di formulir",
    "正在加载中": "Memuat",
    "确定拒绝这次人气分配比例变更": "Yakin untuk menolak perubahan rasio distribusi popularitas ini",
    "确定同意这次人气分配比例变更": "Yakin untuk setuju perubahan rasio distribusi popularitas ini",
    "是否签约":"Apakah akan menandatangani anggota",
    "成员类型":"Jenis Anggota",
};

export default id;