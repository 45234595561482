import React from "react";
import {Form, Input, Modal} from 'antd';

const {TextArea} = Input;

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18}
};

export default ({visible, onCancel, onOk, title}) => {
    const [form] = Form.useForm();
    const handleOk = async () => {
        const result = await form.validateFields();
        onOk(result);
    }
    return <Modal visible={visible} onCancel={onCancel} onOk={handleOk} title={title}>
        <Form {...layout} form={form} preserve={false}>
            <Form.Item
                label="reason"
                name="reason"
                rules={[{required: true, message: "input reason"}]}
            >
                <TextArea
                    showCount
                    placeholder="input reason"
                    autoSize={{minRows: 4}}
                    maxLength={50}
                />
            </Form.Item>
        </Form>
    </Modal>


}