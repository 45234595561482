import React, { Component} from 'react';
import {
	Table, Button, DatePicker, InputNumber, message, Input, Space
} from 'antd';
import Xhr from '../libs/Xhr';
import Utility from "../libs/Utility";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import './scss/HostWater.scss';

export default class HostWater extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			currentPage: 1,
			pageSize: 10,
			searchText: '',
			searchedColumn: '',
			startTime: "",
			endTime: "",
			ruidValue: 0,
			loading: false,
			columns: [],
			lang: 'zh_cn',
			tableScrollX: 1100,
		}
		this._load();

		this._columns = this.state.columns
	}

	componentDidMount() {
        this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({
			searchText: ''
		});
	};


	handleTableChange = (pagination) => {
		// this.setState({
		//   currentPage: pagination,
		// }, () =>{
		// 	this._load();
		// })
	}

	onSearch = () => {
		this.setState({
			currentPage: 1,
		},() => {
			this._load();
		})
	}

	onChange = (value, dateString) => {
		this.setState({
			startTime: dateString[0],
			endTime: dateString[1],
		})
	}

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}
	  
	tableChange = (pagination, filters, sorter, extra) => {
		if(extra.action !== 'paginate')
			return false;
		this.setState({
				currentPage: pagination.current,
				pageSize: pagination.pageSize,
			},()=>this._load()
		);
	}


	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
		  this.setState({
			loading: false,
		  });
		}, 500);
	};



	async _load() {

		let page = this.state.currentPage;
		let limit = this.state.pageSize;
		let ruid = this.state.ruidValue;
		let start_time = this.state.startTime;
		let end_time = this.state.endTime;
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('host/getHostWater?bid=' + bid + '&ruid=' + ruid + '&start_time=' + start_time + '&end_time=' + end_time + '&page=' + page + '&limit=' + limit)
		if (res.success) {
			this.state.columns = [
				{
					title:Utility.language('日期', this.state.lang),
					dataIndex:'day',
					key:'day',
					// render: (text, record) => {
					// 	return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy-MM-dd') : '';
					// },
					// defaultSortOrder: 'descend',
					// sorter: (a, b) => a.day - b.day,
				},
				{
					title: Utility.language('主播ID', this.state.lang),
					dataIndex: 'uid',
					key: 'uid',
					defaultSortOrder: 'descend',
					sorter: (a, b) => a.uid - b.uid,
				}, {
					title: Utility.language('主播昵称', this.state.lang),
					dataIndex: 'name',
					key: 'name',
					...this.getColumnSearchProps('name'),
				},
				{
					title:Utility.language('最后登录时间', this.state.lang),
					dataIndex:'last_login',
					key:'last_login',
					render: (text, record) => {
						return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy-MM-dd HH:mm:ss') : '';
					},
					defaultSortOrder: 'descend',
					sorter: (a, b) => a.last_login - b.last_login,
				}
			]
			let col = [];
			let columns = res.data.column
			for(var i in columns)
			{
				columns[i]['defaultSortOrder'] = 'descend';
				// eslint-disable-next-line
				switch (columns[i]['dataIndex']) {
					case 'current_total':
						columns[i]['sorter'] = (a, b) => a.current_total - b.current_total;
						break;
					case 'order_total':
						columns[i]['sorter'] = (a, b) => a.order_total - b.order_total;
						break;
					case 'roommoney':
						columns[i]['sorter'] = (a, b) => a.roommoney - b.roommoney;
						break;
					case 'reward_total':
						columns[i]['sorter'] = (a, b) => a.reward_total - b.reward_total;
						break;
					case 'success_order':
						columns[i]['sorter'] = (a, b) => a.success_order - b.success_order;
						break;
					case 'fail_order':
						columns[i]['sorter'] = (a, b) => a.fail_order - b.fail_order;
						break;
					case 'reward_water':
						columns[i]['sorter'] = (a, b) => a.reward_water - b.reward_water;
						break;
					case 'order_water':
						columns[i]['sorter'] = (a, b) => a.order_water - b.order_water;
						break;
					case 'single_water':
						columns[i]['sorter'] = (a, b) => a.single_water - b.single_water;
						break;
					case 'wheat_time':
						columns[i]['sorter'] = (a, b) => a.wheat_time - b.wheat_time;
						columns[i]['render'] = function (wheat_time) {
							return wheat_time ? Utility.secondsToDate(wheat_time) : '';
						}
						break;
					case 'has_num':
						columns[i]['sorter'] = (a, b) => a.has_num - b.has_num;
						break;
					case 'friends_num':
						columns[i]['sorter'] = (a, b) => a.friends_num - b.friends_num;
						break;
				}
				col.push(columns[i]);
			}
			this.setState({
				data: res.data.data,
				total: res.data.total,
				columns: [...this.state.columns, ...col],
			});
		}else{
			message.error(res.msg);
		}
	}

	render() {
		const paginationProps = {
		  onChange : (page) => this.handleTableChange(page),
		  	total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage
		}

		const { RangePicker } = DatePicker;
		const { tableScrollX } = this.state;
		return (
			<article className="page-host-water">
				<header className="phw-header">
					<Space>
							<RangePicker
							format="YYYY-MM-DD"
							onChange={this.onChange}
							onOk={this.onOk}
						/>

						<InputNumber  name="ruidValue" onChange={value=>this.getInputValue(value, 'ruidValue')} placeholder={Utility.language('主播ID', this.state.lang)} />
						<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
					</Space>
				</header>
					<Table
						className="phw-table"
						scroll={{ x: tableScrollX }}
						rowKey={record => record.id}
						columns={this.state.columns}
						dataSource={this.state.data}
						bordered
						pagination={paginationProps}
						onChange={this.tableChange}
					/>
			</article>
		);
	}
}