import React, { Component} from 'react';
import {
    Table, PageHeader, message, Select, Space,
} from 'antd';
import Xhr from '../libs/Xhr';
import Utility from "../libs/Utility";
import './scss/HostWater.scss';

export default class SettleManageDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedRowKeys: [],
            loading: false,
            startTime: "",
            endTime: "",
            status: '',
            currentPage: 1,
            pageSize: 10,
            columns: [],
            flag: false,
            lang: 'zh_cn',
            tableScrollX: 1000,
        }

        this._load();
        this._columns = this.state.columns
    }

    componentDidMount() {
        this.userinfo();
	}

    userinfo = () => {
        var uinfo = localStorage.getItem('uinfo');
        var lang = localStorage.getItem('lang');
        this.setState({
            lang: lang ? lang : 'zh_cn',
        })
        uinfo = JSON.parse(uinfo);
        if (!uinfo) {
            this.props.history.push('/static/login');
        }
    }

    onSearch = () => {

        this.setState({

        }, () =>{
            this._load();
        })

    }

    onOk(value) {
        //('onOk: ', value);
    }

    onChange = (value, dateString) => {
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        })
    }

    tableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            currentPage: pagination.current,
            pageSize: pagination.pageSize,
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    selectChange = (value) => {
        // console.log(`selected ${value}`);
        this.setState({
            status: value,
            currentPage:1,
        }, () =>{
            this._load();
        })
    }

    start = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 500);
    };


    handleTableChange = (pagination) => {
        this.setState({
            currentPage: pagination,
        }, () =>{
            this._load();
        })
    }

    SetTitle = (value) => {
        return <h1 style={{textAlign:"center"}}>{value}</h1>;
    }



    async _load() {

        let page = this.state.currentPage;
        let limit = this.state.pageSize;
        let time = localStorage.getItem('settleDate');
        let is_from = localStorage.getItem('isFrom');
        if(!time)
        {
            message.error(Utility.language('缺少日期参数，请从上个页面重新进来', this.state.lang));
            return false;
        }

        if(!is_from)
        {
            message.error(Utility.language('缺少跳转参数，请从上个页面重新进来', this.state.lang));
            return false;
        }

        time = time.split('-');
        var startTime = time[0];
        var endTime = time[1];
        var bid = localStorage.getItem('bid');
        var status = this.state.status;
        let res = await Xhr.get('broker/settleManageDetail?bid=' + bid + '&status='+ status + '&start_time=' + startTime + '&end_time=' + endTime + '&is_from=' + is_from + '&page=' + page + '&limit=' + limit)
        if (res.success) {
            let columns = res.data.column
            for(var i in columns)
            {
                columns[i]['defaultSortOrder'] = 'descend';
                // eslint-disable-next-line
                switch (columns[i]['dataIndex']) {
                    case 'date':
                        columns[i]['sorter'] = (a, b) => a.date - b.date;
                        columns[i]['render']= (text, record) => {
                            return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy/MM/dd') : '';
                        };
                        break;
                    case 'total':
                        columns[i]['sorter'] = (a, b) => a.total - b.total;
                        break;
                    case 'rid':
                        columns[i]['sorter'] = (a, b) => a.rid - b.rid;
                        break;
                    case 'water':
                        columns[i]['sorter'] = (a, b) => a.water - b.water;
                        break;
                    case 'per':
                        columns[i]['sorter'] = (a, b) => a.per - b.per;
                        break;
                }

            }
            this.setState({
                data: res.data.data,
                total: res.data.count,
                columns: columns,
                flag: res.data.flag,
            });
        }else{
            message.error(res.msg)
        }
    }

    render() {
        const paginationProps = {
            onChange : (page) => this.handleTableChange(page),
            total: this.state.total,
            showSizeChanger: true,
            defaultCurrent: this.state.currentPage,
            current: this.state.currentPage
        }

        const { Option } = Select;
        const{ tableScrollX} = this.state;
        return (
            <article className="page-settle-manage-detail">
                <header className="psmd-header">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => window.history.back()}
                        title={Utility.language('返回结算管理', this.state.lang)}
                        subTitle=""
                    />
                    <Space>
                        {this.state.flag ? '' :
                            <Select
                                style={{ width:100}}
                                placeholder="select"
                                optionFilterProp="children"
                                onChange={this.selectChange}
                            >
                            <Option value="">all</Option>
                            <Option value="0">{Utility.language('未发放', this.state.lang)}</Option>
                            <Option value="1">{Utility.language('已发放', this.state.lang)}</Option>
                        </Select>
                    }
                    </Space>
                </header>
                    <Table
                        className="psmd-table"
                        scroll={{ x: tableScrollX }}
                        rowKey={record => record.id}
                        columns={this.state.columns}
                        dataSource={this.state.data}
                        bordered
                        pagination={paginationProps}
                        onChange={this.tableChange}
                    />
            </article>
        );
    }
}