import React, { Component } from 'react';
import { Button } from 'antd';

export default class Error extends Component {
  render() {
	return (
	  <div className="App">
		<Button type="primary">Error</Button>
	  </div>
	);
  }
}