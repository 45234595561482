import React, { Component} from 'react';
import {
	Table, Button, DatePicker, InputNumber, message, Input, Space
} from 'antd';
import Xhr from '../libs/Xhr';
import {Link} from "react-router-dom";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Utility from "../libs/Utility";
import './scss/RoomWater.scss';

export default class BrokerReward extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			currentPage: 1,
			pageSize: 10,
			searchText: '',
			searchedColumn: '',
			uid: 0,
			selectedRowKeys: [],
			loading: false,
			startValue: 0,
			endValue: 0,
			endOpen: false,
			lang: 'zh_cn',
			tableScrollX: 1200,
		}


		this._columns = [
			{
				title:'账期',
				dataIndex:'date',
				key:'date',
			},
			{
				title:'成员ID',
				dataIndex:'uid',
				key:'uid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.uid - b.uid,
				...this.getColumnSearchProps('uid'),
			}, {
				title: '昵称',
				dataIndex: 'name',
				key: 'name',
				...this.getColumnSearchProps('name'),
			},
			{
				title: '人气值奖励',
				dataIndex: 'money',
				key: 'money',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.money - b.money,
			},
		]

		this._load();
	}

	componentDidMount() {
        this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}

	handleTableChange = (pagination) => {
		// this.setState({
		//   currentPage: pagination,
		// }, () =>{
		// 	this._load();
		// })
	}



	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({
			searchText: ''
		});
	};


	onSearch = () => {
		this.setState({
			currentPage: 1,
		},() => {
			this._load();
		})
	}

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}
	  
	tableChange = (pagination, filters, sorter, extra) => {
		if(extra.action !== 'paginate')
			return false;
		this.setState({
				currentPage: pagination.current,
				pageSize: pagination.pageSize,
			},()=>this._load()

		);
	}

	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
		  this.setState({
			selectedRowKeys: [],
			loading: false,
		  });
		}, 500);
	};

	 range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	}

	disabledRangeTime = (_, type) => {
		if (type === 'start') {
			return {
				disabledHours: () => this.range(1, 60),
				disabledMinutes: () => this.range(1, 60),
				disabledSeconds: () => this.range(1, 60),
			};
		}
		return {
			disabledHours: () => this.range(0, 23),
			disabledMinutes: () => this.range(0, 59),
			disabledSeconds: () => this.range(0,59),
		};
	}




	async _load() {

		let page = this.state.currentPage;
		let limit = this.state.pageSize;
		let uid = this.state.uid;
		let start_time = this.state.startValue;
		let end_time = this.state.endValue;
		if(start_time)
			start_time = start_time.format('YYYY-MM-DD');
		if(end_time)
			end_time = end_time.format('YYYY-MM-DD');
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('broker/brokerReward?bid=' + bid + '&bstate=1&start_time=' + start_time + '&end_time=' + end_time + '&page=' + page + '&limit=' + limit + '&uid=' + uid)
		if (res.success) {
			this.setState({
				data: res.data.data,
				total: res.data.total,
			});
		}else{
			message.error(res.msg)
		}
	}



	disabledStartDate = (startValue) => {
		const { endValue } = this.state;
		if (endValue) {
			return (
				startValue.weekday() !== 3 || startValue.valueOf() >= endValue.valueOf()
			);
		} else {
			return startValue.weekday() !== 3;
		}
	};

	disabledEndDate = (endValue) => {
		const { startValue } = this.state;
		if (startValue) {
			return (
				endValue.weekday() !== 3 || (startValue.valueOf() + 24*7*3600*1000) >= endValue.valueOf()
			);
		} else {
			return endValue.weekday() !== 3;
		}
	};

	onChange = (field, value) => {
		this.setState({
			[field]: value
		});
	};

	onStartChange = (value) => {
		this.onChange("startValue", value);
	};

	onEndChange = (value) => {
		this.onChange("endValue", value);
	};

	handleStartOpenChange = (open) => {
		if (!open) {
			this.setState({ endOpen: true });
		}
	};

	handleEndOpenChange = (open) => {
		this.setState({ endOpen: open });
	};


	render() {
		const paginationProps = {
		  onChange : (page) => this.handleTableChange(page),
		  	total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage
		}

		const { startValue, endValue, endOpen, tableScrollX } = this.state;


		return (
			<article className="page-broker-reward">
				<header className="pbr-header">
					<Space>
						<DatePicker
						disabledDate={this.disabledStartDate}
						value={startValue}
						placeholder="Start"
						onChange={this.onStartChange}
						onOpenChange={this.handleStartOpenChange}
					/>
					<DatePicker
						disabledDate={this.disabledEndDate}
						value={endValue}
						placeholder="End"
						onChange={this.onEndChange}
						open={endOpen}
						onOpenChange={this.handleEndOpenChange}
					/>

					<InputNumber  name="uid" onChange={value=>this.getInputValue(value, 'uid')} placeholder={Utility.language('成员ID', this.state.lang)} />

					<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>

					<Link to="/static/hotcheck">
						<Button type="primary">
							{Utility.language('人气值审核', this.state.lang)}
						</Button>
					</Link>
					</Space>
				</header>

				<Table
					className="pbr-table"
					scroll={{ x: tableScrollX }}
					rowKey={record => record.id}
					columns={this._columns}
					dataSource={this.state.data}
					bordered
					pagination={paginationProps}
					onChange={this.tableChange}
				/>
			</article>
		);
	}
}