import React, { Component } from 'react';

export default class Index extends Component {

	componentDidMount() {
        this.userinfo();
	}
	
	userinfo = () => {
        var uinfo = localStorage.getItem('uinfo');
		uinfo = JSON.parse(uinfo);
        if (!uinfo) {
			this.props.history.push('/static/login');
        }
    }

	render() {
		return (
			<div style={{ padding: "0 24px 24px", marginTop: 200, backgroundColor: '#fff', textAlign: 'center' }}>

                <span style={{ textAlign: 'center', margin: "0 auto", fontSize: 40, color: '#1890FF' }}>Welcome</span>
			</div>
		);
	}
}