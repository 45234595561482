import React, {Component} from 'react';
import {
	Table, PageHeader, message, Space,
} from 'antd';
import Xhr from '../libs/Xhr';
import './scss/TaskDataDetail.scss';
import Utility from "../libs/Utility";

export default class TaskDataDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			selectedRowKeys: [],
			loading: false,
			startTime: "",
			endTime: "",
			status: '',
			currentPage: 1,
			pageSize: 10,
			flag: false,
			tableScrollX: 3000,
			rid:0,
			rname:'',
			user_id:0,
			name:'',
			time:''
		}

		this._columns = [
			{
				title: Utility.language('任务等级', this.state.lang),
				dataIndex: 'level',
				key: 'level',
			},
			{
				title: Utility.language('完成状态', this.state.lang),
				dataIndex: 'state',
				key: 'state',
			},
			{
				title: Utility.language('任务1', this.state.lang),
				dataIndex: 'task1',
				key: 'task1',
			},
			{
				title: Utility.language('任务2', this.state.lang),
				dataIndex: 'task2',
				key: 'task2',
			},
			{
				title: Utility.language('任务3', this.state.lang),
				dataIndex: 'task3',
				key: 'task3',
			},
			{
				title: Utility.language('任务4', this.state.lang),
				dataIndex: 'task4',
				key: 'task4',
			},
			{
				title: Utility.language('任务5', this.state.lang),
				dataIndex: 'task5',
				key: 'task5',
			},
			{
				title:Utility.language('任务6', this.state.lang),
				dataIndex: 'task6',
				key: 'task6',
			},
			{
				title: Utility.language('任务7', this.state.lang),
				dataIndex: 'task7',
				key: 'task7',
			},
			{
				title:Utility.language('任务8', this.state.lang),
				dataIndex: 'task8',
				key: 'task8',
			},
			{
				title: Utility.language('任务9', this.state.lang),
				dataIndex: 'task9',
				key: 'task9',
			},
			{
				title: Utility.language('任务10', this.state.lang),
				dataIndex: 'task10',
				key: 'task10',
			},
			{
				title: Utility.language('任务11', this.state.lang),
				dataIndex: 'task11',
				key: 'task11',
			},
			{
				title: Utility.language('任务12', this.state.lang),
				dataIndex: 'task12',
				key: 'task12',
			},
			{
				title: Utility.language('任务13', this.state.lang),
				dataIndex: 'task13',
				key: 'task13',
			},
		]

		this._load();
	}

	componentDidMount() {
		this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');

		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}

	onSearch = () => {

		this.setState({}, () => {
			this._load();
		})

	}

	onOk(value) {
		//('onOk: ', value);
	}

	onChange = (value, dateString) => {
		this.setState({
			startTime: dateString[0],
			endTime: dateString[1],
		})
	}

	tableChange = (pagination, filters, sorter, extra) => {
		this.setState({
			currentPage: pagination.current,
			pageSize: pagination.pageSize,
		});
	}

	onSelectChange = selectedRowKeys => {
		this.setState({selectedRowKeys});
	};

	selectChange = (value) => {
		// console.log(`selected ${value}`);
		this.setState({
			status: value,
			currentPage: 1,
		}, () => {
			this._load();
		})
	}

	start = () => {
		this.setState({loading: true});
		setTimeout(() => {
			this.setState({
				selectedRowKeys: [],
				loading: false,
			});
		}, 500);
	};


	handleTableChange = (pagination) => {
		this.setState({
			currentPage: pagination,
		}, () => {
			this._load();
		})
	}

	SetTitle = (value) => {
		return <h1 style={{textAlign: "center"}}>{value}</h1>;
	}


	async _load() {

		let page = this.state.currentPage;
		let limit = this.state.pageSize;
		let time = '';
		let id = 0 ;
		let u_type = 0 ;
		try {
			time = this.props.location.state.date;
			id =this.props.location.state.id;
			u_type =this.props.location.state.u_type;
		} catch (e) {
			if (!time || !id || !u_type) {
				message.error(Utility.language('缺少跳转参数，请从上个页面重新进来', this.state.lang));
				return false;
			}
		}
		if (!time || !id || !u_type) {
			message.error(Utility.language('缺少跳转参数，请从上个页面重新进来', this.state.lang));
			return false;
		}
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('reception/taskdataDetail?bid=' + bid + '&id=' + id  + '&u_type=' + u_type + '&page=' + page + '&limit=' + limit)
		if (res.success) {
			this.setState({
				data: res.data.data,
				rid: res.data.rid,
				rname: res.data.rname,
				name: res.data.name,
				user_id: res.data.user_id,
			});
		} else {
			message.error(res.msg)
		}
	}

	render() {
		var time = '';
		try {
			time = this.props.location.state.date;
		} catch (e) {
			if (!time) {
				message.error(Utility.language('缺少跳转参数，请从上个页面重新进来', this.state.lang));
			}
		}
		const paginationProps = {
			onChange: (page) => this.handleTableChange(page),
			total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage,

		}

		const { tableScrollX } = this.state;
		return (
			<article className="page-task-data-detail">
				<PageHeader
					className="site-page-header"
					onBack={() => window.history.back()}
					title={Utility.language('返回任务数据', this.state.lang)}
					subTitle=""
				/>
				<header className="ptdd-header">
					<Space>
						{Utility.language('房间id', this.state.lang)}:<span>{this.state.rid}</span>
						{Utility.language('房间昵称', this.state.lang)}:<span>{this.state.rname}</span>
						{Utility.language('成员ID', this.state.lang)}:<span>{this.state.user_id}</span>
						{Utility.language('成员昵称', this.state.lang)}:<span>{this.state.name}</span>
						{Utility.language('周期', this.state.lang)}:<span>{time}</span>
					</Space>
				</header>
				<Table
					className="ptdd-table"
					scroll={{ x: tableScrollX }}
					rowKey={record => record.level}
					columns={this._columns}
					dataSource={this.state.data}
					bordered
					pagination={paginationProps}
					onChange={this.tableChange}
				/>
			</article>
		);
	}
}