var zh_cn = {};
zh_cn.l = {
    "今日总流水": "今日总流水",
    "本周期总流水": "本周期总流水",
    "环比昨日": "环比昨日",
    "环比上周": "环比上周",
    "日期": "日期",
    "订单详情": "订单详情",
    "账期": "账期",
    "成员ID": "成员ID",
    "昵称": "昵称",
    "人气值奖励": "人气值奖励",
    "查询": "查询",
    "人气值审核": "人气值审核",
    "成员昵称": "成员昵称",
    "成员vip等级": "成员vip等级",
    "状态": "状态",
    "结算类型": "结算类型",
    "打包结算": "打包结算",
    "普通结算": "普通结算",
    "加入时间": "加入时间",
    "最后登录时间": "最后登录时间",
    "操作": "操作",
    "确定通过该成员的入会申请？": "确定通过该成员的入会申请？",
    "确定拒绝该成员的入会申请？": "确定拒绝该成员的入会申请？",
    "入会审核通过": "入会审核通过",
    "入会审核拒绝": "入会审核拒绝",
    "确定同意该成员的退会申请？": "确定同意该成员的退会申请？",
    "同意退会": "同意退会",
    "确定拒绝该成员的退会申请？": "确定拒绝该成员的退会申请？",
    "拒绝退会": "拒绝退会",
    "确定从公会移除该成员？": "确定从公会移除该成员？",
    "移出公会": "移出公会",
    "加入待审核": "加入待审核",
    "已通过": "已通过",
    "已拒绝": "已拒绝",
    "退会待审核": "退会待审核",
    "确认": "确认",
    "取消": "取消",
    "所选个数存在问题": "所选个数存在问题",
    "输入uid": "输入uid",
    "状态筛选": "状态筛选",
    "全部状态": "全部状态",
    "加入批量通过": "加入批量通过",
    "退会批量拒绝": "退会批量拒绝",
    "主播ID": "主播ID",
    "主播昵称": "主播昵称",
    "返回公会奖励": "返回公会奖励",
    "同意": "同意",
    "时间": "时间",
    "人气变更对象": "人气变更对象",
    "房间": "房间",
    "人气变更详细": "人气变更详细",
    "生效时间": "生效时间",
    "人气值变化": "人气值变化",
    "时长": "时长",
    "操作人id": "操作人id",
    "返回房间管理": "返回房间管理",
    "房间id": "房间id",
    "欢迎光临": "欢迎光临",
    "请输入手机号码": "请输入手机号码",
    "请输入正确的图形验证码": "请输入正确的图形验证码",
    "请填写正确的手机验证码": "请填写正确的手机验证码",
    "正在登录中": "正在登录中",
    "你未被邀请开通公会": "你未被邀请开通公会",
    "手机号": "手机号",
    "请输入右边的图形验证码!": "请输入右边的图形验证码!",
    "图形码": "图形码",
    "验证码": "验证码",
    "请输入你收到的验证码!": "请输入你收到的验证码!",
    "获取验证码": "获取验证码",
    "秒后重发": "秒后重发",
    "登录": "登录",
    "订单流水": "订单流水",
    "订单成功数": "订单成功数",
    "订单失败数": "订单失败数",
    "有单人数": "有单人数",
    "返回公会流水": "返回公会流水",
    "公会流水监控": "公会流水监控",
    "今日订单总流水": "今日订单总流水",
    "本周期订单总流水": "本周期订单总流水",
    "本周订单成功数": "本周订单成功数",
    "本周期订单失败数": "本周期订单失败数",
    "本周期有单人数": "本周期有单人数",
    "房间昵称": "房间昵称",
    "接待id": "接待id",
    "接待昵称": "接待昵称",
    "在档房间流水": "在档房间流水",
    "在坑流水": "在坑流水",
    "开始日期": "开始日期",
    "结束日期": "结束日期",
    "魅力值": "魅力值",
    "缺少房间参数，请从上个页面重新进来": "缺少房间参数，请从上个页面重新进来",
    "返回房间流水": "返回房间流水",
    "房间魅力榜单": "房间魅力榜单",
    "房主昵称": "房主昵称",
    "房主ID": "房主ID",
    "房间状态": "房间状态",
    "房间业务": "房间业务",
    "麦时（本周期）": "麦时（本周期）",
    "设置房间人气值": "设置房间人气值",
    "房间信息": "房间信息",
    "公会人气值": "公会人气值",
    "人气设置": "人气设置",
    "使用时长": "使用时长",
    "提交设置": "提交设置",
    "数据格式错误": "数据格式错误",
    "人气设置不能为空": "人气设置不能为空",
    "时长不能为空": "时长不能为空",
    "人气只能是大于100的正整数": "人气只能是大于100的正整数",
    "时长只能是大于1的正整数": "时长只能是大于1的正整数",
    "待创建": "待创建",
    "正常": "正常",
    "关闭": "关闭",
    "删除": "删除",
    "房间频道": "房间频道",
    "房间流水": "房间流水",
    "麦时": "麦时",
    "房间流水详细": "房间流水详细",
    "时段": "时段",
    "流水": "流水",
    "房间流水监控": "房间流水监控",
    "公会类型": "公会类型",
    "结算金额": "结算金额",
    "查看详细": "查看详细",
    "说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准": "说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准",
    "缺少日期参数，请从上个页面重新进来": "缺少日期参数，请从上个页面重新进来",
    "缺少跳转参数，请从上个页面重新进来": "缺少跳转参数，请从上个页面重新进来",
    "返回结算管理": "返回结算管理",
    "未发放": "未发放",
    "已发放": "已发放",
    "Partying业务": "Partying业务",
    "签约成员": "成员列表",
    "房间管理": "房间管理",
    "流水信息": "流水信息",
    "公会流水": "公会流水",
    "主播流水": "主播流水",
    "接待流水": "接待流水",
    "结算信息": "结算信息",
    "结算管理": "结算管理",
    "日接待时长": "日接待时长",
    "当前任务等级": "當前任務等級",
    "1级任务完成度": "1級任務完成度",
    "2级任务完成度": "2級任務完成度",
    "3级任务完成度": "3級任務完成度",
    "4级任务完成度": "4級任務完成度",
    "5级任务完成度": "5級任務完成度",
    "接待任务": "接待任務",
    "主播任务": "主播任務",
    "萌新任务": "萌新任務",
    "任务等级": "任務等級",
    "完成状态": "完成狀態",
    "完成": "完成",
    "未完成": "未完成",
    "周期": "周期",
    "任务1": "任务1",
    "任务2": "任务2",
    "任务3": "任务3",
    "任务4": "任务4",
    "任务5": "任务5",
    "任务6": "任务6",
    "任务7": "任务7",
    "任务8": "任务8",
    "任务9": "任务9",
    "任务10": "任务10",
    "任务11": "任务11",
    "任务12": "任务12",
    "任务13": "任务13",
    "返回任务数据": "返回任务数据",
    "任务数据": "任务数据",
    "运营数据": "运营数据",
    "有效天":"有效天",
    "数据是否聚合":"数据是否聚合",
    "是":"是",
    "否":"否",
    "直播房间流水":"直播房间流水",
    "房间流水汇总":"房间流水汇总",
    "是否签约":"是否签约",
    "成员类型":"成员等级",
};

export default zh_cn;