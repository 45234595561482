import React, { Component} from 'react';
import {
	Table, Input, Button, Select, Row, Col, Modal, List, Card, InputNumber, message, Space
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Xhr from '../libs/Xhr';
import BaseModal from '../libs/BaseModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Utility from "../libs/Utility";
import './scss/RoomManage.scss';

export default class RoomManage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			roomValue: 0,
			currentPage: 1,
			pageSize: 10,
			ssuid: '',
			searchText: '',
			searchedColumn: '',
			dt: 0,
			selectedRowKeys: [],
			loading: false,
			visible: false,
			modalTitle:'',
			modalData:'',
			broker:[],
			deduct: 0,
			channel: [],
			lang: 'zh_cn',
			tableScrollX: 1200,
		}


		this._columns = [
			{
				title:Utility.language('房间id', this.state.lang),
				dataIndex:'rid',
				key:'rid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.rid - b.rid,
			},
			{
				title: Utility.language('房间昵称', this.state.lang),
				dataIndex: 'name',
				key: 'name',
				...this.getColumnSearchProps('name'),
			}, {
				title: Utility.language('房间状态', this.state.lang),
				dataIndex: 'deleted',
				key: 'deleted',
				render: (text, record) => {
					return this._columnsDeletedRender(text, record)
				},
			},
			{
				title:Utility.language('房主ID', this.state.lang),
				dataIndex:'uid',
				key:'uid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.uid - b.uid,
			},{
				title:Utility.language('房主昵称', this.state.lang),
				dataIndex:'nickname',
				key:'nickname',
				...this.getColumnSearchProps('nickname'),
			}
			,{
				title: Utility.language('房间业务', this.state.lang),
				dataIndex: 'settlement_channel',
				key: 'settlement_channel',
				render: (text, record) => {
					return this._columnsStateRender(text, record)
				},
			}, {
				title: Utility.language('麦时（本周期）', this.state.lang),
				dataIndex: 'o_duration',
				key: 'o_duration',
				render: (text, record) => {
					return text ? Utility.secondsToDate(text) : '';
				},
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.o_duration - b.o_duration,
			},
			// {
			// 	title: '操作',
			// 	key: 'action',
			// 	render: (text, record) => (
			// 		<span>
			// 			<Button type="link" onClick={() => this.setHit(text.rid, text.name)}>
			// 				设置房间人气值
			// 			</Button>
			// 		</span>
			// 	),
			// },
		]

		this._load();
	}

	componentDidMount() {
        this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	operaLog = () => {
		this.setState({
			modalTitle:'操作日志',
			modalData:<div>
				<p>这是操作日志</p>
			</div>
		})
		this.showModal()
	}

	setHit = (rid, name) => {
		this.setState({
			modalTitle:Utility.language('设置房间人气值', this.state.lang),
			modalData:<div>
				<List
					size="small"
					grid={{ gutter: 0, column: 1 }}
					dataSource={[{title:Utility.language('房间信息', this.state.lang)}]}
					renderItem={item => (
						<List.Item>
							<Card>
								<p>{Utility.language('房间id', this.state.lang)}:{rid}</p>
								<p>{Utility.language('房间昵称', this.state.lang)}：{name}</p>
							</Card>
						</List.Item>
					)}
				/>
				<List
					size="small"
					grid={{ gutter: 0, column: 1 }}
					dataSource={[{title:Utility.language('房间信息', this.state.lang)}]}
					renderItem={item => (
						<List.Item>
							<Card>
								<p>{Utility.language('公会人气值', this.state.lang)}：{this.state.broker['hotnum']}    <span id='deduct' style={{color:"red", margin:10}}>0</span></p>
							</Card>
						</List.Item>
						//todo 
					)}
				/>
				<List
					size="small"
					grid={{ gutter: 0, column: 1 }}
					dataSource={[{title:Utility.language('房间信息', this.state.lang)}]}
					renderItem={item => (
						<List.Item>
							<Card>
								{/*<Row style={{margin:10}}>*/}
								{/*	<Col span={5}>生效日期</Col>*/}
								{/*	<Col span={15}>*/}
								{/*		<DatePicker>*/}
								{/*			showTime={{ format: 'HH:mm:ss' }}*/}
								{/*			format="YYYY-MM-DD HH:mm:ss"*/}
								{/*			onChange={this.onChange}*/}
								{/*		</DatePicker>*/}
								{/*	</Col>*/}
								{/*</Row>*/}
								<Row style={{margin:10}}>
									<Col span={5}>{Utility.language('人气设置', this.state.lang)}</Col>
									<Col span={15}><InputNumber min={100} name="moodLength" onChange={value=>this.getInputValue(value, 'moodLength')} type={"text"}/></Col>
								</Row>
								<Row style={{margin:10}}>
									<Col span={5}>{Utility.language('使用时长', this.state.lang)}</Col>
									<Col span={15}><InputNumber min={1} name="useLength" onChange={value=>this.getInputValue(value, 'useLength')} type={"text"}/></Col>
								</Row>
								<Input type={"hidden"} value={rid} name="roomd"/>
								<p style={{textAlign:"center"}}><Button onClick={() => this.handleAudit(rid)} style={{margin:10}} type="primary">{Utility.language('提交设置', this.state.lang)}</Button></p>
							</Card>
						</List.Item>
					)}
				/>
			</div>
		})
		this.showModal()
	}

	setAdmin = (value) => {
		this.setState({
			modalTitle:'设置房间管理员',
			modalData:<div>
				<p>这是设置房间管理员值模板</p>
			</div>
		})
		this.showModal()
	}

	setRoom = (value) => {
		let content = !value ? '确定关闭房间么' : '确定开启房间么';
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: content,
			okText: '确认',
			cancelText: '取消',
		});
		//ToDo 调用开关房间操作
	}

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleAudit = (rid) => {
		const{useLength, moodLength} = this.state;
		if(!moodLength)
		{
			Modal.error({
				title: Utility.language('数据格式错误', this.state.lang),
				content: Utility.language('人气设置不能为空', this.state.lang),
			});
			return false;
		}
		if(!useLength)
		{
			Modal.error({
				title: Utility.language('数据格式错误', this.state.lang),
				content: Utility.language('时长不能为空', this.state.lang),
			});
			return false;
		}
		 let reg = /^[1-9]\d*$/
		if(!reg.test(moodLength))
		{
			Modal.error({
				title: Utility.language('数据格式错误', this.state.lang),
				content: Utility.language('人气只能是大于100的正整数', this.state.lang),
			});
			return false
		}
		if(!reg.test(useLength))
		{
			Modal.error({
				title: Utility.language('数据格式错误', this.state.lang),
				content: Utility.language('时长只能是大于1的正整数', this.state.lang),
			});
			return false
		}
		this._submitAudit(rid, moodLength, useLength)
	}

	async _submitAudit( rid, mood, use){
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('room/setMoodHot?bid=' + bid + '&rid=' + rid + '&prizehot=' + mood + '&hour=' + use)
		if (res.success) {
			message.success("success");
			this.handleOk();
			//this._load();
		}else{
			Modal.warning({
				title: 'fail',
				content: res.msg,
			});
		}
	}

	handleOk = e => {
		this.setState({
			visible: false,
		});
		this._load();
	};

	handleCancel = e => {
		this.setState({
			visible: false,
		});
	};
	
	  handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
		  searchText: selectedKeys[0],
		  searchedColumn: dataIndex,
		});
	  };
	
	  handleReset = clearFilters => {
		clearFilters();
		this.setState({ 
			searchText: '' 
		});
	  };

	_columnsDeletedMap = {
		'-1':'待创建',
		'0':'正常',
		'1':'关闭',
		'2':'删除',
	}

	_columnsDeletedRender(text, record) {
		if (this._columnsDeletedMap[text]) {
			return Utility.language(this._columnsDeletedMap[text], this.state.lang);
		}
		return text;
	}

	_columnsStateRender(text, record) {
		if (this.state.channel[text]) {
			return this.state.channel[text];
		}
		return text;
	}

	handleTableChange = (pagination) => {
		// this.setState({
		//   currentPage: pagination,
		// }, () =>{
		// 	this._load();
		// })
	}

	selectChange = (value) => {
		// console.log(`selected ${value}`);
		this.setState({
			dt: value,
			currentPage:1,
		  }, () =>{
			  this._load();
		  })
	}

	onSearch = () => {
		this.setState({
			currentPage: 1,
		},() => {
			this._load();
		})
	}

	onChange = (value, dateString) =>  {
		//console.log('Selected Time: ', value);
		//console.log('Formatted Selected Time: ', dateString);
	}

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
		//动态展示预扣除的人气值
		if(this.state.useLength && this.state.moodLength)
		{
			document.getElementById("deduct").innerHTML = this.state.useLength * this.state.moodLength
		}
	}

	tableChange = (pagination, filters, sorter, extra) => {
		if(extra.action !== 'paginate')
			return false;
		this.setState({
				currentPage: pagination.current,
				pageSize: pagination.pageSize,
			},()=>this._load()

		);
	}

	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
		  this.setState({
			selectedRowKeys: [],
			loading: false,
		  });
		}, 500);
	};



	async _load() {

		let page = this.state.currentPage;
		let dt = this.state.dt;
		let limit = this.state.pageSize;
		let room_id = this.state.roomValue;
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('room/index?bid='+ bid +'&dt='+ dt + '&room_id=' + room_id + '&page=' + page + '&limit=' + limit)
		if (res.success) {
			this.setState({
				data: res.data,
				total: res.total,
				broker: res.broker,
				channel: res.channel
			});
		}else{
			message.error(res.msg);
		}
	}

	render() {
		const paginationProps = {
		    onChange : (page) => this.handleTableChange(page),
		  	total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage
		}
		const { Option } = Select;
		const { tableScrollX } = this.state;
		return (
			<article className="page-room-manage">
				<header className="prm-header">
					<Space>
						<InputNumber  name="roomValue" onChange={value=>this.getInputValue(value, 'roomValue')} placeholder={Utility.language('房间id', this.state.lang)} />
						<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
						{Utility.language('房间频道', this.state.lang)}：<Select
						placeholder="select"
						optionFilterProp="children"
						onChange={this.selectChange}
						>
							<Option value="">all</Option>
							{Object.keys(this.state.channel).map((item, key) => {
								return (
									<Option key={key} value={item}>{this.state.channel[item]}</Option>
								)
							})}
						</Select>
						<BaseModal
							title={this.state.modalTitle}
							visible={this.state.visible}
							onCancel={this.handleCancel}
							onOk={this.handleOk}
							data={this.state.modalData}
						>
						</BaseModal>
					</Space>
				</header>
				<Table
					className="pbu-table"
					scroll={{ x: tableScrollX }}
					rowKey={record => record.rid}
					columns={this._columns}
					dataSource={this.state.data}
					bordered
					pagination={paginationProps}
					onChange={this.tableChange}
				/>
			</article>
		);
	}
}