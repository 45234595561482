import React, { Component} from 'react';
import {
	Table, Button, DatePicker, InputNumber, message, Input, Space
} from 'antd';
import Xhr from '../libs/Xhr';
import {Link} from "react-router-dom";
import Utility from "../libs/Utility";
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import './scss/RoomWater.scss';

export default class RoomWater extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			types: [],
			currentPage: 1,
			pageSize: 10,
			searchText: '',
			searchedColumn: '',
			dt: 0,
			startTime: "",
			endTime: "",
			roomValue: 0,
			ruidValue: 0,
			selectedRowKeys: [],
			loading: false,
			isFun :false,
			lang: 'zh_cn',
			tableScrollX: 1200,
		}


		this._columns = [
			{
				title:Utility.language('日期', this.state.lang),
				dataIndex:'day',
				key:'day',
				// render: (text, record) => {
				// 	return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy-MM-dd') : '';
				// },
				// defaultSortOrder: 'descend',
				// sorter: (a, b) => a.day - b.day,
			},
			{
				title:Utility.language('房间id', this.state.lang),
				dataIndex:'rid',
				key:'rid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.rid - b.rid,
			},
			{
				title: Utility.language('房主ID', this.state.lang),
				dataIndex: 'ruid',
				key: 'ruid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.ruid - b.ruid,
			}, {
				title: Utility.language('房主昵称', this.state.lang),
				dataIndex: 'name',
				key: 'name',
				...this.getColumnSearchProps('name'),
			},
			// {
			// 	title:'公会业务',
			// 	dataIndex:'type',
			// 	key:'type',
			// 	render: (text, record) => {
			// 		return this._columnsStateRender(text, record)
			// 	},
			// },
			// {
			// 	title: '所属频道',
			// 	dataIndex: 'channel_id',
			// 	key: 'channel_id',
			// 	render: (text, record) => {
			// 		return this._columnsDeletedRender(text, record)
			// 	},
			// },
			{
				title: Utility.language('房间流水', this.state.lang),
				dataIndex: 'water_total',
				key: 'water_total',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.water_total - b.water_total,
			},
			{
				title: Utility.language('麦时', this.state.lang),
				dataIndex: 'o_duration',
				key: 'o_duration',
				render: (text, record) => {
					return text ? Utility.secondsToDate(text) : '';
				},
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.o_duration - b.o_duration,
			},{
				title: Utility.language('操作', this.state.lang),
				key: 'action',
				render: (text, record) => (
					<span>
						<p><Link to={{
							pathname: '/static/roomwaterdetail',
							state: {'rid': record.rid},
						}}>{Utility.language('房间流水详细', this.state.lang)}</Link></p>
						{this.state.isFun ?
							<p><Link to={{
								pathname: '/static/roomchardetail',
								state: {'rid': record.rid},
							}}>{Utility.language('房间魅力榜单', this.state.lang)}</Link></p>
							: ''}
						{this.state.isFun ?
							<p><Link to={{
								pathname: '/static/roomprofferdetail',
								state: {'rid': record.rid},
							}}>{Utility.language('房间贡献榜单', this.state.lang)}</Link></p>
							: ''}
					</span>
				),
			},
		]

		this._load();
	}

	componentDidMount() {
        this.userinfo();
	}


	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({
			searchText: ''
		});
	};

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}

	_columnsDeletedRender(text, record) {
		if (this.state.channel[text]) {
			return this.state.channel[text];
		}
		return text;
	}

	_columnsStateRender(text, record) {
		if (this.state.types[text]) {
			return this.state.types[text];
		}
		return text;
	}

	handleTableChange = (pagination) => {
		// this.setState({
		//   currentPage: pagination,
		// }, () =>{
		// 	this._load();
		// })
	}

	selectChange = (value) => {
		// console.log(`selected ${value}`);
		this.setState({
			dt: value,
			currentPage:1,
		  }, () =>{
			  this._load();
		  })
	}

	onSearch = () => {
		this.setState({
			currentPage: 1,
		},() => {
			this._load();
		})
	}

	onChange = (value, dateString) => {
		this.setState({
			startTime: dateString[0],
			endTime: dateString[1],
		})
	}

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}
	  
	tableChange = (pagination, filters, sorter, extra) => {
		if(extra.action !== 'paginate')
			return false;
		this.setState({
				currentPage: pagination.current,
				pageSize: pagination.pageSize,
			},()=>this._load()
		);
	}

	onSelectChange = selectedRowKeys => {
		console.log('selectedRowKeys changed: ', selectedRowKeys);
		this.setState({ selectedRowKeys });
	};

	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
		  this.setState({
			selectedRowKeys: [],
			loading: false,
		  });
		}, 500);
	};



	async _load() {

		let page = this.state.currentPage;
		let dt = this.state.dt;
		let limit = this.state.pageSize;
		let room_id = this.state.roomValue;
		let ruid = this.state.ruidValue;
		let start_time = this.state.startTime;
		let end_time = this.state.endTime;
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('roomWater/getRoomList?bid=' + bid + '&room_id=' + room_id + '&ruid=' + ruid + '&channel_id=' + dt + '&start_time=' + start_time + '&end_time=' + end_time + '&page=' + page + '&limit=' + limit)
		if (res.success) {
			this.setState({
				data: res.data.data,
				total: res.data.total,
				isFun: res.data.is_fun,
			});
		}else{
			message.error(res.msg);
		}
	}

	render() {
		const paginationProps = {
		  onChange : (page) => this.handleTableChange(page),
		  	total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage
		}

		const { RangePicker } = DatePicker;

		const dateFormat = 'YYYY/MM/DD';
		const end = moment().format(dateFormat);
		const start = moment().subtract('7','day').format(dateFormat);

		const { tableScrollX } = this.state;
		return (
				<article className="page-room-water">
					<header className="prw-header">
						<Space>
							{Utility.language('日期', this.state.lang)}：<RangePicker
							format="YYYY-MM-DD"
							onChange={this.onChange}
							onOk={this.onOk}
							defaultValue={[moment( start ,dateFormat), moment(end, dateFormat)]}
						/>
							<InputNumber  name="roomValue" onChange={value=>this.getInputValue(value, 'roomValue')} placeholder={Utility.language('房间id', this.state.lang)} />
							<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
						</Space>
					</header>
					<Table
						className="prw-table"
						scroll={{ x: tableScrollX }}
						rowKey={record => record.id}
						columns={this._columns}
						dataSource={this.state.data}
						bordered
						pagination={paginationProps}
						onChange={this.tableChange}
					/>
				</article>
		);
	}
}