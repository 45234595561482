import React, { Component} from 'react';
import {
	Table, Button, DatePicker, InputNumber, PageHeader, message, Input, Space
} from 'antd';
import Xhr from '../libs/Xhr';
import Utility from '../libs/Utility';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import './scss/HotMange.scss';

export default class HotManage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			currentPage: 1,
			pageSize: 10,
			selectedRowKeys: [],
			loading: false,
			startTime: "",
			endTime: "",
			roomValue: 0,
			lang: 'zh_cn',
			tableScrollX: 1200,
		}

		this._columns = [
			{
				title:Utility.language('时间', this.state.lang),
				dataIndex:'dateline',
				key:'dateline',
				render: (text, record) => {
					return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy-MM-dd HH:mm:ss') : '';
				},
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.dateline - b.dateline,
			},
			{
				title: Utility.language('人气变更对象', this.state.lang),
				dataIndex: 'rid',
				key: 'rid',
				render: (text, record) => {
					return record.rid > 0 ? "room："+record.rid : "+"+record.rid;
				},
			}, {
				title: Utility.language('人气变更详细', this.state.lang),
				dataIndex: 'rname',
				key: 'rname',
				render: (text, record) => {
					return record.rid > 0 ? "room："+record.rname : "-";
				},
			}, {
				title: Utility.language('生效时间', this.state.lang),
				dataIndex: 'dateline',
				key: 'dateline',
				render: (text, record) => {
					return text ? Utility.dateFormat(new Date(text * 1000), 'yyyy-MM-dd HH:mm:ss') : '';
				},
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.dateline - b.dateline,
			},{
				title: Utility.language('人气值变化', this.state.lang),
				dataIndex: 'totalhot',
				key: 'totalhot',
				render: (text, record) => {
					return record.rid > 0 ? "-"+record.totalhot : "+"+record.totalhot;
				},
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.totalhot - b.totalhot,
			}, {
				title:Utility.language('时长', this.state.lang),
				dataIndex:'hour',
				key:'hour',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.hour - b.hour,
			},
			{
				title:Utility.language('操作人id', this.state.lang),
				dataIndex:'uid',
				key:'uid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.uid - b.uid,
			},
		]

		this._load();
	}

	componentDidMount() {
        this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');
		var lang = localStorage.getItem('lang');
		this.setState({
			lang: lang ? lang : 'zh_cn',
		})
		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}


	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({
			searchText: ''
		});
	};


	handleTableChange = (pagination, size) => {
		//console.log('page', pagination);
		this.setState({
		  	currentPage: pagination,
			pageSize: size
		}, () =>{
			this._load();
		})
	}

	onSearch = () => {

		this.setState({
			currentPage: 1,
		}, () =>{
			this._load();
		})

	}

	onOk(value) {
		//console.log('onOk: ', value);
	}

	onChange = (value, dateString) => {
		this.setState({
			startTime: dateString[0],
			endTime: dateString[1],
		})
	}

	tableChange = (pagination, filters, sorter, extra) => {
		//console.log('tableChange', pagination, filters, sorter, extra);
	}

	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
		  this.setState({
			selectedRowKeys: [],
			loading: false,
		  });
		}, 500);
	};

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}


	async _load() {

		const { currentPage, pageSize, roomValue,  startTime, endTime} = this.state;

		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('room/getHotLog?bid=' + bid + '&room_id=' + roomValue + '&page=' + currentPage + '&limit=' + pageSize + '&start_time=' + startTime + '&end_time=' + endTime)
		if (res.success) {
			this.setState({
				data: res.data.data,
				total: res.data.total,
			});
		}else{
			message.error(res.msg)
		}
	}

	render() {
		const paginationProps = {
		  onChange : (page, size) => this.handleTableChange(page, size),
		  	total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage
		}
		const { RangePicker } = DatePicker;
		const { tableScrollX } = this.state;


		return (
		<article className="page-hot-manage">
			<PageHeader
				className="site-page-header"
				onBack={() => window.history.back()}
				title={Utility.language('返回房间管理', this.state.lang)}
				subTitle=""
			/>
			<header className="phm-header">
				<Space>
				<RangePicker
					showTime={{ format: 'HH:mm:ss' }}
					format="YYYY-MM-DD HH:mm:ss"
					onChange={this.onChange}
					onOk={this.onOk}
				/>
				<InputNumber  name="roomValue" onChange={value=>this.getInputValue(value, 'roomValue')} placeholder="房间id" />
				<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
				</Space>
			</header>
				<Table
					className="phm-table"
					scroll={{ x: tableScrollX }}
					rowKey={record => record.id}
					columns={this._columns}
					dataSource={this.state.data}
					bordered
					pagination={paginationProps}
					onChange={this.tableChange}
				/>
		</article>
		);
	}
}