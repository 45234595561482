import React, {Component} from 'react';
import {Form, Input, Button, message, Radio} from "antd";
import Xhr from '../libs/Xhr';
import Utility from "../libs/Utility";
import sha1 from "../libs/sha1";
import Head from "../components/Head";
import "./scss/login.scss"
import { helpSite, apiSite } from './Constant';
import moment from 'moment';
import axios from "axios";
import Qs from 'qs'
import BaseThirdLogin from "./thirdLogin/BaseThirdLogin";

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 60,
            liked: true,
            disabled: false,
            reg: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            isSendMsg: false,
            vcodeImg: '',
            page: '', // vcode是验证码页
            mobile: '',
            vcode: '',
            token: '',
            code: '',
            area: '',
            lang: 'zh_cn',
            switchs: 'phone',
            phoneShow: true,
            accountShow: false,
            account: '',
            password: '',
        }
    }

    // 创建一个ref
    formRef = React.createRef()

    componentDidMount() {
        var lang = localStorage.getItem('lang');
        if (!lang) {
            localStorage.setItem('lang', 'en');
        }
        this.setState({
            lang: lang ? lang : 'en',
        })
        let login_phone = localStorage.getItem('login_phone');
        if(login_phone)
        {
            this.setState({
                account : login_phone,
                mobile : login_phone,
            })
        }
        //检测用户是否有填充历史记录
        this.getCode();
        //获取websission  存起来
        this.getWebSession().then(v=>{
            this.queryCodeImg();
        });
    }

    async getCode() {
        let login_area = localStorage.getItem('login_area');
        if(!login_area)
        {
            var res = await Xhr.post(apiSite + '/account/getAreaCode', {
            });
            if(res.success)
            {
                localStorage.setItem('login_area', res.data.code);
                login_area = res.data.code;
            }
        }
        this.setState({
            area: login_area,
        })
        this.formRef.current.setFieldsValue({
            "area" : login_area,
        })
    }

    async getWebSession() {
        let websession = localStorage.getItem('wesession');
        if(!websession || moment().format("YYYY-MM-DD HH:mm:ss") >= JSON.parse(websession).expire)
        {
            let res = await Xhr.post( helpSite + '/h5user/getWebSession', {
            });
            if(res.success){
                localStorage.setItem('wesession', JSON.stringify(res.data));
                websession = JSON.stringify(res.data);
            }
        }
        return websession;
    }



    countDown() {
        const {count} = this.state;
        if (count === 1) {
            this.setState({
                count: 60,
                liked: true,
                disabled: false
            });
        } else {
            this.setState({
                count: count - 1,
                liked: false,
                disabled: true
            });
            setTimeout(this.countDown.bind(this), 1000);
        }
    }

    async _submitMobile() {

        const {liked, mobile, vcode, area} = this.state;
        // console.log('state',this.state);
        if (!mobile) {
            message.error(Utility.language('请输入手机号码', this.state.lang));
            return false;
        }
        if (!liked) {
            return false;
        }
        // eslint-disable-next-line
        if (!vcode || String(vcode).length != 4) {
            message.error(Utility.language('请输入正确的图形验证码', this.state.lang));
            return false;
        }
        // eslint-disable-next-line
        if (window.location.hostname == 'localhost' || window.location.hostname == 'oversea.imee.com') {
            var webSite = 'http://xs.imee.com';
        } else {
            // eslint-disable-next-line
            var webSite = helpSite
        }

        let wesession = this.getWebSession();
        var data =  Qs.stringify({
            mobile: mobile,
            vcode: vcode,
            area: area,
        });
        wesession.then(v=>{
            axios( {
                method: 'post',
                url: webSite + '/h5user/sendVerifyCode',
                headers: { 'WEBSESSION': JSON.parse(v).websession },
                data
            }).then(res => {
                console.log(res);
                if (!res.data.success) {
                    // eslint-disable-next-line
                    if (res.data.msg && res.data.msg.indexOf("驗證碼錯誤") != -1) {
                        res.data.msg = "驗證碼錯誤";
                    }
                    message.error(Utility.language(res.data.msg, this.state.lang));
                    this.queryCodeImg();
                    return false;
                }
                if (res.data) {
                    localStorage.setItem('vtoken', res.data);
                    this.setState({page: 'vcode', vtoken: res.data});
                    this.countDown();
                }
            })
        })

    };

    async _submitLogin() {
        localStorage.setItem('login_area', '');
        const {mobile, code, area, password, account, switchs, vcode} = this.state;
        let login_phone = '';
        // eslint-disable-next-line
        if (window.location.hostname == 'localhost' || window.location.hostname == 'oversea.imee.com') {
            var webSite = 'http://xs.imee.com';
        } else {
            // eslint-disable-next-line
            var webSite = helpSite
        }
        if (!vcode) {
            message.error(Utility.language('请填写正确的图形验证码', this.state.lang));
            return false;
        }
        // eslint-disable-next-line
        if (switchs == 'phone') {
            if (!mobile) {
                message.error(Utility.language('请输入手机号码', this.state.lang));
                return false;
            }

            // eslint-disable-next-line
            if (!code || String(code).length != 4) {
                message.error(Utility.language('请填写正确的手机验证码', this.state.lang));
                return false;
            }

            login_phone = mobile;
            var res = await Xhr.post(webSite + '/h5user/login', {
                mobile: mobile,
                type: 'login',
                code: code,
                token: vcode,
                area: area,
            });
        } else {
            if (!account) {
                message.error(Utility.language('请输入你的账号', this.state.lang));
                return false;
            }
            if (!password) {
                message.error(Utility.language('请输入你的密码', this.state.lang));
                return false;
            }
            login_phone = account;
            // eslint-disable-next-line
            var res = await Xhr.post(webSite + '/h5user/uidLogin', {
                account: account,
                vcode: vcode,
                password: sha1.hex_sha1(password),
            });
        }

        console.log(res);
        if (!res.success) {
            // eslint-disable-next-line
            if (res.msg && res.msg.indexOf("驗證碼錯誤") != -1) {
                res.msg = "驗證碼錯誤";
            }
            message.error(Utility.language(res.msg, this.state.lang));
            this.queryCodeImg();
            return false;
        }
        // eslint-disable-next-line
        if (res.data.broker != 1 && res.data.is_hunter != 1) { // 非公会长、非挖猎人
            message
                .loading(Utility.language('正在登录中', this.state.lang), 1)
                .then(() => message.error(Utility.language('你未被邀请开通公会', this.state.lang), 2.5))
            return false;
        }
        if (res.data) {
            var uinfoMap = {uid: 1, name: 1, icon: 1, broker: 1};
            var uinfo = {uid: 0};
            var udata = res.data;
            for (var i in udata) {
                if (!uinfoMap[i]) continue;
                uinfo[i] = udata[i];
            }

            localStorage.removeItem('vtoken');
            localStorage.setItem('uid', uinfo.uid);
            localStorage.setItem('token', udata.token);
            localStorage.setItem('login_area', area);
            localStorage.setItem('login_phone', login_phone);
            if (udata['fu']) localStorage.setItem('fu', udata.fu);
            localStorage.setItem('uinfo', JSON.stringify(uinfo));

            // 跳转到新后台(pt、pt都跳)
            const { data } = await Xhr.get("api/auth/login/callback");
            window.location.href = 'https://'+window.location.hostname+'/new/'
            return

            res = await Xhr.get("broker/brokerlist");
            if (res && res.success) {
                //localStorage.setItem('bid', res.data[0].bid);
                localStorage.setItem('broker_language', res.data[0].language);
            }
            this.props.history.push('/');
            window.location.href = '/';
        }

    };


    queryCodeImg = () => {
        let wesession = this.getWebSession();
        wesession.then(v=>{
            axios.get(  helpSite + `/h5user/sendVerifyCodeImg?`, {
                responseType: 'blob',
                params: {  },
                headers: { 'WEBSESSION': JSON.parse(v).websession }
            }).then(res => {
                let reader = new FileReader();
                reader.onload = (e) => this.setState({ vcodeImg: e.target.result });
                reader.readAsDataURL(res.data);
            })
        })
    }

    reloadVcode = () => {
        // eslint-disable-next-line
        if (window.location.hostname == 'localhost' || window.location.hostname == 'family.imee.com') {
            var webSite = 'http://xs.imee.com';
        } else {
            // eslint-disable-next-line
            var webSite = helpSite
        }
        let vcodeImage = webSite + '/h5user/sendVerifyCodeImg?v=' + Math.random();

        // if (process.env.NODE_ENV === 'development') {
        //   vcodeImage = '/h5user/sendVerifyCodeImg?v=' + Math.random();
        // }

        this.setState({
            vcodeImg: vcodeImage
        })
    }

    checkArea = ({target: {value}}) => {
        console.log(value);
        if (value) {
            this.setState({
                area: value
            })
        }
    }

    checkPhone = ({target: {value}}) => {
        if (value) {
            this.setState({
                isSendMsg: true,
                mobile: value
            })
        }
    }

    checkAccount = ({target: {value}}) => {
        if (value) {
            this.setState({
                account: value
            })
        }
    }

    checkPassword = ({target: {value}}) => {
        if (value) {
            this.setState({
                password: value
            })
        }
    }


    onFinish = () => {
        console.log("Received values of form: ");
    };

    switchRadio = e => {
        this.setState({
            switchs: e.target.value
        })
        // eslint-disable-next-line
        if (e.target.value == 'account') {
            this.setState({
                phoneShow: false,
                accountShow: true,
            })
        } else {
            this.setState({
                phoneShow: true,
                accountShow: false,
            })
        }
    };



    render() {
        const {switchs} = this.state;
        const login_phone =  localStorage.getItem('login_phone');
        const login_area = localStorage.getItem('login_area');
        const onFinish = values => {
            console.log('Success:', values);
            this.setState({
                isSendMsg: true,
                mobile: values.phone,
                account: values.account,
                area: values.area,
            }, () => {
                this._submitLogin()
            })
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const query = new URLSearchParams(this.props.location.search);
        let third_login = query.get('third_login');

        return (
            <article className='page-login'>
                <div className='page-login-logo'>
                    <img alt="img" src="https://xs-image.pati.sg/backstage/partying/family/logo.png"/>
                </div>
                <div className='page-login-setting'>
                    <Head noFrame/>
                </div>
                <div className='page-login-ext'>
                <Form className='page-login-content' onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      initialValues={{switch: 'phone'}}
                      ref={this.formRef}
                >
                    <header className="page-login-switch">
                        <Form.Item name="switch" noStyle>
                            <Radio.Group value={switchs} onChange={this.switchRadio}>
                                <Radio.Button
                                    value="phone">{Utility.language('手机号登录', this.state.lang)}</Radio.Button>
                                <Radio.Button
                                    value="account">{Utility.language('账号登录', this.state.lang)}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </header>
                    {
                        this.state.phoneShow &&
                        <section>
                            <div className='pl-item'>
                                <label htmlFor="">{Utility.language('手机号', this.state.lang)}</label>
                                <div className='pl-item-content pl-contcat'>
                                    <Form.Item name="area" noStyle initialValue={ login_area }
                                        //rules={[{ required: true ,message: '请输入的正确手机号!',pattern: reg}]}
                                    >
                                        <Input className="pl-contcat-area"
                                               placeholder={Utility.language('区号', this.state.lang)}
                                               onChange={this.checkArea}
                                               id="phone_area_id"
                                        />
                                    </Form.Item>
                                    <Form.Item name="phone" noStyle initialValue={login_phone}>
                                        <Input className="pl-contcat-phone"
                                               placeholder={Utility.language('手机号', this.state.lang)}
                                               onChange={this.checkPhone}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='pl-item pl-vcode'>
                                <label>{Utility.language('图形码', this.state.lang)}</label>
                                <div className='pl-item-content'>
                                    <Form.Item name="vcode" noStyle
                                    >
                                        <Input className="pl-vcode-input" autoComplete="off"
                                               onChange={({target: {value}}) => {
                                                   this.setState({vcode: value});
                                               }}
                                        />
                                    </Form.Item>
                                    <img alt="img" className="pl-vcode-image" src={this.state.vcodeImg}
                                         onClick={() => this.queryCodeImg()}
                                    />
                                </div>
                            </div>

                            <div className='pl-item pl-code'>
                                <label>{Utility.language('验证码', this.state.lang)}</label>
                                <div className='pl-item-content'>
                                    <Form.Item name="code" noStyle
                                    >
                                        <Input onChange={({target: {value}}) => {
                                            this.setState({code: value});
                                        }}/>
                                    </Form.Item>
                                    <Button className="pl-code-btn" type="primary"
                                            onClick={() => {
                                                this._submitMobile()
                                            }}
                                            disabled={this.state.disabled}
                                    >
                                        {
                                            this.state.liked
                                                ? Utility.language('获取验证码', this.state.lang)
                                                : `${this.state.count} second`
                                        }
                                    </Button>
                                </div>
                            </div>


                        </section>

                    }
                    {this.state.accountShow ?
                        <section style={{display: this.state.accountShow}}>
                            <div className='pl-item'>
                                <label>{Utility.language('账号', this.state.lang)}</label>
                                <div className='pl-item-content pl-contcat'>
                                    {/*<Form.Item name="area" noStyle initialValue={ login_area }>
                                        <Input className="pl-contcat-area"
                                               placeholder={Utility.language('区号', this.state.lang)}
                                               onChange={this.checkArea}
                                               id="account_area_id"
                                        />
                                    </Form.Item>*/}
                                    <Form.Item name="account" initialValue={login_phone} className="pl-contcat-phone"
                                               noStyle
                                    >
                                        <Input className="pl-contcat-phone"
                                               placeholder={Utility.language('UID', this.state.lang)}
                                               onChange={this.checkAccount}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='pl-item pl-pw'>
                                <label>{Utility.language('密码', this.state.lang)}</label>
                                <div className='pl-item-content'>

                                    <Form.Item noStyle name="password"
                                    >
                                        <Input.Password onChange={this.checkPassword}/>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='pl-item pl-code'>
                                <label>{Utility.language('图形码', this.state.lang)}</label>
                                <div className='pl-item-content'>
                                    <Form.Item
                                        name="vcode"
                                        noStyle
                                    >

                                        <Input className="pl-vcode-input" value={this.state.vcode}
                                               onChange={({target: {value}}) => {
                                                   this.setState({vcode: value});
                                               }}/>
                                    </Form.Item>
                                    <img alt="img" className="pl-vcode-image" src={this.state.vcodeImg}
                                         onClick={() => this.queryCodeImg()}/>
                                </div>
                            </div>
                        </section> : ''}

                    <div className="page-login-btn">
                        <Button type="primary" htmlType="submit"
                                //onClick={() => this._submitLogin()}
                        >
                            {Utility.language('登录', this.state.lang)}
                        </Button>
                    </div>
                </Form>
                {third_login == 1 ?
                    <div className="third-login-btn">
                        <BaseThirdLogin />
                    </div> : ''
                }
                </div>
                <footer className="page-login-footer">©2023 Created by Pati</footer>
            </article>
        );
    }

}
