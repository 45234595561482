var en = {};
en.l = {
    "今日总流水": "Today's total income",
    "本周期总流水": "Total income this week",
    "环比昨日": "month-on-month ratio of yesterday",
    "环比上周": "month-on-month ratio of last week",
    "日期": "Date",
    "订单详情": "Order details",
    "账期": "Account period",
    "成员ID": "Member ID",
    "昵称": "Nickname",
    "人气值奖励": "Popularity reward",
    "查询": "Inquire",
    "人气值审核": "Popularity review",
    "成员昵称": "Member Nickname",
    "成员vip等级": "VIP Level of Member",
    "状态": "Status",
    "结算类型": "Settlement type",
    "打包结算": "All settlement",
    "普通结算": "Ordinary settlement",
    "加入时间": "Time Joined",
    "最后登录时间": "Last login date",
    "操作": "Operate",
    "确定通过该成员的入会申请？": "Are you sure to pass the member's application for agency?",
    "确定拒绝该成员的入会申请？": "Are you sure to reject the member's application for agency?",
    "入会审核通过": "Your application for agency is approved",
    "入会审核拒绝": "Your application for agency is rejected",
    "确定同意该成员的退会申请？": "Are you sure you agree to the member's withdrawal application?",
    "同意退会": "Agree to quit agency",
    "确定拒绝该成员的退会申请？": "Are you sure you disagree to the member's withdrawal application?",
    "拒绝退会": "Refuse to quit agency",
    "确定从公会移除该成员？": "Are you sure to remove this member from the agency?",
    "移出公会": "Remove from the agency",
    "加入待审核": "Join pending",
    "已通过": "Approved",
    "已拒绝": "Rejected",
    "退会待审核": "Withdrawal pending",
    "确认": "Confirm",
    "取消": "Cancel",
    "所选个数存在问题": "A problem with the selected number",
    "输入uid": "Enter uid",
    "状态筛选": "Select status",
    "全部状态": "All Statuses",
    "加入批量通过": "Members who joined the agency passed in batches",
    "退会批量拒绝": "Members who withdrew from the agency rejected in batches",
    "主播ID": "Host ID",
    "主播昵称": "Host Nickname",
    "返回公会奖励": "Return to agency rewards",
    "同意": "Agree",
    "时间": "Time",
    "人气变更对象": "Change Popularity objects",
    "房间": "Room",
    "人气变更详细": "Popularity change details",
    "生效时间": "Effective time",
    "人气值变化": "Popularity value change",
    "时长": "Duration",
    "操作人id": "Operator id",
    "返回房间管理": "Return to Room Management",
    "房间id": "Room ID",
    "欢迎光临": "Welcome",
    "请输入手机号码": "Please enter your phone number",
    "请输入正确的图形验证码": "Please enter the correct graphic verification code",
    "请填写正确的手机验证码": "Please fill in the correct phone verification code",
    "正在登录中": "Logging in",
    "你未被邀请开通公会": "You are not invited to open an agency",
    "手机号": "Phone number",
    "请输入右边的图形验证码!": "Please enter the graphic verification code on the right!",
    "图形码": "Graphic code",
    "验证码": "Verification code",
    "请输入你收到的验证码!": "Please enter the verification code you received!",
    "获取验证码": "Send verification code",
    "秒后重发": "Resend in seconds",
    "登录": "Login",
    "订单流水": "Order income",
    "订单成功数": "Number of successful orders",
    "订单失败数": "Number of failed orders",
    "有单人数": "Number of people with orders",
    "返回公会流水": "Return to the agency income",
    "公会流水监控": "Agency Income Monitoring",
    "今日订单总流水": "Total income of today's orders",
    "本周期订单总流水": "Total order income this week",
    "本周订单成功数": "Number of successful orders this week",
    "本周期订单失败数": "Number of failed orders this week",
    "本周期有单人数": "Number of people with orders this week",
    "房间昵称": "Room Nickname",
    "接待id": "Receptionist id",
    "接待昵称": "Receptionist Nickname",
    "在档房间流水": "Room income during live broadcast",
    "在坑流水": "income on the seat",
    "开始日期": "Start Date",
    "结束日期": "End Date",
    "魅力值": "Charm Value",
    "缺少房间参数，请从上个页面重新进来": "Missing room parameters, please enter again from the previous page",
    "返回房间流水": "Return to Room income",
    "房间魅力榜单": "Rank of room charm",
    "房主昵称": "Room Owner Nickname",
    "房主ID": "Room Owner ID",
    "房间状态": "Room Status",
    "房间业务": "Room businesses",
    "麦时（本周期）": "Mic time (this period)",
    "设置房间人气值": "Set room popularity value",
    "房间信息": "Room information",
    "公会人气值": "Agency Popularity",
    "人气设置": "Popularity setting",
    "使用时长": "Using time",
    "提交设置": "Submit settings",
    "数据格式错误": "Data format error",
    "人气设置不能为空": "Popularity setting cannot be empty",
    "时长不能为空": "Duration cannot be empty",
    "人气只能是大于100的正整数": "Popularity can only be a positive integer greater than 100",
    "时长只能是大于1的正整数": "The duration can only be a positive integer greater than 1",
    "待创建": "To be created",
    "正常": "Normal",
    "关闭": "Close",
    "删除": "Delete",
    "房间频道": "types of room",
    "房间流水": "Room income",
    "麦时": "Mic time",
    "房间流水详细": "Room income details",
    "时段": "Time period",
    "流水": "Income",
    "房间流水监控": "Room income monitoring",
    "公会类型": "Agency type",
    "结算金额": "Settle amount",
    "查看详细": "See all",
    "说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准": "Note：This settlement amount does not include live broadcast rebates, which are subject to the results announced by the official operation",
    "缺少日期参数，请从上个页面重新进来": "Missing date parameters, please enter again from the previous page",
    "缺少跳转参数，请从上个页面重新进来": "Missing jump parameters, please enter again from the previous page",
    "返回结算管理": "Return to settlement management",
    "未发放": "To be issued",
    "已发放": "Issued",
    "Partying业务": "Partying businesses",
    "签约成员": "Signing members",
    "房间管理": "Room Management",
    "流水信息": "Income information",
    "公会流水": "Agency income",
    "主播流水": "Host income",
    "接待流水": "Receptionist income",
    "结算信息": "Settlement Information",
    "结算管理": "Manage Settlement",
    "登录方式": "Login method",
    "手机号登录": "Mobile phone number login",
    "账号登录": "Account login",
    "请输入你的账号": "Please enter your account number",
    "请输入你的密码": "Please enter your password",
    "密码": "password",
    "手機號碼格式不合理": "Invalid phone number format",
    "驗證碼錯誤": "Verification code is wrong",
    "驗證碼好像不對哦…": "Wrong verification code",
    "区号": "Area code",
    "韩语": "Korean",
    "印尼语": "Indonesian",
    "泰语": "Thai",
    "阿拉伯语": "Arabic",
    "账号": "Account id",
    "越南语": "Vietnamese",
    "日接待时长": "Daily reception time",
    "当前任务等级": "Current task level",
    "1级任务完成度": "Lvl 1 task completion",
    "2级任务完成度": "Lvl 2 task completion",
    "3级任务完成度": "Lvl 3 task completion",
    "4级任务完成度": "Lvl 4 task completion",
    "5级任务完成度": "Lvl 5 task completion",
    "接待任务": "Host Task",
    "主播任务": "Anchor Task",
    "萌新任务": "Newbie Attract Task",
    "任务等级": "Task level",
    "完成状态": "Completion Status",
    "完成": "Complete",
    "未完成": "Uncompleted",
    "周期": "Period",
    "任务1": "Task 1",
    "任务2": "Task 2",
    "任务3": "Task 3",
    "任务4": "Task 4",
    "任务5": "Task 5",
    "任务6": "Task 6",
    "任务7": "Task 7",
    "任务8": "Task 8",
    "任务9": "Task 9",
    "任务10": "Task 10",
    "任务11": "Task 11",
    "任务12": "Task 12",
    "任务13": "Task 13",
    "返回任务数据": "Return Task Data",
    "运营数据":"Operational data",
    "任务数据":"Task Data",
    "有效天":"Valid days",
    "数据是否聚合":"Data Aggregation",
    "是":"Yes",
    "否":"No",
    "直播房间流水":"Live room income",
    "房间流水汇总":"room income total",
    "是否签约":"Whether signed membe",
    "成员类型":"Member type",
};

export default en;