import React, {Component} from 'react';
import Xhr from "../../libs/Xhr";
import * as _ from 'lodash'
import hello from 'hellojs'
import {message, Modal} from "antd";
import Utility from "../../libs/Utility";
import Script from 'react-load-script';
import { helpSite, apiSite } from "../Constant";

import "../scss/BaseThirdLogin.scss";

export default class BaseThirdLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'zh_cn',
            scriptStatus: false
        }
    }

    handleSnapScriptLoad() {
        let that = this;
        this.setState({ scriptStatus: true })
        const host = this.getLocationHostName();
        const url = window.location.protocol + '//' + host + '/h5/loginsnap';
        console.log(url);
        window.snapKitInit = function () {
            window.snap.loginkit.mountButton("my-login-button-target", {
                clientId: "57c55967-38e8-433c-a35a-cd97c380994e",
                redirectURI: url,
                scopeList: [
                    "user.display_name",
                    "user.bitmoji.avatar",
                    "user.external_id",
                ],
                handleResponseCallback: function () {
                    window.snap.loginkit.fetchUserInfo().then(
                       async function (result) {
                            console.log("User info:", result.data.me);

                            let params = {
                                openId: result.data.me?.externalId
                            };

                            message.loading();
                            try {
                                var res = await Xhr.post(helpSite + '/h5user/snapLogin?c=openid&url=' + encodeURIComponent(url) + '&is_broker=1', params);
                            } catch (err) {
                                return that.errModal(err);
                            }
                            return that.handleLogin(res);
                        },
                        function (err) {
                            return that.errModal(err);
                        }
                    );
                },
            });
        };
        window.snapKitInit();
    }

    getLocationHostName()
    {
        let host = window.location.host;
        if (host.length && host.lastIndexOf(':') !== -1) {
            return host.substring(0, host.lastIndexOf(':'));
        }
        return host;
    }

    getLang(){
        var lang = localStorage.getItem('lang');
        if (!lang) {
            localStorage.setItem('lang', 'en');
        }
        this.setState({
            lang: lang ? lang : 'en',
        })
    }

    errModal(title = '') {
        return Modal.error({title: title});
    }

    componentDidMount() {
        // 获取语言
        this.getLang();

        hello.init({
            twitter: 'MBNegFqBUC74DqpSbbH3o35Hn',
            facebook: '430994877543280',
            google: '323405409360-gq4jjk0abrum3mtnjj9qm3lqpaji6dtr.apps.googleusercontent.com',
        });
    }

    async handleLogin(res) {
        if (!res.success) {
            return this.errModal(Utility.language(res.msg, this.state.lang));
        }

        if (res.data.broker != 1) {
            message
                .loading(Utility.language('正在登录中', this.state.lang), 1)
                .then(() => this.errModal(Utility.language('你未被邀请开通公会', this.state.lang)))
            return false;
        }

        if (res.data) {
            var uinfoMap = {uid: 1, name: 1, icon: 1, broker: 1};
            var uinfo = {uid: 0};
            var udata = res.data;
            for (var i in udata) {
                if (!uinfoMap[i]) continue;
                uinfo[i] = udata[i];
            }

            localStorage.removeItem('vtoken');
            localStorage.setItem('uid', uinfo.uid);
            localStorage.setItem('token', udata.token);
            if (udata['fu']) localStorage.setItem('fu', udata.fu);
            localStorage.setItem('uinfo', JSON.stringify(uinfo));
            res = await Xhr.get("broker/brokerlist");
            if (res && res.success) {
                localStorage.setItem('broker_language', res.data[0].language);
            }
            // this.props.history.push('/');
            window.location.href = '/';
        }

        return true;
    }

    async lineLogin() {
        const host = this.getLocationHostName();
        const url = window.location.protocol + '//' + host + '/h5/loginline';
        // 请求获取line的登录页面
        let res = await Xhr.get(helpSite + '/h5user/lineLogin?c=redirect&url=' + encodeURIComponent(url));
        if (res.success)
            window.location.href = res.data;
    }

    async googleLogin() {
        const host = this.getLocationHostName();
        const url = window.location.protocol + '//' + host + '/h5/logingoogle';
        console.log(url);
        hello.login(
            'google',
            {
                redirect_uri: url,
                oauth_proxy: 'https://auth-server.herokuapp.com/proxy',
                response_type: 'token id_token',
                scope: 'openid email',
            },
            async (_res) => {
                console.log('Google Authorize:', _res);

                if (!_.isObject(_res.authResponse))
                    return this.errModal(_res?.error?.message || 'Authorization error');

                let authRes = _res.authResponse
                let params = {
                    type: 'login',
                    idToken: authRes.id_token,
                    accessToken: authRes.access_token,
                    platform: 'web',
                }

                message.loading();
                try {
                    var res = await Xhr.post(apiSite + '/account/googleLogin', params);
                } catch (err) {
                    return this.errModal(err);
                }

                if (!res?.data?.token)
                    return this.errModal(res?.msg || 'Authorization error');

                // 设置token
                localStorage.setItem('token', res?.data?.token);

                try {
                    var result = await Xhr.post(helpSite + '/h5user/googleLogin?c=user&is_broker=1', {});
                } catch (err) {
                    return this.errModal(err);
                }

                return this.handleLogin(result);
            }
        )
    }

    async facebookLogin() {
        const host = this.getLocationHostName();
        const url = window.location.protocol + '//' + host + '/h5/loginfacebook';
        console.log(url);
        hello.login(
            'facebook',
            {
                redirect_uri: url,
                oauth_proxy: 'https://auth-server.herokuapp.com/proxy',
            },
            async (_res) => {
                console.log('Facebook Authorize:', _res);

                if (!_.isObject(_res.authResponse))
                    return this.errModal(_res?.error?.message || 'Authorization error');

                let authRes = _res.authResponse
                let params = {
                    type: 'login',
                    platform: 'web',
                    t: authRes?.access_token,
                };

                message.loading();
                try {
                    var res = await Xhr.post(apiSite + '/account/fbLogin?t=' + authRes?.access_token, params);
                } catch (err) {
                    return this.errModal(err);
                }

                if (!res?.data?.token)
                    return this.errModal(res?.msg || 'Authorization error');

                // 设置token
                localStorage.setItem('token', res?.data?.token);

                try {
                    var result = await Xhr.post(helpSite + '/h5user/facebookLogin?c=user&is_broker=1', {});
                } catch (err) {
                    return this.errModal(err);
                }

                return this.handleLogin(result);
            }
        )
    }

    render() {
        return (
            <div className="third-login-content">
                {/*<div onClick={() => { this.lineLogin() }} alt="line">*/}
                {/*    <img className="third-login-img" src="https://www.partying.sg/assets/login_line.png?v=9d3f8fb4" />*/}
                {/*</div>*/}
                <div onClick={() => { this.googleLogin() }} alt="google">
                    <img className="third-login-img" src="https://www.partying.sg/assets/login_google.png?v=30c4c013" />
                </div>
                <div onClick={() => { this.facebookLogin() }} alt="facebook">
                    <img className="third-login-img" src="https://www.partying.sg/assets/login_facebook.png?v=17a24322" />
                </div>
                <div className={[this.state.scriptStatus ? '' : 'my-login-button-hide']} id="my-login-button-target"></div>
                <Script url="https://sdk.snapkit.com/js/v1/login.js" onLoad={this.handleSnapScriptLoad.bind(this)}></Script>
            </div>
        );
    }
}