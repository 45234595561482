import React, {Component} from 'react';
import {
	Table, Button, DatePicker, message, Space, Tabs, InputNumber
} from 'antd';
import Xhr from '../libs/Xhr';
import {Link} from "react-router-dom";
import './scss/TaskData.scss';
import Utility from "../libs/Utility";

export default class TaskData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			currentPage: 1,
			pageSize: 10,
			searchText: '',
			searchedColumn: '',
			startTime: "",
			endTime: "",
			user_id: 0,
			rid: 0,
			selectedRowKeys: [],
			loading: false,
			startValue: '',
			endValue: '',
			endOpen: false,
			types: '',
			tableScrollX: 1200,
			u_type:2,
		}


		this._columns = [
			{
				title: Utility.language('周期', this.state.lang),
				dataIndex: 'date',
				key: 'date',
			},
			{
				title: Utility.language('成员ID', this.state.lang),
				dataIndex: 'uid',
				key: 'uid',
			}, {
				title: Utility.language('成员昵称', this.state.lang),
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: Utility.language('房间id', this.state.lang),
				dataIndex: 'rid',
				key: 'rid',
			},
			{
				title: Utility.language('房间昵称', this.state.lang),
				dataIndex: 'rname',
				key: 'rname',
			},
			{
				title: Utility.language('当前任务等级', this.state.lang),
				dataIndex: 'level',
				key: 'level',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.level - b.level,
			},
			{
				title: Utility.language('1级任务完成度', this.state.lang),
				dataIndex: 'level_1',
				key: 'level_1',
			},
			{
				title: Utility.language('2级任务完成度', this.state.lang),
				dataIndex: 'level_2',
				key: 'level_2',
			},
			{
				title: Utility.language('3级任务完成度', this.state.lang),
				dataIndex: 'level_3',
				key: 'level_3',
			},
			{
				title: Utility.language('4级任务完成度', this.state.lang),
				dataIndex: 'level_4',
				key: 'level_4',
			},
			{
				title: Utility.language('5级任务完成度', this.state.lang),
				dataIndex: 'level_5',
				key: 'level_5',
			},
			{
				title: Utility.language('操作', this.state.lang),
				key: 'action',
				render: (text, record) => (
					<span>
						<p><Link to={{
							pathname: '/static/taskdatadetail',
							state: {'id': record.id,'u_type': this.state.u_type,'date': record.date,},
						}}>{Utility.language('查看详细', this.state.lang)}</Link></p>
					</span>
				),
			},
		]

		this._load();
	}

	componentDidMount() {
		this.userinfo();
	}

	userinfo = () => {
		var uinfo = localStorage.getItem('uinfo');

		uinfo = JSON.parse(uinfo);
		if (!uinfo) {
			this.props.history.push('/static/login');
		}
	}

	handleTableChange = (pagination) => {
		this.setState({
			currentPage: pagination,
		}, () => {
			this._load();
		})
	}


	onSearch = () => {
		this.setState({
			currentPage: 1,
		}, () => {
			this._load();
		})
	}

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}

	tableChange = (pagination, filters, sorter, extra) => {
		this.setState({
			currentPage: pagination.current,
			pageSize: pagination.pageSize,
		});
	}

	onSelectChange = selectedRowKeys => {
		this.setState({selectedRowKeys});
	};

	start = () => {
		this.setState({loading: true});
		setTimeout(() => {
			this.setState({
				selectedRowKeys: [],
				loading: false,
			});
		}, 500);
	};

	handleTab = (u_type) => {
		this.setState({
			u_type: u_type,
		},()=>this._load());

	}


	async _load() {
		let user_id = this.state.user_id;
		let rid = this.state.rid;
		let start_time = this.state.startValue;
		let u_type = this.state.u_type;
		if(!u_type){
			u_type = 2
		}
		if (start_time)
			start_time = start_time.format('YYYY-MM-DD');
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('reception/taskData?bid=' + bid + '&start_time=' + start_time + '&user_id=' + user_id + '&rid=' + rid + '&u_type=' + u_type)
		if (res.success) {
			// eslint-disable-next-line
			this.setState({
				data: res.data.data,
			});

		} else {
			message.error(res.msg)
		}
	}


	disabledStartDate = (startValue) => {
		return startValue.weekday() !== 3;
	};


	onStartChange = (value) => {
		this.getInputValue(value,"startValue");
	};

	onChange = (field, value) => {
		this.setState({
			[field]: value
		});
	};


	render() {

		const {tableScrollX} = this.state;
		return (
			<div className="page-task-data">
				<div className="ptd-header">
					<Space>
						{Utility.language('周期', this.state.lang)}： <DatePicker
						disabledDate={this.disabledStartDate}
						format="YYYY-MM-DD"
						placeholder="Date"
						onChange={this.onStartChange}
						onOk={this.onOk}
					/>
						{Utility.language('成员ID', this.state.lang)}：<InputNumber name="user_id"
										  onChange={value => this.getInputValue(value, 'user_id')}
										  placeholder={Utility.language('成员ID', this.state.lang)}/>
						{Utility.language('房间id', this.state.lang)}：<InputNumber name="rid"
										  onChange={value => this.getInputValue(value, 'rid')}
										  placeholder={Utility.language('房间id', this.state.lang)}/>
						<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
					</Space>
				</div>

				<Tabs defaultActiveKey="2" type="card" onChange={ this.handleTab} className="ptd-table"
					  scroll={{ x: tableScrollX }} >
					<Tabs.TabPane tab = {Utility.language('接待任务', this.state.lang)} key="2">
						<Table
							rowKey={record => record.id}
							columns={this._columns}
							dataSource={this.state.data}
							bordered
							onChange={this.tableChange}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab={Utility.language('主播任务', this.state.lang)} key="1">
						<Table
							rowKey={record => record.id}
							columns={this._columns}
							dataSource={this.state.data}
							bordered
							onChange={this.tableChange}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab={Utility.language('萌新任务', this.state.lang)} key="3">
						<Table
							rowKey={record => record.id}
							columns={this._columns}
							dataSource={this.state.data}
							bordered
							onChange={this.tableChange}
							/>
					</Tabs.TabPane>
				</Tabs>

			</div>
		);

	}
}