import React, {Component} from 'react';
import qs from "qs";
import {Modal, message} from "antd";
import Xhr from "../../libs/Xhr";
import Utility from "../../libs/Utility";
import {helpSite} from "../Constant";

export default class LineLogin extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            lang: 'zh_cn',
        }
    }

    getLocationHostName()
    {
        let host = window.location.host;
        if (host.length && host.lastIndexOf(':') !== -1) {
            return host.substring(0, host.lastIndexOf(':'));
        }
        return host;
    }

    getLang(){
        var lang = localStorage.getItem('lang');
        if (!lang) {
            localStorage.setItem('lang', 'en');
        }
        this.setState({
            lang: lang ? lang : 'en',
        })
    }

    errModal(title = '') {
        return Modal.error({title: title});
    }

    async componentDidMount() {
        // 获取语言
        this.getLang();

        let urlSearch = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })

        let error_code = urlSearch?.error;
        if (error_code)
            return this.errModal(urlSearch?.error_description || 'Authorization error');

        let code = urlSearch?.code
        if (!code)
            return this.errModal('params error');

        message.loading();
        try {
            const host = this.getLocationHostName();
            const url = window.location.protocol + '//' + host + '/h5/loginline';
            var res = await Xhr.post(helpSite + '/h5user/lineLogin?c=code&url=' + encodeURIComponent(url) + '&is_broker=1', {code});
        } catch (err) {
            return this.errModal(err);
        }

        if (!res.success) {
            return this.errModal(Utility.language(res.msg, this.state.lang));
        }

        if (res.data.broker != 1) {
            message
                .loading(Utility.language('正在登录中', this.state.lang), 1)
                .then(() => this.errModal(Utility.language('你未被邀请开通公会', this.state.lang)))
            return false;
        }

        if (res.data) {
            var uinfoMap = {uid: 1, name: 1, icon: 1, broker: 1};
            var uinfo = {uid: 0};
            var udata = res.data;
            for (var i in udata) {
                if (!uinfoMap[i]) continue;
                uinfo[i] = udata[i];
            }

            localStorage.removeItem('vtoken');
            localStorage.setItem('uid', uinfo.uid);
            localStorage.setItem('token', udata.token);
            if (udata['fu']) localStorage.setItem('fu', udata.fu);
            localStorage.setItem('uinfo', JSON.stringify(uinfo));
            res = await Xhr.get("broker/brokerlist");
            if (res && res.success) {
                localStorage.setItem('broker_language', res.data[0].language);
            }
            // this.props.history.push('/');
            window.location.href = '/';
        }

        return true;
    }

    render() {
        return (
            <article className="page-line">
                <div className="loading">
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                </div>
                <div className="pl-logging">login......</div>
            </article>
        )
    }
}