var Utility = {};

Utility.isString = function (source) {
    // eslint-disable-next-line
    return '[object String]' == Object.prototype.toString.call(source);
};

Utility.isArray = function (source) {
    // eslint-disable-next-line
    return '[object Array]' == Object.prototype.toString.call(source);
};

Utility.isNumber = function (source) {
    // eslint-disable-next-line
    return '[object Number]' == Object.prototype.toString.call(source) && isFinite(source);
};

Utility.isObject = function (source) {
    // eslint-disable-next-line
    return 'function' == typeof source || !!(source && 'object' == typeof source);
};

Utility.isFunction = function (source) {
    // eslint-disable-next-line
    return '[object Function]' == Object.prototype.toString.call(source);
};

Utility.isPosition = function (souce) {
    return souce && souce.hasOwnProperty('latitude') && souce.hasOwnProperty('longitude');
}

Utility.blank = function () { };

Utility.extend = function () {
    var source;
    var target = {};
    for (var i = 0, len = arguments.length; i < len; i++) {
        source = arguments[i];
        for (var p in source) {
            if (source.hasOwnProperty(p)) {
                target[p] = source[p];
            }
        }
    }
    return target;
};

Utility.object = {};
Utility.object.isEmpty = function (source) {
    for (var key in source) {
        return false;
    }
    return true;
};
Utility.object.each = function (source, iterator) {
    var returnValue, key, item;
    // eslint-disable-next-line
    if ('function' == typeof iterator) {
        for (key in source) {
            if (source.hasOwnProperty(key)) {
                item = source[key];
                returnValue = iterator.call(source, item, key);
                if (returnValue === false) {
                    break;
                }
            }
        }
    }
    return source;
};

Utility.escapeSymbol = function (source) {
    // eslint-disable-next-line
    return String(source).replace(new RegExp("/[#%&+=\/\\\ \　\f\r\n\t]/g"), function (all) {
        return '%' + (0x100 + all.charCodeAt()).toString(16).substring(1).toUpperCase();
    });
};

Utility.jsonToQuery = function (json, replacer_opt) {
    var result = [],
        itemLen,
        replacer = replacer_opt || function (value) {
            return Utility.escapeSymbol(value);
        };

    Utility.object.each(json, function (item, key) {
        // 这里只考虑item为数组、字符串、数字类型，不考虑嵌套的object
        if (Utility.isArray(item)) {
            itemLen = item.length;
            // value的值需要encodeURIComponent转义吗？
            // FIXED 优化了escapeSymbol函数
            while (itemLen--) {
                result.push(key + '[]=' + replacer(item[itemLen], key));
            }
        } else {
            result.push(key + '=' + replacer(item, key));
        }
    });

    return result.join('&');
};

Utility.verify = {
    isEmail: function (email) {
        // eslint-disable-next-line
        return email && /^[-a-zA-Z0-9_\.]+\@([0-9A-Za-z_][0-9A-Za-z-]+\.)+[A-Za-z]{2,8}$/.test(email);
    },
    isPassword: function (password) {
        //修改密码长度6位
        return password && password.length >= 6;
    },
    isMobile: function (phone) {
        return phone && /^(13|14|15|16|17|18|19)\d{9}$/.test(phone);
    },
    //是否是手机验证码
    isVerifyCode: function (code) {
        return code && /^[0-9]{6}$/.test(code);
    },
    //是否合法的身份证
    isCard: function (code) {
        var len = code.length;
        var to18 = function (card) {
            var offset = card.substr(12, 3);
            if ((['996', '997', '998', '999']).indexOf(offset) > -1) {
                card = card.substr(0, 6) + '18' + card.substr(6, 9);
            } else {
                card = card.substr(0, 6) + '19' + card.substr(6, 9);
            }
            return card + verify(card);
        };
        var verify = function (base) {
            // eslint-disable-next-line
            if (base.length != 17) return false;
            var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            var verifyNumberList = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            var checksum = 0;
            for (var i = 0; i < 17; i++) {
                checksum += base.substr(i, 1) * factor[i];
            }
            var mod = checksum % 11;
            return verifyNumberList[mod].toLowerCase();
        };
        // eslint-disable-next-line
        if ((len != 15 && len != 18)) return false;
        // eslint-disable-next-line
        if ((len == 15 && /^\d+$/.test(code)) || (len == 18 && /^\d{15}(\d{2}[A-Za-z0-9])?$/.test(code))) {
            // eslint-disable-next-line
            if (len == 15) code = to18(code);
            var base = code.substr(0, 17);
            // eslint-disable-next-line
            return verify(base) == code.substr(17, 1).toLowerCase();
        }
        return false;
    },
};

const Minute = 1000 * 60;
const Hour = 1000 * 3600;
const Day = 1000 * 86400;

Utility.withZero = function (val) {
    if (val >= 10) return val;
    return '0' + val;
};

Utility.formatDateDis = function (unix) {
    var date = new Date(unix);
    var now = new Date().getTime();
    if (isNaN(now)) {
        return '';
    }
    var diff = now - unix;
    if (diff < Minute) {
        return '刚刚';
    } else if (diff < Hour) {
        return parseInt(diff / Minute) + '分钟前';
    } else if (diff < Day) {
        return parseInt(diff / Hour) + '小时前';
    } else if (diff < 2 * Day) {
        return '1天前';
    } else if (diff < 7 * Day) {
        return '2天前';
    } else {
        return Utility.dateFormat(date, 'M-dd')
    }
}

Utility.formatDate = function (unix) {
    var date = new Date(unix);
    var now = new Date().getTime();
    if (isNaN(now)) {
        return '';
    }
    var diff = now - unix;
    if (diff < Minute) {
        return '刚刚';
    } else if (diff < Hour) {
        return parseInt(diff / Minute) + '分钟前';
    } else if (diff < Day) {
        return parseInt(diff / Hour) + '小时前';
    } else {
        return Utility.withZero(date.getMonth() + 1) + '-' + Utility.withZero(date.getDate());
    }
}

Utility.expAt = function (sec) {
    sec = parseInt(sec);
    //sec = sec - 3600 * 1000 * 16
    if (sec <= 0) sec = 0;
    else if (sec >= Day) sec = Day;
    if (sec < Minute) {
        return '即将失效';
    } else if (sec < Hour) {
        return parseInt(sec / Minute) + '分钟后失效';
    } else if (sec <= Day) {
        return parseInt(sec / Hour) + '小时后失效';
    }
    return sec;
}

Utility.today = function (days) {
    var date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    if (days) date.setTime(date.getTime() + days * 86400 * 1000);
    return date;
}

Utility.hex2Rgba = function (hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        // eslint-disable-next-line
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
    }
    throw new Error('Bad Hex');
};
Utility.arrayUnique = function (array) {
    if (!array.length) return array;
    array.sort();
    var res = [array[0]];
    for (var i = 1; i < array.length; i++) {
        if (array[i] !== res[res.length - 1]) {
            res.push(array[i]);
        }
    }
    return res;
}

Utility.stringUidsToArray = function (extension) {
    if (!extension || !Utility.isString(extension)) return [];
    var uids = extension.trim().split(',');
    var array = [];
    for (var i = 0, len = uids.length; i < len; i++) {
        array.push(uids[i]);
    }
    return array;
}

var protocolType = /^([a-z0-9]*):\/\//;
// eslint-disable-next-line
var isHttp = /^http[s]*:\/\//;
// eslint-disable-next-line
var isImeeDomain = /oss\-cn\-hangzhou\.aliyuncs\.com\//;
Utility.getImageUrl = function (img, style, forbiddenProxy) {
    if (!img || !Utility.isString(img)) return '';
    var proto = protocolType.exec(img);
    var isLocal = false;
    if (proto) {
        // eslint-disable-next-line
        switch (proto[1]) {
            case 'file':
            case 'content':
                return img;
                // eslint-disable-next-line
                break;
        }
        // eslint-disable-next-line
    } else if (img.substr(0, 1) == '/') {
        return img;
    } else {
        if (forbiddenProxy) {
            img = 'http://xs-image.oss-cn-hangzhou.aliyuncs.com/' + img;
        } else {
            img = 'https://oversea-image.oss-cn-hongkong.aliyuncs.com/' + img;
        }
    }

    if (isLocal || img.match(isImeeDomain)) {
        var index = img.indexOf('!');
        if (index > -1) img = img.substr(0, index);
        return img + (style ? ('!' + style) : '');
    } else {
        return img;
    }
}

/**
 * use
 * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q) 可以用 1-2 个占位符
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
 * fmt: yyyy-MM-dd hh:mm:ss.S ==> 2006-07-02 08:09:04.423    
 * fmt: yyyy-MM-dd E HH:mm:ss ==> 2009-03-10 二 20:09:04   
 * fmt: yyyy-MM-dd EE hh:mm:ss ==> 2009-03-10 周二 08:09:04   
 * fmt: yyyy-MM-dd EEE hh:mm:ss ==> 2009-03-10 星期二 08:09:04  
 * fmt: yyyy-M-d h:m:s.S ==> 2006-7-2 8:9:4.18  
 */
Utility.dateFormat = function (date, fmt) {
    var o = {
        "M+": date.getMonth() + 1, //月份         
        "d+": date.getDate(), //日
        // eslint-disable-next-line
        "h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, //小时         
        "H+": date.getHours(), //小时         
        "m+": date.getMinutes(), //分         
        "s+": date.getSeconds(), //秒         
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度         
        "S": date.getMilliseconds() //毫秒         
    };

    var week = {
        "0": "日",
        "1": "一",
        "2": "二",
        "3": "三",
        "4": "四",
        "5": "五",
        "6": "六"
    };

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "星期" : "周") : "") + week[date.getDay() + ""]);
    }

    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            // eslint-disable-next-line
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
};

Utility.getToday = function () {
    var now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
}

Utility.getDiffToday = function (ntime) {
    if (!ntime) return '';
    var s = Utility.dateFormat(ntime, 'HH:mm');
    var diff = (ntime.getTime() - Utility.getToday().getTime()) / (86400 * 1000);
    if (diff > 0) {
        if (diff < 1) {
            return '今天 ' + s;
        } else if (diff < 2) {
            return '明天 ' + s;
        } else if (diff < 3) {
            return '后天 ' + s;
        }
    }
    return Utility.dateFormat(ntime, 'yyyy-MM-dd HH:mm');
}

Utility.uuid = function () {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    return s.join("");
};

Utility.randomInt = function (min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
};

Utility.trims = function (val) {
    return String(val).replace(/\s|　/g, "");
}

Utility.parseComponentParams = function (url) {
    //开始解析url
    var parts = String(url).split('?');
    var routeName = parts[0];
    var params = {};
    if (parts.length > 1) {
        var args = parts[1].split('&');
        var tmp, value;
        for (var i = 0, len = args.length; i < len; i++) {
            tmp = args[i].split('=');
            // eslint-disable-next-line
            if (tmp.length == 2) {
                if (tmp[1] === 'true') {
                    value = true;
                } else if (tmp[1] === 'false') {
                    value = false;
                } else {
                    value = tmp[1];
                }
                params[tmp[0]] = value;
            }
        }
    }
    return {
        name: routeName,
        params: params
    }
}

Utility.urlStringify = function (url, payload, encode = true) {
    const arr = Object.keys(payload).map(key =>
        `${key}=${encode ? encodeURIComponent(payload[key]) : payload[key]}`
    )

    // NOTE 注意支付宝小程序跳转链接如果没有参数，就不要带上 ?，否则可能无法跳转
    return arr.length ? `${url}?${arr.join('&')}` : url
}

let ua = navigator.userAgent.toLowerCase();
let ostype = "android";
if (/iphone|ipad|ipod|ios/.test(ua)) {
    ostype = "ios";
}
Utility.ostype = ostype;

let browser = "";
if (/micromessenger/.test(ua)) {
    browser = "wechat";
} else if (/mobile\smqqbrowser|\sqq/.test(ua)) {
    browser = "qq";
}
Utility.browser = browser;

Utility.toThousands = (num, sep) => {
    sep = sep || ',';
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1' + sep);
}

Utility.strip = (num, precision = 12) => {
    return +parseFloat(parseFloat(num).toPrecision(precision));
}

Utility.secondsToDate = function (second) {
    if (Utility.isString(second)) {
        var value = {
            "years":0, "days" : 0, "hours" : 0,
            "minutes" : 0, "seconds" : 0
        };
        if (second >= 31556926) {
            value["years"] = Math.floor(second / 31556926);
            second = (second % 31556926);
        }
        if (second >= 86400) {
            value["days"] = Math.floor(second / 86400);
            second = (second % 86400);
        }
        if (second >= 3600) {
            value["hours"] = Math.floor(second / 3600);
            second = (second % 3600);
        }
        if (second >= 60) {
            value["minutes"] = Math.floor(second / 60);
            second = (second % 60);
        }
        value["seconds"] = Math.floor(second);

        var t = '';
        if (value['years'] > 0) t += value["years"] + "y";
        if (value['days'] > 0) t += value["days"] + "d,";
        if (value['hours'] > 0) t += value["hours"] + "h,";
        if (value['minutes'] > 0) t += value["minutes"] + "m,";
        t += value["seconds"] + 's';
        return t;
    } else {
        return 0;
    }
}


Utility.language = function (str, lang) {
    var localLang = window.localStorage.getItem('lang');
    if(localLang)
        lang = localLang;
    switch (lang) {
        case 'th':
            var l = require('../lang/th');
            break;
        case 'zh_cn':
            // eslint-disable-next-line
            var l = require('../lang/zh_cn');
            break;
        case 'ar':
            // eslint-disable-next-line
            var l = require('../lang/ar');
            break;
        case 'id':
            // eslint-disable-next-line
            var l = require('../lang/id');
            break;
        case 'ko':
            // eslint-disable-next-line
            var l = require('../lang/ko');
            break;
        case 'vi':
            // eslint-disable-next-line
            var l = require('../lang/vi');
            break;
        case 'tu':
            // eslint-disable-next-line
            var l = require('../lang/tu');
            break;
        default:
            // eslint-disable-next-line
            var l = require('../lang/en');
            break;
    }
    if(l.default.l[str])
        return l.default.l[str];
    return str;
}

export default Utility;