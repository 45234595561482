import React, { Component} from 'react';
import {
    Table, Button, DatePicker, Card, PageHeader, message, Space, Statistic
} from 'antd';
import Xhr from '../libs/Xhr';
import moment from "moment";
import Utility from "../libs/Utility";
import './scss/RoomWaterDetail.scss';

export default class RoomWaterDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedRowKeys: [],
            loading: false,
            startTime: "",
            endTime: "",
            todayTotalWater: 0,
            yesterdayTotalWater: 0,
            currentWeekWater: 0,
            lastWeekWater: 0,
            roomId: 0,
            uid: 0,
            name: '',
            lang: 'zh_cn',
            tableScrollX: 1200,
        }

        this._columns = [
            {
                title: Utility.language('时段', this.state.lang),
                dataIndex: 'period_time',
                key: 'period_time',
            },{
                title: Utility.language('流水', this.state.lang),
                dataIndex: 'water_data',
                key: 'water_data',
            }
        ]

        this._load();
    }

    componentDidMount() {
        this.userinfo();
	}

    userinfo = () => {
        var uinfo = localStorage.getItem('uinfo');
        var lang = localStorage.getItem('lang');
        this.setState({
            lang: lang ? lang : 'zh_cn',
        })
        uinfo = JSON.parse(uinfo);
        if (!uinfo) {
            this.props.history.push('/static/login');
        }
    }

    onSearch = () => {

        this.setState({

        }, () =>{
            this._load();
        })

    }

    onOk(value) {
        //('onOk: ', value);
    }

    onChange = (value, dateString) => {
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        })
    }

    tableChange = (pagination, filters, sorter, extra) => {
        //console.log('tableChange', pagination, filters, sorter, extra);
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    start = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false,
            });
        }, 500);
    };


    SetTitle = (value) => {
        return <h1 style={{textAlign:"center"}}>{value}</h1>;
    }

    caclNum = (num1, num2) => {
        let dealer = ((num1 - num2)/ num2);
        // eslint-disable-next-line
        if(num2 == 0) dealer = num1
        dealer = (dealer * 100).toFixed(1);
        let res = <span><span style={{color:'gray'}}>0</span>%</span>;
        if(dealer >= 0) res = <span><span style={{color:'red'}}>{'+' + dealer}</span>%</span>;
        if(dealer < 0) res = <span><span style={{color:'green'}}>{ dealer}</span>%</span>;
        return res;
    }


    caclRate = (num1, num2) => {
        let dealer = ((num1 - num2) / num2).toFixed(1);
        // eslint-disable-next-line
        if (num2 == 0) dealer = num1;
        dealer = (dealer * 100).toFixed(1);
        return dealer;
    }
    caclColor = (num1, num2) => {
        let dealer = ((num1 - num2) / num2).toFixed(1);
        // eslint-disable-next-line
        if (num2 == 0) dealer = num1;
        dealer = (dealer * 100).toFixed(1);
        return dealer >= 0 ? 'red' : 'green';
    }


    async _load() {

        const { startTime, endTime} = this.state;
        let roomId = 0;
        try {
            roomId = this.props.location.state.rid;
        }catch (e) {
            if(!roomId)
            {
                message.error(Utility.language('缺少房间参数，请从上个页面重新进来', this.state.lang));
                return false;
            }
        }

        var bid = localStorage.getItem('bid');
        let res = await Xhr.get('roomwater/getRoomDetail?bid=' + bid + '&room_id=' + roomId + '&start_time=' + startTime + '&end_time=' + endTime)
        if (res.success) {
            this.setState({
                data: res.data.data,
                todayTotalWater: res.data.water_data.today_water_total,
                yesterdayTotalWater: res.data.water_data.yesterday_water_total,
                currentWeekWater: res.data.water_data.current_week_total,
                lastWeekWater: res.data.water_data.last_week_total,
                roomId: res.data.room_info.room_id,
                uid: res.data.room_info.uid,
                name: res.data.room_info.name,
            });
        }
    }

    render() {

        const { RangePicker } = DatePicker;
        const dateFormat = 'YYYY/MM/DD HH:ii:ss';
        const end = moment().format(dateFormat);
        const start = moment().subtract('3','day').format('YYYY/MM/DD 00:00:00');
        const { tableScrollX } = this.state;
        return (
            <article className="page-room-water-detail">
                <PageHeader
                    className="site-page-header"
                    onBack={() => window.history.back()}
                    title={Utility.language('返回房间流水', this.state.lang)}
                    subTitle=""
                />
                <section className="prwd-monitor">
                    <div className="prwd-monitor-header">{Utility.language('房间流水监控', this.state.lang)}</div>
                    <Space>
                        <Card bordered={false}>
                            <span>{Utility.language('房间id', this.state.lang)}：{this.state.roomId} {Utility.language('房主ID', this.state.lang)}：{this.state.uid} {Utility.language('房主昵称', this.state.lang)}：{this.state.name}</span>
                        </Card>
                    </Space>
                    <Space>
                        <Card>
                            <Statistic
                                title={Utility.language('今日总流水', this.state.lang)}
                                value={this.state.todayTotalWater}
                                precision={0}
                            />
                            <Statistic
                                title={Utility.language('环比昨日', this.state.lang)}
                                value={this.caclRate(this.state.todayTotalWater, this.state.yesterdayTotalWater)}
                                precision={1}
                                valueStyle={{color: this.caclColor(this.state.todayTotalWater, this.state.yesterdayTotalWater)}}
                                suffix="%"
                            />
                        </Card>
                        <Card>
                            <Statistic
                                title={Utility.language('本周期总流水', this.state.lang)}
                                value= {this.state.currentWeekWater}
                                precision={0}
                            />
                            <Statistic
                                title={Utility.language('环比昨日', this.state.lang)}
                                value={this.caclRate(this.state.currentWeekWater, this.state.lastWeekWater)}
                                precision={1}
                                valueStyle={{color: this.caclColor(this.state.currentWeekWater, this.state.lastWeekWater)}}
                                suffix="%"
                            />
                        </Card>
                    </Space>
                </section>
                <section className="prwd-filter">

                        {Utility.language('日期', this.state.lang)}：<RangePicker
                        showTime={{ format: 'HH:mm:ss' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={this.onChange}
                        onOk={this.onOk}
                        defaultValue={[moment( start ,dateFormat), moment(end, dateFormat)]}
                    />

                    <Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
                </section>


                {Object.keys(this.state.data).map((item, index) => {
                    return(
                        <Table
                            className="prwd-table"
                            scroll={{ x: tableScrollX }}
                            key={this.state.data[item].date}
                            title={this.SetTitle.bind(this, this.state.data[item].date)}
                            rowKey={record => record.id}
                            columns={this._columns}
                            dataSource={this.state.data[item].data}
                            bordered={true}
                            pagination={false}
                        />
                    )
                })}

            </article>
        );
    }
}