import React, { Component} from 'react';
import {Modal} from 'antd';

export default class BaseModal extends Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    handleOk = (e) => {
        this.props.onOk();
    };

    handleCancel = (e) => {
        this.props.onCancel();
    }

    okText = (e) => {
        this.props.okText()
    }

    cancelText = (e) => {
        this.props.cancelText()
    }

    render() {
        return (
            <Modal
                title={this.props.title}
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                maskClosable={false}
                okText={'确定'}
                cancelText= {'取消'}
                footer={null}
            >
                {this.props.data}
            </Modal>
        )
    }

}