import React, {Component} from 'react';

export default class SnapLogin extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            lang: 'zh_cn',
        }
    }

    componentDidMount() {}

    render() {
        return (
            <article className='page-snap'>
                <div className="loading">
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                </div>
                <div className='ps-logging'>login......</div>
            </article>
        )
    }
}