import React, { Component} from 'react';
import {
	Table, Button, DatePicker, InputNumber, message, Space,Select
} from 'antd';
import Xhr from '../libs/Xhr';
import Utility from "../libs/Utility";
import moment from 'moment';
import './scss/LiveWater.scss';

export default class LiveWater extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			types: [],
			currentPage: 1,
			pageSize: 10,
			searchText: '',
			searchedColumn: '',
			dt: 0,
			startTime: "",
			endTime: "",
			roomValue: 0,
			selectedRowKeys: [],
			loading: false,
			isFun :false,
			lang: 'zh_cn',
			is_together:0,
			tableScrollX: 1200,
			waterSum: 0,
			sort: "",
			dir: "",
		}


		this._columns = [
			{
				title:Utility.language('日期', this.state.lang),
				dataIndex:'day',
				key:'day',
			},
			{
				title:Utility.language('房间id', this.state.lang),
				dataIndex:'rid',
				key:'rid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.rid - b.rid,
			},
			{
				title: Utility.language('房主ID', this.state.lang),
				dataIndex: 'ruid',
				key: 'ruid',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.ruid - b.ruid,
			}, {
				title: Utility.language('房主昵称', this.state.lang),
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: Utility.language('房间流水', this.state.lang),
				dataIndex: 'water_total',
				key: 'water_total',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.water_total - b.water_total,
			},
			{
				title: Utility.language('麦时', this.state.lang),
				dataIndex: 'o_duration',
				key: 'o_duration',
			},
			{
				title: Utility.language('有效天', this.state.lang),
				dataIndex: 'effect_day',
				key: 'effect_day',
			}
		]

		this._load();
	}



	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({
			searchText: ''
		});
	};


	_columnsDeletedRender(text, record) {
		if (this.state.channel[text]) {
			return this.state.channel[text];
		}
		return text;
	}

	_columnsStateRender(text, record) {
		if (this.state.types[text]) {
			return this.state.types[text];
		}
		return text;
	}

	handleTableChange = (pagination) => {
		// this.setState({
		//   currentPage: pagination,
		// }, () =>{
		// 	this._load();
		// })
	}

	onSearch = () => {
		this.setState({
			currentPage: 1,
		},() => {
			this._load();
		})
	}
	togetherChange = (value) => {
		this.setState({
			is_together: value
		})
	}
	onChange = (value, dateString) => {
		this.setState({
			startTime: dateString[0],
			endTime: dateString[1],
		})
	}

	getInputValue = (value, name) => {
		this.setState({
			[name]: value
		})
	}
	  
	tableChange = (pagination, filters, sorter, extra) => {
		let dir = ""
		let sort = ""
		if (sorter.field === 'water_total') {
			sort = sorter.field
			dir = sorter.order === "ascend" ? "asc" : "desc";
		}

		// eslint-disable-next-line
		if((extra.action !== 'paginate') && (extra.action === 'sort' && sorter.field !== 'water_total')) {
			return false;
		}
		this.setState({
				currentPage: pagination.current,
				pageSize: pagination.pageSize,
				dir: dir,
				sort: sort,
			},()=>this._load()
		);
	}

	onSelectChange = selectedRowKeys => {
		console.log('selectedRowKeys changed: ', selectedRowKeys);
		this.setState({ selectedRowKeys });
	};

	start = () => {
		this.setState({ loading: true });
		setTimeout(() => {
		  this.setState({
			selectedRowKeys: [],
			loading: false,
		  });
		}, 500);
	};



	async _load() {

		let page = this.state.currentPage;
		let limit = this.state.pageSize;
		let room_id = this.state.roomValue;
		let start_time = this.state.startTime;
		let end_time = this.state.endTime;
		let is_together = this.state.is_together;
		let sort = this.state.sort;
		let dir = this.state.dir;
		var bid = localStorage.getItem('bid');
		let res = await Xhr.get('roomWater/getLiveRoomList?bid=' + bid + '&room_id=' + room_id  + '&start_time=' + start_time + '&end_time=' + end_time + '&page=' + page + '&limit=' + limit+ '&is_together=' + is_together + '&sort=' + sort + '&dir=' + dir)
		if (res.success) {
			this.setState({
				data: res.data.data,
				total: res.data.total,
				isFun: res.data.is_fun,
				waterSum: res.data.water_sum,
			});
		}else{
			message.error(res.msg);
		}
	}

	render() {
		const paginationProps = {
		  onChange : (page) => this.handleTableChange(page),
		  	total: this.state.total,
			showSizeChanger: true,
			defaultCurrent: this.state.currentPage,
			current: this.state.currentPage
		}

		const { RangePicker } = DatePicker;

		const dateFormat = 'YYYY/MM/DD';
		const end = moment().format(dateFormat);
		const start = moment().subtract('7','day').format(dateFormat);

		const { tableScrollX } = this.state;
		return (
				<article className="page-live-water">
					<header className="plw-header">
						<Space>
							{Utility.language('日期', this.state.lang)}：<RangePicker
							format="YYYY-MM-DD"
							onChange={this.onChange}
							onOk={this.onOk}
							defaultValue={[moment( start ,dateFormat), moment(end, dateFormat)]}
						/>
							<InputNumber  name="roomValue" onChange={value=>this.getInputValue(value, 'roomValue')} placeholder={Utility.language('房间id', this.state.lang)} />
				{Utility.language('数据是否聚合', this.state.lang)}：<Select
					showSearch
					placeholder="选择"
					optionFilterProp="children"
					onChange={this.togetherChange}
					defaultValue="0"
				>
						<Select.Option key={1} value="0">{Utility.language('否', this.state.lang)}</Select.Option>
						<Select.Option key={2} value="1">{Utility.language('是', this.state.lang)}</Select.Option>
					</Select>
							<Button type="primary" onClick={this.onSearch}>{Utility.language('查询', this.state.lang)}</Button>
							{Utility.language('房间流水汇总', this.state.lang)}:{this.state.waterSum}
						</Space>
					</header>
					<Table
						className="plw-table"
						scroll={{ x: tableScrollX }}
						rowKey={record => record.id}
						columns={this._columns}
						dataSource={this.state.data}
						bordered
						pagination={paginationProps}
						onChange={this.tableChange}
					/>
				</article>
		);
	}
}