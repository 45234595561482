var th = {};
th.l = {
    "今日总流水": "ยอดรวมในวันนี้",
    "本周期总流水": "ยอดรวมในสัปดาห์นี้",
    "环比昨日": "เทียบกับเมื่อวาน",
    "环比上周": "เทียบกับสัปดาห์ที่แล้ว",
    "日期": "วันที่",
    "订单详情": "รายละเอียดการสั่งซื้อ",
    "账期": "ระยะเวลาบัญชี",
    "成员ID": "IDสมาชิก",
    "昵称": "ชื่อ",
    "人气值奖励": "รางวัลความนิยม",
    "人气值审核": "รีวิวความนิยม",
    "成员vip等级": "เลเวลvipของสมาชิก",
    "结算类型": "ประเภทของการจัดการรายได้",
    "打包结算": "การจัดการรายได้ทีเดียว",
    "普通结算": "การจัดการรายได้ทั่วไป",
    "加入时间": "วันที่เข้าร่วม",
    "最后登录时间": "วันที่เข้าระบบล่าสุด",
    "操作": "จัดการ",
    "确定通过该成员的入会申请？": "คุณแน่ใจหรือไม่ว่าจะผ่านการสมัครของคนนี้ในการเป็นสมาชิก?",
    "确定拒绝该成员的入会申请？": "คุณแน่ใจหรือว่าจะปฏิเสธการสมัครเป็นสมาชิกของคนนี้？",
    "入会审核通过": "การเป็นสมาชิกได้รับการอนุมัติ",
    "入会审核拒绝": "การตรวจสอบการเป็นสมาชิกถูกปฏิเสธ",
    "确定同意该成员的退会申请？": "คุณแน่ใจหรือไม่ว่าคุณยอมรับการสมัครถอนออกจากสังกัดของสมาชิกคนนี้？",
    "同意退会": "เห็นด้วยกับการออกจากสังกัด",
    "确定拒绝该成员的退会申请？": "คุณแน่ใจหรือว่าจะปฏิเสธการสมัครเป็นสมาชิกของคนนี้？",
    "拒绝退会": "ปฏิเสธกับการออกจากสังกัด",
    "确定从公会移除该成员？": "แน่ใจหรือว่าจะลบสมาชิกคนนี้ออกจากสังกัด?",
    "移出公会": "ย้ายออกจากสังกัด",
    "加入待审核": "เพิ่มไปในการรอตรวจสอบ",
    "已通过": "ผ่านแล้ว",
    "已拒绝": "ปฏิเสธแล้ว",
    "退会待审核": "รอการตรวจสอบการถอนตัวจากสังกัด",
    "确认": "ยืนยัน",
    "取消": "ยกเลิก",
    "所选个数存在问题": "มีปัญหากับหมายเลขที่เลือก",
    "输入uid": "ป้อน uid",
    "状态筛选": "เลือกสถานะ",
    "全部状态": "สถานะทั้งหมด",
    "加入批量通过": "เพิ่มไปในการผ่านทั้งหมด",
    "退会批量拒绝": "ปฏิเสธถอนตัวจากสังกัดทั้งหมด",
    "主播ID": "IDวีเจ",
    "主播昵称": "ชื่อวีเจ",
    "返回公会奖励": "รางวัลกลับสังกัด",
    "同意": "ยอมรับ",
    "时间": "เวลา",
    "人气变更对象": "เป้าหมายที่จะใช้ความนิยม",
    "房间": "ห้อง",
    "人气变更详细": "รายละเอียดการเปลี่ยนแปลงความนิยม",
    "生效时间": "เวลาที่เริ่มใช้",
    "人气值变化": "การเปลี่ยนแปลงความนิยม",
    "时长": "ระยะเวลา",
    "操作人id": "idผู้ดำเนินการ",
    "返回房间管理": "กลับไปที่การจัดการห้อง",
    "房间id": "ห้องid",
    "欢迎光临": "ยินดีต้อนรับ",
    "请输入手机号码": "กรุณากรอกหมายเลขโทรศัพท์",
    "请输入正确的图形验证码": "โปรดป้อนรหัสยืนยันรูปที่ถูกต้อง",
    "请填写正确的手机验证码": "กรุณาป้อนรหัสยืนยันมือถือที่ถูกต้อง",
    "正在登录中": "กำลังล็อกอิน",
    "你未被邀请开通公会": "คุณไม่ได้รับเชิญให้เปิดสังกัด",
    "手机号": "เบอร์โทร",
    "请输入右边的图形验证码!": "โปรดป้อนรหัสยืนยันทางด้านขวา!",
    "图形码": "รหัสรูป",
    "验证码": "รหัสยืนยัน",
    "请输入你收到的验证码!": "โปรดป้อนรหัสยืนยันที่คุณได้รับ!",
    "获取验证码": "รับรหัสยืนยัน",
    "秒后重发": "วินาทีแล้วจะส่งใหม่ให้",
    "登录": "ล็อกอิน",
    "订单流水": "ยอดการสั่งซื้อ",
    "订单成功数": "จำนวนคำสั่งซื้อที่สำเร็จ",
    "订单失败数": "จำนวนคำสั่งซื้อที่ล้มเหลว",
    "有单人数": "จำนวนคนที่มีคำสั่งซื้อ",
    "返回公会流水": "ยอดที่คืนกลับสังกัด",
    "公会流水监控": "การเช็ครายละเอียดยอดของสังกัด",
    "今日订单总流水": "ยอดสั่งซื้อวันนี้",
    "本周期订单总流水": "ยอดสั่งซื้อทั้งหมดในสัปดาห์นี้",
    "本周订单成功数": "จำนวนคำสั่งซื้อที่สำเร็จในสัปดาห์นี้",
    "本周期订单失败数": "จำนวนคำสั่งซื้อที่ล้มเหลวในสัปดาห์นี้",
    "本周期有单人数": "จำนวนคนที่มีคำสั่งซื้อในสัปดาห์นี้",
    "房间昵称": "ชื่อห้อง",
    "接待id": "idพิธีกร",
    "接待昵称": "ชื่อพิธีกร",
    "在档房间流水": "ยอดห้องเวลานั่งที่โต๊ะ",
    "在坑流水": "ยอดของตนเวลานั่งที่โต๊ะ",
    "开始日期": "วันเริ่มต้น",
    "结束日期": "วันสิ้นสุด",
    "魅力值": "ค่าเสน่ห์",
    "缺少房间参数，请从上个页面重新进来": "ไม่มีพารามิเตอร์วัน  โปรดป้อนใหม่จากหน้าที่แล้ว",
    "返回房间流水": "ยอดที่คืนกลับห้อง",
    "房间魅力榜单": "บอร์ดค่าเสน่ห์ของห้อง",
    "房主昵称": "ชื่อหัวห้อง",
    "房主ID": "IDหัวห้อง",
    "房间状态": "สถานะห้อง",
    "房间业务": "ธุรกิจห้อง",
    "麦时（本周期）": "เวลาขึ้นโต๊ะ (รอบนี้)",
    "设置房间人气值": "ตั้งค่าความนิยมของห้อง",
    "房间信息": "ข้อมูลห้อง",
    "公会人气值": "ความนิยมของสังกัด",
    "人气设置": "การตั้งค่าความนิยม",
    "使用时长": "เวลาที่ใช้",
    "提交设置": "ส่งการตั้งค่า",
    "数据格式错误": "รูปแบบข้อมูลผิดพลาด",
    "人气设置不能为空": "การตั้งค่าความนิยมต้องไม่ว่างเปล่า",
    "时长不能为空": "ระยะเวลาต้องไม่ว่างเปล่า",
    "人气只能是大于100的正整数": "ความนิยมต้องเป็นจำนวนเต็มบวกที่มากกว่า 100 เท่านั้น",
    "时长只能是大于1的正整数": "ระยะเวลาสามารถเป็นจำนวนเต็มบวกที่มากกว่า 1 เท่านั้น",
    "待创建": "รอสร้าง",
    "正常": "ปกติ",
    "关闭": "ปิด",
    "删除": "ลบ",
    "房间频道": "ช่องห้อง",
    "房间流水": "ยอดห้อง",
    "麦时": "เวลาขึ้นไมค์",
    "房间流水详细": "รายละเอียดยอดห้อง",
    "时段": "ช่วงเวลา",
    "流水": "ยอดห้อง",
    "房间流水监控": "การตรวจสอบยอดห้อง",
    "公会类型": "ประเภทสังกัด",
    "结算金额": "ยอดเงิน",
    "查看详细": "ดูรายละเอียด",
    "说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准": "ปล: ยอดเงินนี้ไม่รวมส่วนการแบ่งอัตราของไลฟ์สด อัตราของไลฟ์สดขึ้นอยู่กับผลลัพธ์ที่ประกาศโดยแอดมินแอพ",
    "缺少日期参数，请从上个页面重新进来": "ไม่มีพารามิเตอร์วัน  โปรดป้อนใหม่จากหน้าที่แล้ว",
    "缺少跳转参数，请从上个页面重新进来": "ไม่มีพารามิเตอร์การกระโดด โปรดป้อนใหม่จากหน้าที่แล้ว",
    "返回结算管理": "กลับไปที่การจัดการรายได้",
    "未发放": "ยังไม่ได้แจก",
    "已发放": "แจกแล้ว",
    "Partying业务": "ธุรกิจPartying",
    "签约成员": "สมาชิกที่ลงชื่อ",
    "房间管理": "การจัดการของห้อง",
    "流水信息": "ข้อมูลยอดห้อง",
    "公会流水": "ยอดห้องของสังกัด",
    "主播流水": "ยอดของวีเจ",
    "接待流水": "ยอดของพิธีกร",
    "结算信息": "ข้อมูลการจัดการรายได้",
    "结算管理": "การจัดการแบ่งรายได้",
    "登录方式": "โหมดการเข้าสู่ระบบ",
    "手机号登录": "หมายเลขโทรศัพท์มือถือ",
    "账号登录": "เข้าสู่ระบบบัญชี",
    "请输入你的账号": "กรุณาใส่หมายเลขบัญชีของคุณ",
    "请输入你的密码": "กรุณาใส่รหัสผ่านของคุณ",
    "密码": "รหัสผ่าน",
    "手機號碼格式不合理": "รูปแบบเบอร์โทรไม่ถูกต้อง",
    "驗證碼錯誤": "รหัสยืนยันไม่ถูกต้อง",
    "驗證碼好像不對哦…": "รหัสยืนยันไม่ถูกต้อง",
    "区号": "รหัสนำหน้าเบอร์โทร",
    "韩语": "ภาษาเกาหลี",
    "印尼语": "ภาษาอินโดนิเซีย",
    "泰语": "ภาษาไทย",
    "阿拉伯语": "ภาษาอารบิก",
    "账号": "บัญชี",
    "越南语": "ภาษาเวียดนาม",
    "日接待时长": "เวลาขึ้นพธีกร/วัน",
    "当前任务等级": "เลเวลภารกิจปัจจุบัน",
    "1级任务完成度": "สถานะภารกิจเลเวล1",
    "2级任务完成度": "สถานะภารกิจเลเวล2",
    "3级任务完成度": "สถานะภารกิจเลเวล3",
    "4级任务完成度": "สถานะภารกิจเลเวล4",
    "5级任务完成度": "สถานะภารกิจเลเวล5",
    "接待任务": "ภารกิจพิธีกร",
    "主播任务": "ภารกิจวีเจ",
    "萌新任务": "ภารกิจด้านมือใหม่",
    "任务等级": "เลเวลภารกิจ",
    "完成状态": "สถานะเสร็จสมบรูณ์",
    "完成": "เรียบร้อย",
    "未完成": "ไม่สมบูรณ์",
    "周期": "รอบ",
    "任务1": "ภารกิจ1",
    "任务2": "ภารกิจ2",
    "任务3": "ภารกิจ3",
    "任务4": "ภารกิจ4",
    "任务5": "ภารกิจ5",
    "任务6": "ภารกิจ6",
    "任务7": "ภารกิจ7",
    "任务8": "ภารกิจ8",
    "任务9": "ภารกิจ9",
    "任务10": "ภารกิจ10",
    "任务11": "ภารกิจ11",
    "任务12": "ภารกิจ12",
    "任务13": "ภารกิจ13",
    "返回任务数据": "กลับไปที่ข้อมูลภารกิจ",
    "运营数据":"ข้อมูลการดำเนินงาน",
    "任务数据":"ข้อมูลภารกิจ",
    "有效天":"วันที่ใช้ได้",
    "数据是否聚合":"ข้อมูลจะรวมหรือไม่",
    "是":"ใช่",
    "否":"ไม่",
    "直播房间流水":"ยอดห้องการถ่ายทอดสด",
    "房间流水汇总":"สรุปการไหลของน้ำในห้อง",
    "当商业厅内某用户给A主播赠送了100钻礼物": "เมื่อยูสเซอร์ในห้องร่วมมือมอบของขวัญให้วีเจA 100เพชร",
    "1、公会长设定A主播的人气比为0%：": "1. ประธานสังกัดกำหนดอัตราส่วนความนิยมของวีเจ A เป็น 0%：",
    "则A主播立即收到0公会魅力值，Ta的公会长立即收到70公会魅力值；": "จากนั้นวีเจ A จะได้รับ 0 สังกัดค่าเสน่ห์ทันที หัวหน้สังกัดของเขา จะได้รับ 70 สังกัดค่าเสน่ห์ทันที",
    "2、公会长设定A主播的人气比为90%：": "2. ประธานสังกัดกำหนดอัตราส่วนความนิยมของวีเจ A เป็น 90%：",
    "则A主播立即收到63公会魅力值，Ta的公会长立即收到7公会魅力值": "จากนั้นวีเจ A จะได้รับ 63 สังกัดค่าเสน่ห์ทันที หัวหน้สังกัดของเขา จะได้รับ 7 สังกัดค่าเสน่ห์ทันที",
    "会长成功配置A主播的比例后，A主播将能收到【该比例部分】的人气魅力值": "หลังจากที่หัวสังกัดกำหนดสัดส่วนวีเจ A . สำเร็จแล้ว วีเจA จะสามารถรับค่าความนิยม [ตามที่เป็นสัดส่วน]",
    "当主播和平台审核通过后，分成比会实时生效": "หลังจากที่วีเจและแพลตฟอร์มผ่านการตรวจสอบแล้ว อัตราส่วนจะมีผลในแบบเรียลไทม์",
    "设置公会成员与公会人气分配比例": "กำหนดอัตราส่วนของสมาชิกสังกัดต่อความนิยมของสังกัด",
    "成员uids集合": "สมาชิก uid คอลเลกชัน",
    "支持手动在后面用英文逗号拼接uid": "สนับสนุนการประกบ uid ด้วยตนเองด้วยเครื่องหมายจุลภาคตามหลัง",
    "人气分配比": "อัตราส่วนการกระจายความนิยม",
    "数字必须在0~100之间的整数，没有小数": "ตัวเลขต้องเป็นจำนวนเต็มระหว่าง 0 ถึง 100 ไม่มีทศนิยม",
    "提交": "ส่งมอบ",
    "人气分配比例说明": "คำอธิบายของอัตราส่วนการกระจายความนิยม",
    "批量设置人气分配": "กำหนดการกระจายความนิยมเป็นชุดๆ",
    "人气比例调整记录": "บันทึกการปรับอัตราส่วนความนิยม",
    "编号": "ID",
    "成员id": "IDสมาชิก",
    "成员昵称": "ชื่อสมาชิก",
    "申请时间": "เวลาลงทะเบียน",
    "调整前比例": "อัตราส่วนก่อนปรับ",
    "调整后比例": "ปรับอัตราส่วน",
    "状态": "สถานะ",
    "返回成员列表": "กลับไปที่รายชื่อสมาชิก",
    "成员uid": "UIDสมาชิก",
    "选择状态": "เลือกสถานะ",
    "全部": "ทั้งหมด",
    "查询": "ค้นหา",
    "人气分配比例变更": "อัตราส่วนการกระจายความนิยมเปลี่ยนไป",
    "用户ID": "ID ผู้ใช้",
    "用户昵称": "ชื่อเล่นของผู้ใช้",
    "公会ID": "IDสังกัด",
    "公会昵称": "ชื่อเล่นสังกัด",
    "个人与公会人气分配比例变化": "การเปลี่ยนแปลงอัตราส่วนการกระจายของความนิยมส่วนบุคคลและสังกัด",
    "当前": "ปัจจุบัน",
    "调整后": "หลงการปรับ",
    "人气分配比说明": "คำอธิบายอัตราส่วนการกระจายความนิยม",
    "例：": "ตัวอย่าง:",
    " 1、公会长设定A主播的人气比为0%：": " 1. หัวสังกัดกำหนดอัตราส่วนความนิยมของวีเจ A เป็น 0%：",
    "则A主播立即收到63公会魅力值，Ta的公会长立即收到7公会魅力值；": "จากนั้นวีเจ A จะได้รับ 63 สังกัดค่าเสน่ห์ทันที หัวหน้สังกัดของเขา จะได้รับ 7 สังกัดค่าเสน่ห์ทันที",
    "拒绝调整": "ปฏิเสธการปรับ",
    "同意调整": "ตกลงการปรับ",
    "缺少表更信息！": "ตารางที่หายไป ข้อมูลเพิ่มเติม!",
    "正在加载中": "กำลังโหลด",
    "确定拒绝这次人气分配比例变更": "ตกลงที่จะปฏิเสธการเปลี่ยนแปลงอัตราส่วนการกระจายความนิยม",
    "确定同意这次人气分配比例变更": "ยืนยันยอมรับการเปลี่ยนแปลงอัตราส่วนการกระจายความนิยม",
    "是否签约":"ได้ลงนามในสัญญาการเป็นสมาชิกแล้วหรือยัง",
    "成员类型":"ประเภทสมาชิก",
    "新公会长后台": "ระบบหลังบ้านใหม่",
};
export default th;