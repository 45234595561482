var tu = {};
tu.l = {
    "今日总流水": "bugünün tüm geliri",
    "本周期总流水": "bu dönemin tüm geliri",
    "环比昨日": "Düne kıyasla",
    "环比上周": "geçen hafta kıyasla",
    "日期": "tarih",
    "订单详情": "sipariş detayları",
    "账期": "hesap dönemi",
    "成员ID": "üye ID",
    "昵称": "takma ad",
    "人气值奖励": "popülerlik ödülleri",
    "查询": "Sorgulama",
    "人气值审核": "Popülerlik incelemesi",
    "成员昵称": "Üye takma adı",
    "成员vip等级": "Üye VIP seviyesi",
    "状态": "durum",
    "结算类型": "Ödeme türü",
    "打包结算": "toplu hesapla",
    "普通结算": "Ödeme",
    "加入时间": "katılma tarihi",
    "最后登录时间": "son giriş tarihi",
    "操作": "İşlem",
    "确定通过该成员的入会申请？": "bu ajans üyeliği başvurusunu kabul ediyor musun?",
    "确定拒绝该成员的入会申请？": "bu ajans üyeliği başvurusunu reddediyor musun?",
    "入会审核通过": "ajans katılma başvurusu kabul edildi",
    "入会审核拒绝": "ajans katılma başvurusu reddedildi",
    "确定同意该成员的退会申请？": "bu para çekme başvurusunu kabul ediyor musun?",
    "同意退会": "Ajanstan ayrılmayı kabul et",
    "确定拒绝该成员的退会申请？": "Üyenin para çekme talebini reddetmeyi onaylıyor musunuz?",
    "拒绝退会": "Ajanstan ayrılmayı reddet",
    "确定从公会移除该成员？": "Bu üyeyi ajanstan çıkarmayı onaylıyor musunuz?",
    "移出公会": "Ajanstan çıkar",
    "加入待审核": "Bekleyen katılma isteği",
    "已通过": "kabul edildi",
    "已拒绝": "reddedildi",
    "退会待审核": "Bekleyen çıkış isteği",
    "确认": "onayla",
    "取消": "iptal et",
    "所选个数存在问题": "miktar yanlış",
    "输入uid": "UID'yi giriniz",
    "状态筛选": "durum filtresi",
    "全部状态": "Tüm Durumlar",
    "加入批量通过": "Hepsini onayla",
    "退会批量拒绝": "Hepsini reddet",
    "主播ID": "Yayıncı ID",
    "主播昵称": "Yayıncının takma adı",
    "返回公会奖励": "Ajans ödüllerini iade edin",
    "同意": "kabul et",
    "时间": "tarih",
    "人气变更对象": "popülerlik değiştirecek yayıncı",
    "房间": "Oda",
    "人气变更详细": "popülerlik değişikliğinin detayları",
    "生效时间": "geçerli olma tarihi",
    "人气值变化": "popülerlik değişikliği",
    "时长": "süresi",
    "操作人id": "işlemci ID",
    "返回房间管理": "Oda Yönetimine Dön",
    "房间id": "oda ID",
    "欢迎光临": "hog geldiniz",
    "请输入手机号码": "telefon numarasını girin",
    "请输入正确的图形验证码": "doğru grafik doğrulama kodunu girin",
    "请填写正确的手机验证码": "doğru telefon doğrulama kodunu girin",
    "正在登录中": "giriş yapılıyor",
    "你未被邀请开通公会": "ajans açmaya davet edilmedin",
    "手机号": "telefon numarası",
    "请输入右边的图形验证码!": "sağdaki doğrulama kodunu girin",
    "图形码": "grafik kodu",
    "验证码": "Doğrulama kodu",
    "请输入你收到的验证码!": "aldığınız doğrulama kodunu girin",
    "获取验证码": "doğrulama kodu al",
    "秒后重发": "saniye sonra tekrar gönderilecek",
    "登录": "Oturum aç ",
    "订单流水": "sipariş geliri",
    "订单成功数": "başarılı siparişin sayısı",
    "订单失败数": "başarısız siparişin sayısı",
    "有单人数": "sipariş alanın sayısı",
    "返回公会流水": "Ajansın gelirine dön",
    "公会流水监控": "Ajansın gelirini takip et",
    "今日订单总流水": "bugünün tüm sipariş geliri",
    "本周期订单总流水": "bu haftanın tüm sipariş geliri",
    "本周订单成功数": "Bu haftaki başarılı siparişlerin sayısı",
    "本周期订单失败数": "bu hafta tamamlanamayan sipariş sayısı",
    "本周期有单人数": "Haftalık sipariş veren oda sahibi sayısı",
    "房间昵称": "odanın adı",
    "接待id": "oda sahibi ID",
    "接待昵称": "oda sahibinin takma adı",
    "在档房间流水": "Tarihindeki oda geliri",
    "在坑流水": "Koltuktaki gelir",
    "开始日期": "başlama tarihi",
    "结束日期": "bitirme tarihi",
    "魅力值": "popülerlik",
    "缺少房间参数，请从上个页面重新进来": "Oda parametreleri eksik, lütfen tekrar deneyin",
    "返回房间流水": "odanın gelirine dön",
    "房间魅力榜单": "oda popülerliği listesi",
    "房主昵称": "oda sahibinin takma adı",
    "房主ID": "oda sahibinin ID'si",
    "房间状态": "oda durumu",
    "房间业务": "odanın işleri",
    "麦时（本周期）": "yayınlama süresi (bu dönem için)",
    "设置房间人气值": "odanın popülerliğini ayarla",
    "房间信息": "oda bilgileri",
    "公会人气值": "ajansın popülerliği",
    "人气设置": "popülerlik ayarları",
    "使用时长": "kullanım süresi",
    "提交设置": "ayarla",
    "数据格式错误": "Veri formatı hatası",
    "人气设置不能为空": "popülerlik boş bırakılamaz",
    "时长不能为空": "süre boş bırakılamaz",
    "人气只能是大于100的正整数": "Popülerlik yalnızca 100'den büyük bir pozitif tam sayı olabilir",
    "时长只能是大于1的正整数": "süre yalnızca 1'den büyük bir pozitif tam sayı olabilir",
    "待创建": "oluşturma bekleniyor",
    "正常": "normal",
    "关闭": "kapat",
    "删除": "sil",
    "房间频道": "oda kanalı",
    "房间流水": "odanın geliri",
    "麦时": "yayınlama süresi",
    "房间流水详细": "oda gelir detayları",
    "时段": "zaman dilimi",
    "流水": "Gelir",
    "房间流水监控": "odanın gelirini takip et",
    "公会类型": "ajans türü",
    "结算金额": "hesaplama miktarı",
    "查看详细": "detayları göster",
    "说明：此结算金额不含直播返点，直播返点以官方运营公布结果为准": "Açıklama ： Bu hesaplama miktarı, canlı yayın para iadesini içermez. Canlı yayın para iadesi hakkında bilgiler, resmi bildiriler yapılarak edinilir.",
    "缺少日期参数，请从上个页面重新进来": "Tarih parametreleri eksik, lütfen tekrar deneyin",
    "缺少跳转参数，请从上个页面重新进来": "yeniden yönlendirme parametresi eksik, lütfen yeniden deneyin",
    "返回结算管理": "hesaplama yönetimine dön",
    "未发放": "dağıtılmadı",
    "已发放": "dağıtıldı",
    "Partying业务": "Ajans yönetimi",
    "签约成员": "sözleşmeli üye",
    "房间管理": "oda yönetimi",
    "流水信息": "Gelir bilgileri",
    "公会流水": "Ajansın geliri",
    "主播流水": "yayıncının geliri",
    "接待流水": "oda sahibinin geliri",
    "结算信息": "hesaplama bilgileri",
    "结算管理": "hesaplama yönetimi",
    "登录方式": "Giriş modusu",
    "手机号登录": "Mobil numaralı giriş",
    "账号登录": "Hesap giriş",
    "请输入你的账号": "Lütfen hesap numarasını girin",
    "请输入你的密码": "Lütfen parolanızı girin",
    "密码": "parola",
    "手機號碼格式不合理": "yanlış telefon numarası formatı",
    "驗證碼錯誤": "yanlış doğrulama kodu",
    "驗證碼好像不對哦…": "doğrulama kodu yanlış görünüyor...",
    "区号": "alan kodu",
    "韩语": "Korece",
    "印尼语": "Endonezyaca",
    "泰语": "Tayca",
    "阿拉伯语": "Arapça",
    "账号": "hesap ıd",
    "越南语": "Vietnamca",
    "日接待时长": "Günlük alış zamanı",
    "当前任务等级": "Görev seviyesi",
    "1级任务完成度": "Lv. 1",
    "2级任务完成度": "Lv. 2",
    "3级任务完成度": "Lv. 3",
    "4级任务完成度": "Lv. 4",
    "5级任务完成度": "Lv. 5",
    "接待任务": "Lv. 6",
    "主播任务": "Yayıncı görevi",
    "萌新任务": "Yeni görevi",
    "任务等级": "Görev seviyesi",
    "完成状态": "Durum",
    "完成": "Tamamlandı",
    "未完成": "Tamamlanmamış",
    "周期": "Süre",
    "任务1": "Görev 1",
    "任务2": "Görev 12",
    "任务3": "Görev 3",
    "任务4": "Görev 4",
    "任务5": "Görev 5",
    "任务6": "Görev 6",
    "任务7": "Görev 7",
    "任务8": "Görev 8",
    "任务9": "Görev 9",
    "任务10": "Görev 10",
    "任务11": "Görev 11",
    "任务12": "Görev 11",
    "任务13": "Görev 13",
    "返回任务数据": "Görevleri döndür",
    "运营数据":"Yönetim verileri",
    "任务数据":"Görev verileri",
    "有效天":"Aktif gün",
    "数据是否聚合":"Veriler hesaplanır mı",
    "是":"Evet",
    "否":"Hayır",
    "直播房间流水":"Canlı yayın odadaki geliri",
    "房间流水汇总":"Oda akışı toplantısı",
};

export default tu;